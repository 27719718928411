export const prometheusConfig = {
    headers: [
        { id: "tier", label: "Tier" },
        { id: "vcpu", label: "nCPU" },
        { id: "ram", label: "RAM" },
    ],
    options: [
        {
            id: "1",
            values: [
                {
                    id: "nCPU",
                    label: "1",
                },
                {
                    id: "ram",
                    label: "4 GB",
                },
            ],
            label: "1",
        },
        {
            id: "2",
            values: [
                {
                    id: "nCPU",
                    label: "2",
                },
                {
                    id: "ram",
                    label: "8 GB",
                },
            ],
            label: "2",
        },
        {
            id: "4",
            values: [
                {
                    id: "nCPU",
                    label: "4",
                },
                {
                    id: "ram",
                    label: "16 GB",
                },
            ],
            label: "4",
        },
        {
            id: "8",
            values: [
                {
                    id: "nCPU",
                    label: "8",
                },
                {
                    id: "ram",
                    label: "32 GB",
                },
            ],
            label: "8",
        },
    ],
};
