import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import history from "libs/browserHistory";
import LoadingSpinner from "components/shared/LoadingSpinner/LoadingSpinner";
import config from "config";

function RedirectToDashboard() {
    useEffect(() => {
        history.push(`/${config.routes.appPrefix}/dashboard`);
    }, []);

    return <LoadingSpinner loading={true} />;
}

export default observer(RedirectToDashboard);
