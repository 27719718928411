export const styles: any = {
    ZoneCardStyles: {
        cursor: "pointer",
        marginRight: 2,
        boxShadow: 1,
        height: "contain",
        minHeight: 8,
        border: "{{lineThickness.1}} solid transparent",
        textAlign: "left",
        img: {
            width: "80px",
            height: "25px",
        },
    },

    SelectedZoneCardStyles: {
        border: "{{lineThickness.1}} solid {{colors.pink}}",
    },
    FlagInlineStyles: {
        objectFit: "cover",
        borderRadius: 1,
        height: 2,
        width: 3,
        border: "{{lineThickness.1}} solid {{colors.grey.border}}",
    },
};
