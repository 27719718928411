import { Action, ActionTypes } from "./dqlQueryExecutor.actions";
import {
    DqlQueryExecutorState,
    DqlQueryState,
} from "./dqlQueryExecutor.reducer.types";

export const dqlQueryExecutorReducer = (
    state: DqlQueryExecutorState,
    action: Action
): DqlQueryExecutorState => {
    let newDqlState: DqlQueryState;
    let isLastQueryIdentical: boolean;
    switch (action.type) {
        case ActionTypes.EXECUTE_QUERY_ACTION:
            newDqlState = {
                ...state.dqlQueryState,
                ...action.payload,
                isLoading: true,
                at: new Date().toJSON(),
            };
            isLastQueryIdentical =
                action.payload.uid === state.dqlQueryState.uid;

            if (isLastQueryIdentical) {
                const isInHistory = state.history.some(
                    oldState => oldState.uid === newDqlState.uid
                );
                if (!isInHistory) {
                    newDqlState = { ...newDqlState, repeatCount: 0 };
                }
                return {
                    ...state,
                    dqlQueryState: newDqlState,
                    history: isInHistory
                        ? state.history.map(oldState =>
                              oldState.uid === newDqlState.uid
                                  ? newDqlState
                                  : oldState
                          )
                        : [newDqlState, ...state.history],
                };
            } else {
                newDqlState = {
                    ...newDqlState,
                    at: new Date().toJSON(),
                    repeatCount: 0,
                };
                return {
                    ...state,
                    dqlQueryState: newDqlState,
                    history: [newDqlState, ...state.history],
                };
            }
        case ActionTypes.EXECUTE_QUERY_FAIL_ACTION:
        case ActionTypes.EXECUTE_QUERY_COMPLETE_ACTION:
            newDqlState = {
                ...state.dqlQueryState,
                ...action.payload,
                isLoading: false,
                repeatCount: state.dqlQueryState.repeatCount + 1,
                at: new Date().toJSON(),
            };
            return {
                ...state,
                dqlQueryState: newDqlState,
                history: state.history.map(dqlStateIter =>
                    dqlStateIter.uid === newDqlState.uid
                        ? newDqlState
                        : dqlStateIter
                ),
            };
        case ActionTypes.CLEAR_HISTORY:
            return {
                ...state,
                history: [],
            };
        case ActionTypes.RESET:
            return action.payload.reducerState;
    }
};
