import {
    Deployment,
    DeploymentType,
} from "store/deployments/deployments.types";

export const backendTypeLabels: Record<DeploymentType, string> = {
    free: "Free",
    shared: "Shared",
    dedicated: "Dedicated",
};

export const getLabelForBackend = (backend: Deployment) => {
    if (backend.organization && backend.organization.name !== "") {
        return `${backend.organization.name} / ${backend.name}`;
    } else {
        return backend.name;
    }
};
