import config from "config";
import React, { useEffect, useState } from "react";
import { Stack } from "@dgraph-io/typhoon-ui";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import trackEvent, { events, trackPage } from "libs/trackevents";
import deploymentStore, {
    DeploymentStore,
} from "store/deployments/deployments";
import { useFetchDQLSchema } from "components/shared/DQLSchemaEditor/hooks/useFetchDQLSchema";
import { QueryPanel } from "./views/QueryPanel/QueryPanel";
import { useDQLQueryExecutor } from "./hooks/useDQLQueryExecutor";
import { ResponsePanel } from "./views/ResponsePanel/ResponsePanel";
import { HistoryPanel } from "./views/HistoryPanel/HistoryPanel";
import { Header } from "components/shared/AuthHeader/AuthHeader.types";
import { QueryOptionsPanel } from "./views/QueryOptionsPanel/QueryOptionsPanel";
import { uuid } from "@dgraph-io/typhoon-ui";
import { DqlOperationType } from "./reducer/dqlQueryExecutor.reducer.types";

const DQLPage = observer(
    ({ deploymentStore }: { deploymentStore: DeploymentStore }) => {
        const deployment = deploymentStore.currentDeployment!;
        const namespace = deploymentStore.selectedNamespace;
        const url = config.httpProtocol + "://" + (deployment.url || "");
        const { dqlSchema, fetchSchema } = useFetchDQLSchema({
            deployment,
            url,
            namespace,
        });
        const predicates = dqlSchema.schema.map(
            predicate => predicate.predicate
        );
        const dqlTypes = dqlSchema.types.map(dqlType => dqlType.name);

        useEffect(trackPage, []);

        useEffect(() => {
            fetchSchema();
        }, [namespace, url, deployment]);

        const [variables, setVariables] = useState<Array<Header>>([]);
        const variablesAsRecord = variables.reduce((agg, variable) => {
            if (variable.key && variable.value) {
                agg[variable.key] = variable.value;
            }
            return agg;
        }, {} as Record<string, string>);
        const [query, setQuery] = useState("schema {}");
        const [mutation, setMutation] = useState("");
        const [operationType, setOperationType] = useState<DqlOperationType>(
            "query"
        );

        const {
            dqlQueryState,
            history,
            executeQuery,
            setState,
            clearHistory,
        } = useDQLQueryExecutor({
            deployment,
            namespace: deploymentStore.selectedNamespace,
            onQueryChange: query => setQuery(query),
            onMutationChange: query => setMutation(query),
            onOperationTypeChange: operationType =>
                setOperationType(operationType),
            onVariablesChange: variables =>
                setVariables(
                    Object.entries(variables).map(([key, value]) => ({
                        key,
                        value,
                        id: uuid(),
                    }))
                ),
        });

        const { isLoading } = dqlQueryState;

        const onExecuteQueryButtonClick = () => {
            executeQuery({
                query: operationType === "query" ? query : mutation,
                variables: variablesAsRecord,
                operationType,
            });
            trackEvent(events.dqlQuery, {
                query: query,
                operationType,
            });
        };

        return (
            <Stack
                styles={{
                    marginX: "auto",
                    marginY: 6,
                    maxWidth: "unset",
                    width: "100%",
                    margin: 0,
                    padding: 3,
                    height: "calc(100% - 80px)",
                }}
                gap={3}
                direction="horizontal"
                key={deploymentStore.currentDeployment!.uid || "0"}
                wrap="nowrap"
            >
                <HistoryPanel
                    dqlHistory={history}
                    setState={setState}
                    styles={{ height: "100%", width: "250px" }}
                    clearHistory={clearHistory}
                />

                <Stack
                    direction="vertical"
                    gap={2}
                    styles={{ flex: 1, height: "100%" }}
                >
                    <QueryPanel
                        query={query}
                        mutation={mutation}
                        setQuery={setQuery}
                        setMutation={setMutation}
                        predicates={predicates}
                        dqlTypes={dqlTypes}
                        onExecute={onExecuteQueryButtonClick}
                        setOperationType={setOperationType}
                        operationType={operationType}
                        styles={{ height: "calc(100% - 260px)", width: "100%" }}
                    />

                    {operationType === "query" && (
                        <QueryOptionsPanel
                            styles={{ width: "100%" }}
                            variables={variables}
                            setVariables={setVariables}
                        />
                    )}
                </Stack>
                <ResponsePanel
                    isLoading={isLoading}
                    response={dqlQueryState.response}
                    styles={{ flex: 1, height: "100%" }}
                />
            </Stack>
        );
    }
);

export default withRouter((props: any) => (
    <DQLPage deploymentStore={deploymentStore} {...props} />
));
