import { useEffect, useState } from "react";
import { useForm, RULES } from "@dgraph-io/typhoon-ui";
import { ApolloError } from "@apollo/client";
import { useToast } from "@dgraph-io/typhoon-ui";
import { useAuth } from "libs/auth";
import history from "libs/browserHistory";
import { ToastContext } from "libs/toast";

type SignupTouched = {
    email: boolean;
    password: boolean;
};

export const useSignupForm = () => {
    const toast = useToast(ToastContext);
    const { isAuthenticated, setIsFirstLogin } = useAuth();
    const [touched, setTouched] = useState<SignupTouched>({
        email: false,
        password: false,
    });

    const onSuccessfulLogin = () => {
        if (setIsFirstLogin) {
            setIsFirstLogin(true);
        }
        handleRedirect();
    };
    const { form: signupForm, setValue, validate } = useForm({
        email: {
            default: "",
            rules: [RULES.email, RULES.nonEmptyString, RULES.emailLength],
        },
        password: {
            default: "",
            rules: [RULES.nonEmptyString, RULES.passwordLength],
        },
    });

    const handleSignupError = (err: ApolloError) => {
        toast.addToast({
            variant: "error",
            heading: err.message,
        });
    };

    const handleRedirect = () => {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        history.push(redirect ?? "/dashboard");
    };
    const errors = validate(signupForm);

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirect();
        }
    }, [isAuthenticated]);

    return {
        signupForm,
        setValue,
        errors,
        handleSignupError,
        handleRedirect,
        onSuccessfulLogin,
        touched,
        setTouched,
    };
};
