import { decorate, observable, action, runInAction } from "mobx";

import api from "libs/api";
import Axios from "axios";
import config from "config";
import {
    GET_DEPLOYMENTS_QUERY_STRING,
    CREATE_DEPLOYMENT,
    DELETE_DEPLOYMENT,
    GET_PATCH_ATTRIBUTES,
    PATCH_DEPLOYMENT,
    CHANGE_DEPLOYMENT_PROTECTION,
    SEARCH_DEPLOYMENTS,
} from "gql/deployments.gql";
import {
    AuthHeader,
    Deployment,
    SummaryAttributes,
    DeploymentUpdateOperationInput,
    CreateDeploymenInput,
    DeploymentMode,
    Namespace,
} from "./deployments.types";
import { ToastContextValue } from "@dgraph-io/typhoon-ui/components/molecules/Toast/Toast.types";

const CURRENT_DEPLOYMENT_KEY = "slashgraphql:deployment";
const getCurrentDeploymentHeaderStoreKey = (id: string) =>
    `slashgraphql:headers:${id}`;

const deploymentTransformer = (response: any) => ({
    ...response,
    ...(typeof response.aclEnabled !== "boolean"
        ? { aclEnabled: response.aclEnabled === "true" }
        : {}),
    ...(typeof response.dgraphHA !== "boolean"
        ? { dgraphHA: response.dgraphHA === "true" }
        : {}),
    ...(typeof response.jaegerEnabled !== "boolean"
        ? { jaegerEnabled: response.jaegerEnabled === "true" }
        : {}),
    ...(typeof response.isMultiTenancyEnabled !== "boolean"
        ? { isMultiTenancyEnabled: response.isMultiTenancyEnabled === "true" }
        : {}),
    ...(typeof response.alphaStorage !== "number"
        ? {
              alphaStorage:
                  typeof response.alphaStorage === "string"
                      ? Number(response.alphaStorage.slice(0, -2))
                      : response.alphaStorage,
          }
        : {}),
    isDeploying: false,
});

class DeploymentHeaderCacheManager {
    getHeaders(id: string) {
        try {
            return JSON.parse(
                localStorage.getItem(getCurrentDeploymentHeaderStoreKey(id)) ||
                    "[]"
            );
        } catch {
            localStorage.setItem(getCurrentDeploymentHeaderStoreKey(id), "[]");
            return [];
        }
    }

    setHeaders(id: string, headers: AuthHeader[]) {
        try {
            localStorage.setItem(
                getCurrentDeploymentHeaderStoreKey(id),
                JSON.stringify(headers)
            );
        } catch {
            localStorage.setItem(getCurrentDeploymentHeaderStoreKey(id), "[]");
        }
    }
}
export class DeploymentStore {
    deploymentHeaderCacheManager: DeploymentHeaderCacheManager;
    deployments: Deployment[] = [];
    currentDeployment: Deployment | null = null;
    selectedNamespace: Namespace | null = null;
    loading: boolean = true; // if there is an async load/create/delete occuring
    summaryAttributes: SummaryAttributes[] = [
        {
            label: "ID",
            name: "uid",
            needsClipboard: false,
        },
        {
            label: "Name",
            name: "name",
            needsClipboard: false,
        },
        {
            label: "URL",
            name: "graphqlURL",
            needsClipboard: true,
        },
        {
            label: "Owner",
            name: "ownerDetails.email",
            needsClipboard: true,
        },
        {
            label: "JWT Token",
            name: "jwtToken",
            needsClipboard: true,
        },
    ];
    patchAttributes = [];
    currentHeaders: AuthHeader[] = [];

    constructor() {
        this.deploymentHeaderCacheManager = new DeploymentHeaderCacheManager();
    }

    getSummaryAttributes() {
        return this.summaryAttributes;
    }

    purgeStore() {
        this.deployments = [];
        this.currentDeployment = null;
        this.currentHeaders = [];
    }

    setNamespace(namespace: Namespace) {
        this.selectedNamespace = namespace;
    }

    async loadPatchAttributes(deploymentId: string) {
        if (!this.patchAttributes.length) {
            try {
                const res = await api.post("/graphql", {
                    query: GET_PATCH_ATTRIBUTES,
                    variables: { uid: deploymentId },
                });

                if (res.data.errors?.length) {
                    throw new Error(res.data.errors[0].message);
                }
                runInAction(() => {
                    this.patchAttributes =
                        res.data.data.getAllowedDeploymentUpdateAttributes;
                });
            } finally {
                runInAction(() => (this.loading = false));
            }
        }
    }

    async load(reload = false, toast?: ToastContextValue) {
        const toastError = () => {
            if (toast) {
                toast.addToast({
                    permanent: true,
                    variant: "error",
                    heading: "Failed to retrieve deployments",
                    description:
                        "Try refreshing the page, if the problem persists, contact support.",
                });
            }
        };
        if (reload || this.deployments.length === 0) {
            this.loading = true;
            try {
                const res = await api.post(config.graphqlServerUrl, {
                    query: GET_DEPLOYMENTS_QUERY_STRING,
                });

                runInAction(() => {
                    if (!res?.data?.data?.deployments && toast) {
                        toastError();
                        this.loading = false;
                        return;
                    }
                    this.deployments =
                        res?.data?.data?.deployments.map((deployment: any) =>
                            deploymentTransformer(deployment)
                        ) || [];

                    // If current deployment was reloaded, update
                    if (this.currentDeployment) {
                        this.currentDeployment =
                            this.deployments.find(
                                deploymentToCheck =>
                                    deploymentToCheck.uid ===
                                    this.currentDeployment?.uid
                            ) || null;

                        if (this.currentDeployment) {
                            this.currentHeaders = this.deploymentHeaderCacheManager.getHeaders(
                                this.currentDeployment.uid
                            );
                        }
                    } else {
                        // otherwise, try to pull from localstorage
                        const uid = window.localStorage.getItem(
                            CURRENT_DEPLOYMENT_KEY
                        );
                        if (uid) {
                            this.currentDeployment =
                                this.deployments.find(d => d.uid === uid) ||
                                null;

                            this.currentHeaders = this.deploymentHeaderCacheManager.getHeaders(
                                uid
                            );
                        }
                    }

                    this.loading = false;
                });
                return res.data;
            } catch (error) {
                toastError();
                console.error(error);
                this.loading = false;
                return;
            }
        }
    }

    async getHealthInfo(url: string, timeout = 60000) {
        const headers = { "Content-Type": "application/json" };
        const res = await Axios.get(`${config.httpProtocol}://${url}/health`, {
            headers: headers,
            timeout: timeout,
        });
        return res;
    }

    async wakeUp(url: string) {
        const res = await Axios.options(
            `${config.httpProtocol}://${url}/graphql`,
            {}
        );
        return res;
    }

    async searchDeployments(
        inputType: "deploymentID" | "email" | "endpoint",
        searchText: string
    ) {
        this.loading = true;
        try {
            const res = await api.post("/graphql", {
                query: SEARCH_DEPLOYMENTS,
                variables: { inputType, searchText },
            });
            return res?.data?.data?.searchDeployments || [];
        } finally {
            runInAction(() => (this.loading = false));
        }
    }

    async getDeploymentByID(deploymentId: string) {
        this.loading = true;
        try {
            const res = await api.get("/deployment/" + deploymentId);
            return res.data;
        } finally {
            runInAction(() => (this.loading = false));
        }
    }

    async createBackup(deployment: Deployment) {
        const baseURL = config.httpProtocol + "://" + deployment.url;
        const url = baseURL + "/admin/slash";
        const headers = {
            "Content-Type": "application/json",
            "X-Auth-Token": deployment.jwtToken,
        };
        const data =
            '{"query":"mutation { backup { response { code message }, location } }","variables":{}}';
        const res = await Axios.post(url, data, { headers });
        return res.data;
    }

    async createExport(
        deployment: Deployment,
        { formatType }: { formatType: "rdf" | "json" }
    ) {
        const baseURL = config.httpProtocol + "://" + deployment.url;
        const url = baseURL + "/admin/slash";
        const headers = {
            "Content-Type": "application/json",
            "X-Auth-Token": deployment.jwtToken,
        };
        const data = '{"query": "TODO"}';
        const res = await Axios.post(url, data, { headers });
        return res.data;
    }

    restoreMutation = () => {
        return `mutation restore($cloneID: String!, $backupFolder: String, $backupNum: Int, $backup: Boolean) {
            restore(uid: $cloneID, backupFolder: $backupFolder, backupNum: $backupNum, backup: $backup) {
                response {
                    code
                    message
                    restoreId
                },
                errors {
                    message
                }
            }
        }`;
    };

    restoreQuery = () => {
        return `query restoreStatus($restoreId: Int!) {
            restoreStatus(restoreId: $restoreId) {
                response {
                    status
                    errors
                }
            }
        }`;
    };

    async patchDeployment(deployment: Deployment, attrs: Partial<Deployment>) {
        this.loading = true;
        try {
            attrs.uid = deployment.uid;
            const resp = await api.post("/graphql", {
                query: PATCH_DEPLOYMENT,
                variables: { input: attrs },
            });
            if (resp.data.errors?.length) {
                throw new Error(resp.data.errors[0].message);
            }

            runInAction(() => {
                this.load(true);
                this.loading = false;
            });
            return resp;
        } finally {
            runInAction(() => (this.loading = false));
        }
    }

    async create({ createDeploymentInput, onComplete }: CreateDeploymenInput) {
        // Create deployment
        this.loading = true;

        try {
            const res = await api.post("/graphql", {
                query: CREATE_DEPLOYMENT,
                variables: { input: createDeploymentInput },
            });

            if (res.data.errors?.length) {
                throw new Error(res.data.errors[0].message);
            }

            const response = res.data.data.createDeployment;
            // add to deployments
            const newDeployment = {
                ...response,
                isMultiTenancyEnabled:
                    response.isMultiTenancyEnabled === "true",
                aclEnabled: response.aclEnabled === "true",
                dgraphHA: response.dgraphHA === "true",
                jaegerEnabled: response.jaegerEnabled === "true",
                isDeploying: false,
                alphaStorage:
                    typeof response.alphaStorage === "string"
                        ? Number(response.alphaStorage.slice(0, -2))
                        : response.alphaStorage,
            };
            runInAction(
                () => (this.deployments = [...this.deployments, newDeployment])
            );
            onComplete && onComplete();
            return newDeployment;
        } finally {
            runInAction(() => (this.loading = false));
        }
    }

    async switchMode(deployment: Deployment, newMode: DeploymentMode) {
        this.loading = true;
        const resp = await api.post("/graphql", {
            query: PATCH_DEPLOYMENT,
            variables: {
                input: { uid: deployment.uid, deploymentMode: newMode },
            },
        });
        if (resp.data.errors?.length) {
            throw new Error(resp.data.errors[0].message);
        }
        runInAction(() => {
            this.updateCurrentDeployment({
                ...deployment,
                deploymentMode: newMode,
            });
            this.loading = false;
        });
    }

    async changeDeploymentProtection(
        deployment: Deployment,
        isProtected: boolean
    ) {
        this.loading = true;

        const resp = await api.post("/graphql", {
            query: CHANGE_DEPLOYMENT_PROTECTION,
            variables: { input: { uid: deployment.uid, protect: isProtected } },
        });
        if (resp.data.errors?.length) {
            throw new Error(resp.data.errors[0].message);
        }

        this.loading = false;
        return { ...deployment, isProtected: isProtected };
    }

    async rename(
        { deployment, onComplete }: DeploymentUpdateOperationInput,
        toast: ToastContextValue
    ) {
        const { name } = deployment;
        let nameExist = this.deployments.find(
            deployment => deployment.name === name
        );

        if (!nameExist) {
            this.loading = true;
            const resp = await api.post("/graphql", {
                query: PATCH_DEPLOYMENT,
                variables: { input: { uid: deployment.uid, name: name } },
            });
            if (resp.data.errors?.length) {
                throw new Error(resp.data.errors[0].message);
            }
            runInAction(() => {
                this.updateCurrentDeployment({ ...deployment, name });
                this.loading = false;
                onComplete && onComplete();
            });
        } else {
            toast.addToast &&
                toast.addToast({
                    description: `${name} already exists, Please try a different name`,
                    variant: "error",
                });
        }
    }

    async setOrganization(
        {
            deployment,
            organization,
            onComplete,
        }: DeploymentUpdateOperationInput,
        toast: ToastContextValue
    ) {
        // FIXME: take care of this as well
        this.loading = true;
        try {
            let organizationUID = "";
            if (organization && "uid" in organization) {
                organizationUID = organization.uid;
            }
            const resp = await api.post("/graphql", {
                query: PATCH_DEPLOYMENT,
                variables: {
                    input: {
                        organizationUID: organizationUID,
                        uid: deployment?.uid,
                    },
                },
            });
            if (resp.data.errors?.length) {
                throw new Error(resp.data.errors[0].message);
            }
        } catch (error) {
            console.error(error);
            this.loading = false;
            if (toast.addToast) {
                toast.addToast({
                    description: `Failed to update organization, try again later`,
                    variant: "error",
                });
            }
            return;
        }

        runInAction(() => {
            this.loading = false;
            this.updateCurrentDeployment({
                ...deployment,
                owner: organization?.createdBy?.auth0ID || "",
                organization: organization || null,
            });
            onComplete && onComplete();
        });
    }

    async removeOrganization(
        { deployment, onComplete }: DeploymentUpdateOperationInput,
        toast: ToastContextValue
    ) {
        this.loading = true;
        try {
            const resp = await api.post("/graphql", {
                query: PATCH_DEPLOYMENT,
                variables: {
                    input: { organizationUID: "", uid: deployment?.uid },
                },
            });
            if (resp.data.errors?.length) {
                throw new Error(resp.data.errors[0].message);
            }
        } catch (error) {
            console.error(error);
            if (toast.addToast) {
                toast.addToast({
                    description: `Failed to update organization`,
                    variant: "error",
                });
            }
            return;
        }

        runInAction(() => {
            this.updateCurrentDeployment({
                ...deployment,
                organization: null,
            });
            this.loading = false;
            onComplete && onComplete();
        });
    }

    async delete({ deployment, onComplete }: DeploymentUpdateOperationInput) {
        this.loading = true;

        const res = await api.post("/graphql", {
            query: DELETE_DEPLOYMENT,
            variables: { deploymentID: deployment.uid },
        });

        if (res.data.errors?.length) {
            throw new Error(res.data.errors[0].message);
        }

        runInAction(() => {
            if (this.currentDeployment?.uid === deployment.uid) {
                this.currentDeployment = null;
            }

            this.deployments = this.deployments.filter(
                deploymentToCheck => deploymentToCheck.uid !== deployment.uid
            );
            this.loading = false;
            onComplete && onComplete();
        });
    }

    updateCurrentDeployment(deployment: Deployment) {
        this.currentDeployment = deployment;
        this.deployments = this.deployments.map(mappedDeployment =>
            mappedDeployment.uid === deployment.uid
                ? deployment
                : mappedDeployment
        );
    }

    setCurrent(deployment: Deployment) {
        this.currentDeployment = deployment;

        this.currentHeaders = this.deploymentHeaderCacheManager.getHeaders(
            deployment.uid
        );

        this.selectedNamespace = null;

        window.localStorage.setItem(CURRENT_DEPLOYMENT_KEY, deployment?.uid);
    }

    setDeployed(uid: string, vars = {}) {
        // It's weird this is done twice
        for (var deployment of this.deployments) {
            if (deployment.uid === uid) {
                deployment.isDeploying = false;
                Object.assign(deployment, vars);
            }
        }

        if (this.currentDeployment?.uid === uid) {
            this.currentDeployment.isDeploying = false;
            Object.assign(this.currentDeployment, vars);
        }
    }

    updateHeaders(headers: AuthHeader[]) {
        if (this.currentDeployment) {
            this.currentHeaders = headers;
            this.deploymentHeaderCacheManager.setHeaders(
                this.currentDeployment.uid,
                headers
            );
        }
    }

    isFreeBackendOptionAvailable = () =>
        this.deployments.filter(
            ({ deploymentType }) => deploymentType === "free"
        ).length === 0;

    updateDeployments = (deployments: Deployment[]) => {
        this.deployments = deployments;
    };
}

// @ts-ignore
decorate(DeploymentStore, {
    updateDeployments: action,
    deployments: observable,
    currentDeployment: observable,
    selectedNamespace: observable,
    currentHeaders: observable,
    loading: observable,
    load: action,
    create: action,
    delete: action,
    rename: action,
    getDeploymentByID: action,
    getSummaryAttributes: action,
    loadPatchAttributes: action,
    getHealthInfo: action,
    patchDeployment: action,
    searchDeployments: action,
    setCurrent: action,
    setDeployed: action,
    setHeader: action,
    updateHeaders: action,
    setOrganization: action,
    removeOrganization: action,
    purgeStore: action,
    restoreMutation: action,
    restoreQuery: action,
    isFreeBackendOptionAvailable: action,
    switchMode: action,
    createExport: action,
    setNamespace: action,
});

export default new DeploymentStore();
