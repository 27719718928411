export const styles: any = {
    Container: {
        position: "relative",
    },
    DropdownButton: {
        padding: 0,
        background: "none",
        width: "fit-content",
        height: "fit-content",
    },
    AccountInfo: {
        flexWrap: "nowrap",
        padding: 2,
        cursor: "default",
    },
    LinkButtonStyles: {
        width: "100%",
        textAlign: "left",
        borderRadius: 0,
        paddingX: 2,
        fontWeight: 500,
    },
    AccountDropdown: {
        padding: 0,
        position: "absolute",
        right: 0,
        top: "110%",
        boxShadow: 3,
    },
    TermsStyles: {
        color: "grey",
        fontSize: "{{fontSizes.1}}",
        whiteSpace: "nowrap",
        margin: 0,
        paddingY: 2,
    },
    Circle: {
        size: "4px",
        backgroundColor: "grey.border",
        borderRadius: 3,
    },
};
