import dev from "./dev";

export default {
    ...dev,
    auth0: {
        ...dev.auth0,
        popupAuth: false,
    },
    auth: {
        github: {
            clientId: "96cc8beb341bb9dd589d",
            redirect_uri: "https://app.thegaas.com/oauth/github",
        },
        google: {
            clientId:
                "1050440161966-rrod2u5auspdpt7k3o40n4l7hgoq2h8p.apps.googleusercontent.com",
        },
    },
    httpProtocol: "https",
    serverUrl: "https://cerebro.stage.thegaas.com",
    graphqlServerUrl: "https://cerebro.stage.thegaas.com/graphql",
    analyticsServerUrl: "https://analytics.dgraph.io/debug",
    monitoringUrl:
        "https://github.com/dgraph-io/slashgraphql-monitoring/blob/master/documentation/debugging.md",
    sentry: {
        dsn: dev.sentry.dsn,
        environment: "staging",
    },
    grafanaPromUrl:
        "https://g.o.stage.thegaas.com/d/ADsEUzNMz/customer-dashboard?orgId=1&",
    grafanaLogsUrl: "https://g.o.stage.thegaas.com/explore?orgId=1&",
    hubspot: {
        form:
            "https://api.hsforms.com/submissions/v3/integration/submit/7078637/3e60480c-8f19-4dd7-9914-1bbda4bf2a1f",
    },
    features: {
        manageBackend: true,
    },
};
