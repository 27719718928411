export const styles: any = {
    Badge: {
        padding: "2px",
        borderRadius: 1,
        width: "fit-content",
        minWidth: "50px",
        textAlign: "center",
        color: "blue.dark",
        background: "blue.surface",
    },
};
