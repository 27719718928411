import { maxWidth } from "./../../mediaqueries";
export const styles: any = {
    Heading: {
        background: "linear-gradient(90deg, #FF4200 0%, #E50695 100%)",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        textAlign: "center",
        marginY: 2,
        lineHeight: 1.3,
        fontSize: 7,
        fontWeight: 600,
    },

    CardStyles: {
        width: "400px",
        height: "320px",
    },
    Highlight: {
        background: "linear-gradient(90deg, #FF4200 0%, #E50695 100%)",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
    },
    Vanity: {
        background: "linear-gradient(90deg, #FF4200 0%, #E50695 100%)",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        fontSize: 5,
        fontWeight: 600,
    },
    Center: {
        textAlign: "center",
    },
    Terms: {
        background: "grey.selected",
        padding: 1,
        textAlign: "center",
    },
    LeftPage: {
        width: "70%",
        backgroundColor: "grey.surface",
        [maxWidth[2]]: {
            display: "none",
        },
    },
    RightPage: {
        width: "30%",
        [maxWidth[2]]: {
            width: "100%",
        },
    },

    UserImage: {
        height: 5,
    },
    ActiveImage: {
        height: 8,
        transition: "0.1s ease",
    },
    Text: {
        background: "linear-gradient(90deg, #FF4200 0%, #E50695 100%)",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        fontWeight: 600,
    },
    Waves: { right: "30%", position: "absolute", top: 0 },
    Banner: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        padding: 1,
        background: "blue",
        zIndex: "navbar",
    },
    SignupStack: {
        maxWidth: 300, // because tabWidth="150px" in AuthHomePage.tsx
    },
};
