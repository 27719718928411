import { SubmitForm } from "components/pages/PricingCalculator/PricingCalculator.types";
import Axios from "axios";
import config from "config";

export const submitHubspotForm = async ({
    contactForm,
    settingsForm,
    deploymentCostPerHour,
    customPlanRequired,
}: SubmitForm) => {
    const {
        cloudProvider,
        zone,
        dgraphHA,
        dgraphHosted,
        selectedTier,
        storage,
        alphaNodes,
        zeroNodes,
    } = settingsForm;

    const { name, email, companyName, jobTitle, phone } = contactForm;

    const totalCost = (deploymentCostPerHour * 24 * (365 / 12)).toFixed(2);

    const res = await Axios.post(config.hubspot.form, {
        fields: [
            {
                name: "email",
                value: email.value,
            },
            {
                name: "firstname",
                value: name.value,
            },
            {
                name: "company",
                value: companyName.value,
            },
            {
                name: "jobtitle",
                value: jobTitle.value,
            },
            {
                name: "phone",
                value: phone.value,
            },
            ...(!customPlanRequired
                ? [
                      {
                          name: "dc_ram",
                          value: selectedTier.value.ram,
                      },
                      {
                          name: "cloud_provider",
                          value: cloudProvider.value?.key || "NA",
                      },
                      {
                          name: "dc_region",
                          value: zone.value,
                      },
                      {
                          name: "dc_storage",
                          value: storage.value,
                      },
                      {
                          name: "dc_dgraph_ha",
                          value: dgraphHA.value,
                      },
                      {
                          name: "dc_dgraph_hosted",
                          value: dgraphHosted.value,
                      },
                      {
                          name: "dc_hourly_cost",
                          value: deploymentCostPerHour?.toFixed(2) || 0,
                      },
                      {
                          name: "dc_monthly_cost",
                          value: totalCost || 0,
                      },
                      {
                          name: "dc_cpu",
                          value: selectedTier.value.vCPU,
                      },
                      {
                          name: "dc_alpha_nodes",
                          value: alphaNodes.value || 0,
                      },
                      {
                          name: "dc_zero_nodes",
                          value: zeroNodes.value || 0,
                      },
                  ]
                : [
                      {
                          name: "cloud_provider",
                          value: "yes",
                      },
                  ]),
        ],
    });

    return res;
};
