import React from "react";
import { Stack, Button, Text, Input, Form } from "@dgraph-io/typhoon-ui";
import { styles } from "../Auth.styles";

import { LoginProps } from "../Auth.types";
import { useLoginForm } from "../hooks/useLoginForm";
import { uuid } from "@dgraph-io/typhoon-ui";
import { useLogin } from "gql/hooks/users/users.hook";

import { GoogleLoginButton } from "../Social/Google";
import { GithubLoginButton } from "../Social/Github";

const Login = ({ isForgotPassword }: LoginProps) => {
    const {
        loginForm: { email, password },
        handleLoginError,
        handleRedirect,
        errors,
        onGoogleSuccess,
        onGoogleFailure,
        touched,
        setTouched,
    } = useLoginForm();

    const [login, { loading }] = useLogin({
        email: email.value,
        password: password.value,
        onCompleted: handleRedirect,
        onError: handleLoginError,
    });

    return (
        <Stack gap={2} direction="vertical">
            <GoogleLoginButton
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                text="Sign in with Google"
            />
            <GithubLoginButton text="Sign in with GitHub" />
            <Text styles={styles.Center}>Or</Text>
            <Form
                onSubmit={event => {
                    event.preventDefault();
                    login({
                        variables: {
                            email: email.value,
                            password: password.value,
                        },
                    });
                }}
            >
                <Stack gap={2} direction="vertical">
                    <Input
                        {...email}
                        placeholder="yours@example.com"
                        onBlur={() => setTouched({ ...touched, email: true })}
                    />
                    {touched.email &&
                        email.errors.map(error => (
                            <Text color="red" variant={2} key={uuid()}>
                                {error.message}
                            </Text>
                        ))}
                    <Input
                        {...password}
                        type="password"
                        placeholder="your password"
                        onBlur={() =>
                            setTouched({ ...touched, password: true })
                        }
                    />
                    {touched.password &&
                        password.errors.map(error => (
                            <Text color="red" variant={2} key={uuid()}>
                                {error.message}
                            </Text>
                        ))}
                    <Stack
                        styles={{
                            margin: "auto",
                            marginTop: 2,
                            cursor: "pointer",
                        }}
                        onClick={() => isForgotPassword(true)}
                    >
                        <Text variant={2}>
                            Don&apos;t remember your password?
                        </Text>
                    </Stack>
                    <Button
                        type="submit"
                        disabled={loading || errors.length > 0}
                    >
                        Login
                    </Button>
                </Stack>
            </Form>
        </Stack>
    );
};

export default Login;
