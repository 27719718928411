import React, { useContext } from "react";
import { Stack } from "@dgraph-io/typhoon-ui";
import { TourContext } from "components/shared/Tour/Tour";
import { useAuth } from "libs/auth";
import { styles } from "./Layout.styles";
import { useAppRoute } from "config";
import { getNavlinks } from "./navigationlinks";
import { useLocation } from "react-router-dom";
import { launchRatel } from "./utils";
import { Sidebar } from "components/shared/Sidebar/Sidebar";
import { SidebarGroup } from "components/shared/Sidebar/SidebarGroup";

export default function SlashMobileSidebar({
    disabled = false,
    onClick,
}: {
    disabled: boolean;
    onClick: () => void;
}) {
    const { user } = useAuth();
    const { path } = useAppRoute();
    const location = useLocation();
    const isSuperAdmin = user?.config.isSuperAdmin === true;
    const { dispatchTourEvent } = useContext(TourContext);

    const {
        overviewLinks,
        developLinks,
        adminLinks,
        resourceLinks,
    } = getNavlinks({
        path,
        disabled,
        isSuperAdmin,
        dispatchTourEvent,
        openRatel: launchRatel,
    });

    return (
        <Stack
            styles={styles.AppSidebarMobileContainerStyles}
            onClick={onClick}
        >
            <Sidebar styles={styles.SidebarStyles}>
                <SidebarGroup
                    links={overviewLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Develop"
                    links={developLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Admin"
                    links={adminLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Resources"
                    links={resourceLinks}
                    pathname={location.pathname}
                />
            </Sidebar>
        </Stack>
    );
}
