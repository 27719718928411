import React from "react";
import { Button, Card, Radio, Stack, StylesObject } from "@dgraph-io/typhoon-ui";
import Editor, { Monaco } from "@monaco-editor/react";
import Spinner from "components/shared/Spinner/Spinner";
import { DqlOperationType } from "../../reducer/dqlQueryExecutor.reducer.types";

export const QueryPanel = ({
    query,
    mutation,
    setQuery,
    setMutation,
    predicates,
    dqlTypes,
    operationType,
    setOperationType,
    onExecute,
    styles: stylesProp,
}: {
    query: string;
    setQuery: (query: string) => void;
    mutation: string;
    setMutation: (query: string) => void;
    predicates: Array<string>;
    dqlTypes: Array<string>;
    operationType: DqlOperationType;
    setOperationType: (operationType: DqlOperationType) => void;
    onExecute: () => void;
    styles?: StylesObject;
}) => {
    const operation = operationType === "query" ? query : mutation;
    const setOperation = (value: string) => {
        if (operationType === "query") {
            setQuery(value);
        } else {
            setMutation(value);
        }
    };
    const clearQuery = () => setOperation("");
    const beforeMount = (monaco: Monaco) => {
        monaco.languages.register({ id: "dql" });
        monaco.languages.registerDocumentFormattingEditProvider("dql", {
            // provideDocumentFormattingEdits: function (model: any) {
            //     return [
            //         {
            //             range: model.getFullModelRange(),
            //             text: dqlformatter(
            //                 model.getValue(),
            //                 predicates,
            //                 dqlTypes
            //             ),
            //         },
            //     ];
            // },
        });

        const config = {
            surroundingPairs: [
                { open: "{", close: "}" },
                { open: "[", close: "]" },
                { open: "(", close: ")" },
                { open: "<", close: ">" },
                { open: "'", close: "'" },
                { open: '"', close: '"' },
            ],
            autoClosingPairs: [
                { open: "{", close: "}" },
                { open: "[", close: "]" },
                { open: "(", close: ")" },
                { open: "'", close: "'", notIn: ["string", "comment"] },
                { open: '"', close: '"', notIn: ["string", "comment"] },
            ],
        };
        monaco.languages.setLanguageConfiguration("dql", config);
        monaco.languages.setMonarchTokensProvider("dql", {
            tokenizer: {
                root: [
                    [/func:/, "name"],
                    ["type", "name"],
                ],
            },
        });
        // Define a new theme that contains only rules that match this language
        monaco.editor.defineTheme("dql-theme", {
            base: "vs",
            inherit: true,
            rules: [{ token: "name", foreground: "5A59C6" }],
        });
    };
    return (
        <Card
            styles={{
                ...stylesProp,
                paddingY: 0,
                paddingX: 2,
                position: "relative",
            }}
        >
            <Stack
                direction="horizontal"
                justify="space-between"
                align="center"
                styles={{
                    paddingY: 1,
                    borderBottom:
                        "{{lineThickness.1}} solid {{colors.grey.border}}",
                }}
            >
                <Stack direction="horizontal" gap={1}>
                    <Radio
                        label="Query"
                        id="query"
                        checked={operationType === "query"}
                        onClick={() => {
                            setOperationType("query");
                        }}
                    />
                    <Radio
                        label="Mutation"
                        id="mutation"
                        checked={operationType == "mutation"}
                        onClick={() => {
                            setOperationType("mutation");
                        }}
                    />
                </Stack>

                <Stack direction="horizontal" gap={1} align="center">
                    <Button
                        variant="ghost"
                        onClick={clearQuery}
                        styles={{
                            fontWeight: 500,
                            marginLeft: "auto",
                            height: 3,
                            fontSize: 1,
                            paddingX: 1,
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        onClick={onExecute}
                        styles={{
                            height: "fit-content",
                            paddingY: 1,
                        }}
                    >
                        Run
                    </Button>
                </Stack>
            </Stack>
            <Editor
                height="calc(100% - 60px)" // By default, it fully fits with its parent
                theme={"dql-theme"}
                language={"dql"}
                loading={<Spinner />}
                value={operation}
                beforeMount={beforeMount}
                onChange={value => {
                    value && setOperation(value);
                }}
                options={{
                    fontSize: 14,
                    lineNumbersMinChars: 2,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </Card>
    );
};
