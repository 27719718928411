import {
    OperationVariables,
    useLazyQuery,
    useMutation,
    useQuery,
} from "@apollo/client";
import { oauthLogin } from "gql/types/oauthLogin";
import { reauthenticate } from "gql/types/reauthenticate";
import { register } from "gql/types/register";
import { REGISTER, LOGIN, REAUTHENTICATE, OAUTH_LOGIN } from "gql/users.gql";
import { useAuth } from "libs/auth";
import trackEvent, { events, ignoreErrorMessages } from "libs/trackevents";
import {
    CustomLazyQueryHook,
    CustomMutationHook,
    CustomQueryHook,
} from "../graphql.hook.type";
import {
    UseGetCurrentCloudUserResult,
    UseLoginVariables,
    UseLoginResult,
    UseRegisterVariables,
    UseRegisterResult,
    useOAuthLoginVariables,
    UseOAuthLoginResult,
} from "./users.hook.types";

export const useRegister: CustomMutationHook<
    UseRegisterVariables,
    UseRegisterResult
> = ({ email, password, onCompleted, onError }) => {
    const [register, mutationResult] = useMutation<register>(REGISTER, {
        variables: { email, password },
        onCompleted,
        onError,
    });
    return {
        ...mutationResult,
        register,
    };
};

export const useLogin: CustomLazyQueryHook<
    UseLoginVariables,
    UseLoginResult,
    OperationVariables
> = ({ email, password, onCompleted, onError }) => {
    const { onLoginSuccess } = useAuth();
    const [login, { data, ...useQueryResult }] = useLazyQuery<
        UseLoginResult,
        OperationVariables
    >(LOGIN, {
        variables: { email, password },
        onCompleted: data => {
            if (data?.login) {
                if (onLoginSuccess) {
                    onLoginSuccess(data.login);
                    trackEvent(events.loginSuccess);
                }

                if (onCompleted) {
                    onCompleted(data?.login);
                }
            }
        },
        onError: err => {
            if (!ignoreErrorMessages.includes(err.message)) {
                trackEvent(events.loginFail, {
                    error: err.message,
                });
            }
            onError && onError(err);
        },
    });

    return [
        login,
        {
            ...useQueryResult,
            data,
            login: data?.login,
        },
    ];
};

export const useGetCurrentCloudUser: CustomQueryHook<
    void,
    UseGetCurrentCloudUserResult
> = () => {
    const {
        onReauthenticationSuccess,
        onReauthenticationError,
        isAuthenticated,
    } = useAuth();
    const { refetch, ...useQueryResult } = useQuery<reauthenticate>(
        REAUTHENTICATE,
        {
            notifyOnNetworkStatusChange: true,
            skip: isAuthenticated,
            onCompleted: data => {
                if (data?.reauthenticate && onReauthenticationSuccess) {
                    onReauthenticationSuccess(data.reauthenticate);
                }
            },
            onError: onReauthenticationError,
        }
    );

    const refetchCloudUser = async () => {
        const data = await refetch();
        if (data.data.reauthenticate) {
            onReauthenticationSuccess(data.data.reauthenticate);
        }
    };

    return {
        refetch,
        refetchCloudUser,
        ...useQueryResult,
    };
};

export const useOAuthLogin: CustomMutationHook<
    useOAuthLoginVariables,
    UseOAuthLoginResult
> = ({ provider, token, onCompleted, onError }) => {
    const { onLoginSuccess } = useAuth();
    const [oauthLoginFunc, { data, ...useQueryResult }] = useMutation<
        oauthLogin
    >(OAUTH_LOGIN, {
        variables: { provider, token },
        onCompleted: data => {
            if (data?.oauthLogin) {
                if (onLoginSuccess) {
                    trackEvent(events.loginSuccess);
                    onLoginSuccess(data.oauthLogin);
                }

                if (onCompleted) {
                    onCompleted(data?.oauthLogin);
                }
            }
        },
        onError: err => {
            if (!ignoreErrorMessages.includes(err.message)) {
                trackEvent(events.loginFail, {
                    error: err.message,
                });
            }
            onError && onError(err);
        },
    });
    return {
        ...useQueryResult,
        data,
        oauthLogin: oauthLoginFunc,
    };
};
