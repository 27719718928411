import { QueryResult, useQuery } from "@apollo/client";
import { Clusters } from "gql/types/Clusters";
import { GetKubeConfig } from "gql/types/GetKubeConfig";
import { GET_CLUSTERS, GET_KUBE_CONFIG } from "../../clusters.gql";
import { CustomQueryHook } from "../graphql.hook.type";
import { UseClustersResult, KubeConfigInputType } from "./clusters.hook.types";

export const useClusters: CustomQueryHook<void, UseClustersResult> = () => {
    const useQueryResult = useQuery<Clusters>(GET_CLUSTERS);
    return {
        ...useQueryResult,
        clusters: useQueryResult.data?.clusters
            ? useQueryResult.data.clusters.map(cluster => ({
                  country: cluster?.country || "",
                  name: cluster?.name || "",
                  zoneID: cluster?.zone || "",
                  isAvailable: cluster?.isAvailable || false,
                  slashAvailable: cluster?.slashAvailable || false,
                  platform: cluster?.platform || "enterprise",
                  ...(cluster?.zone === "stgdgraph" ? { provider: "aws" } : {}),
              }))
            : [],
    };
};

export const useGetKubeConfig: CustomQueryHook<
    KubeConfigInputType,
    QueryResult
> = ({ deploymentId, podType, name }: KubeConfigInputType) => {
    const useQueryResult = useQuery<GetKubeConfig>(GET_KUBE_CONFIG, {
        variables: { deploymentID: deploymentId, podType: podType, name: name },
        notifyOnNetworkStatusChange: true,
    });
    return { ...useQueryResult };
};
