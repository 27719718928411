import React from "react";
import { ConfigTable } from "../ConfigTable/ConfigTable";
import { prometheusConfig } from "./constants";
import { PrometheusConfigSelectionPropsType } from "./PrometheusConfigSelection.types";

export const PrometheusConfigSelection = ({
    selected,
    onSelect,
    disabled = false,
}: PrometheusConfigSelectionPropsType) => {
    return (
        <ConfigTable
            onSelect={({ id }) => onSelect(Number(id))}
            selected={prometheusConfig.options.find(
                option => Number(option.id) == selected
            )}
            config={prometheusConfig}
            disabled={disabled}
        />
    );
};
