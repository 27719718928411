import { Deployment, Namespace } from "store/deployments/deployments.types";

import config from "config";

export const getDeploymentUrl = (deployment: Deployment) =>
    config.httpProtocol + "://" + deployment.url;

export const isDeploymentFreeOrShared = (deployment: Deployment) =>
    deployment.deploymentType === "free" ||
    deployment.deploymentType === "shared";

export const headers = (
    deployment: Deployment,
    namespace: Namespace | null
): Record<string, string> => {
    return {
        "X-Auth-Token": deployment.jwtToken,
        ...(deployment.accessToken !== null || namespace?.accessToken
            ? {
                  "X-Dgraph-AccessToken":
                      namespace?.accessToken! || deployment.accessToken!,
              }
            : {}),
    };
};
