import {
    GetDeploymentCost,
    GetDeploymentCostVariables,
} from "./../../types/GetDeploymentCost";
import { useQuery } from "@apollo/client";
import { GET_DEPLOYMENT_COST } from "gql/pricing.gql";
import { DeploymentCostInput } from "../../../../types/globalTypes";

export const useDeploymentCost = ({
    platform,
    zone,
    storage,
    highAvailability,
    jaegerEnabled,
    jaegerSize,
    deploymentSize,
    shardCount,
    prometheusEnabled,
    prometheusSize,
}: DeploymentCostInput) => {
    const queryResult = useQuery<GetDeploymentCost, GetDeploymentCostVariables>(
        GET_DEPLOYMENT_COST,
        {
            variables: {
                input: {
                    platform,
                    zone,
                    deploymentSize,
                    storage,
                    highAvailability,
                    jaegerEnabled,
                    jaegerSize,
                    shardCount,
                    prometheusEnabled,
                    prometheusSize,
                },
            },
            notifyOnNetworkStatusChange: true,
        }
    );

    return {
        ...queryResult,
        costPerHour: queryResult.data?.getDeploymentCost?.totalCost || 0,
        storageCost: queryResult.data?.getDeploymentCost?.storageCost || 0,
        dataTransferCost:
            queryResult.data?.getDeploymentCost?.dataTransferCost || 0,
    };
};
