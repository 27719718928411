import React from "react";
import { Tier, TierGroup, tierGroups } from "../ProviderData";
import { TierTablePropTypes } from "../PricingCalculator.types";
import {
    Button,
    Container,
    Input,
    Link,
    Stack,
    Switch,
    Text,
} from "@dgraph-io/typhoon-ui";
import { styles } from "./TierTable.styles";
import { MemorySlider, MEMORY_OPTIONS } from "./MemorySlider";

const isTierInTheGroup = (tierGroup: TierGroup, tier: Tier) =>
    tierGroup.cType?.id === tier.id ||
    tierGroup.mType?.id === tier.id ||
    tierGroup.rType?.id === tier.id;

function Tiers(props: TierTablePropTypes) {
    return <TierTableView {...props} tierGroups={tierGroups} />;
}

const TierTableView = ({
    selected,
    onSelect,
    disabled = false,
    storage,
    setStorage,
    ha,
    setHA,
    disableHAToggle = false,
    disableStorage = false,
    shardCount,
    setShardCount,
    minShardCount,
    tierGroups,
}: {
    selected: Tier | null;
    disabled?: boolean;
    storage: number;
    setStorage: (value: number) => void;
    ha: boolean;
    setHA: (value: boolean) => void;
    onSelect: (tier: Tier) => void;
    disableHAToggle?: boolean;
    disableStorage?: boolean;
    shardCount: number;
    setShardCount: (count: number) => void;
    minShardCount: number;
    tierGroups: Array<TierGroup>;
}) => {
    // const debouncedStorageUpdate = debounce((e: any) => {
    //     setStorage(Number(e.target.value));
    // }, 50);
    return (
        <Stack direction="vertical">
            <Stack
                direction="horizontal"
                styles={{
                    height: "40px",
                    borderBottom:
                        "{{lineThickness.1}} solid {{colors.grey.border}}",
                    borderTop:
                        "{{lineThickness.1}} solid {{colors.grey.border}}",
                }}
            >
                <Container styles={styles.FirstCell}>
                    <Text color="grey" variant={3} weight={500}>
                        vCPU
                    </Text>
                </Container>
                <Container styles={styles.Cell}>
                    <Text color="grey" variant={3} weight={500}>
                        RAM
                    </Text>
                </Container>
                <Container styles={styles.Cell}>
                    <Text color="grey" variant={3} weight={500}>
                        Base Price
                    </Text>
                </Container>
            </Stack>
            {tierGroups.map(tierGroup => {
                const isSelectedTierGroup = selected
                    ? isTierInTheGroup(tierGroup, selected)
                    : false;
                const defaultTierOfTheGroup = tierGroup[tierGroup.default]!;
                const ram = `${
                    isSelectedTierGroup
                        ? selected?.ram
                        : defaultTierOfTheGroup.ram
                } GB`;

                return (
                    <>
                        <Stack
                            direction="vertical"
                            styles={
                                isSelectedTierGroup ? styles.SelectedRow : {}
                            }
                            key={tierGroup.cores}
                        >
                            <Stack
                                direction="horizontal"
                                onClick={() => {
                                    !isSelectedTierGroup &&
                                        onSelect(defaultTierOfTheGroup);
                                }}
                                styles={{
                                    ...styles.Row,
                                    ...styles.TierRow,
                                }}
                                align="center"
                            >
                                <Container
                                    styles={{
                                        ...styles.FirstCell,
                                        ...styles.TierCell,
                                        position: "relative",
                                    }}
                                >
                                    {tierGroup.cores}
                                </Container>
                                <Container styles={styles.Cell}>
                                    {ram}
                                </Container>
                                <Container styles={styles.Cell}>
                                    {`from $${tierGroup.startingPrice}/hr`}
                                </Container>
                            </Stack>
                            {isSelectedTierGroup && (
                                <Stack
                                    direction="vertical"
                                    gap={2}
                                    styles={{ padding: 2, paddingTop: 1 }}
                                >
                                    <Stack
                                        direction="horizontal"
                                        justify="space-between"
                                        align="center"
                                        wrap="nowrap"
                                    >
                                        <Stack direction="vertical">
                                            <Text
                                                color="grey"
                                                weight={500}
                                                variant={2}
                                            >
                                                Class
                                            </Text>
                                        </Stack>
                                        <Stack
                                            justify="flex-start"
                                            gap={1}
                                            styles={{
                                                width: "calc(100% - 180px)",
                                                height: "fit-content",
                                            }}
                                        >
                                            {tierGroup.cType && (
                                                <Button
                                                    styles={{
                                                        ...styles.Class,
                                                        ...(tierGroup.cType
                                                            .id === selected?.id
                                                            ? styles.SelectedClass
                                                            : {}),
                                                    }}
                                                    disabled={
                                                        tierGroup.cType === null
                                                    }
                                                    onClick={() => {
                                                        onSelect(
                                                            tierGroup.cType!
                                                        );
                                                    }}
                                                >
                                                    Compute Optimized
                                                </Button>
                                            )}
                                            {tierGroup.mType && (
                                                <Button
                                                    styles={{
                                                        ...styles.Class,
                                                        ...(tierGroup.mType
                                                            .id === selected?.id
                                                            ? styles.SelectedClass
                                                            : {}),
                                                    }}
                                                    disabled={
                                                        tierGroup.mType === null
                                                    }
                                                    onClick={() => {
                                                        onSelect(
                                                            tierGroup.mType!
                                                        );
                                                    }}
                                                >
                                                    General Purpose
                                                </Button>
                                            )}
                                            {tierGroup.rType && (
                                                <Button
                                                    styles={{
                                                        ...styles.Class,
                                                        ...(tierGroup.rType
                                                            .id === selected?.id
                                                            ? styles.SelectedClass
                                                            : {}),
                                                    }}
                                                    disabled={
                                                        tierGroup.rType === null
                                                    }
                                                    onClick={() => {
                                                        onSelect(
                                                            tierGroup.rType!
                                                        );
                                                    }}
                                                >
                                                    Memory Optimized
                                                </Button>
                                            )}
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        direction="horizontal"
                                        gap={1}
                                        justify="space-between"
                                        align="flex-start"
                                        wrap="nowrap"
                                    >
                                        <Stack direction="vertical">
                                            <Text
                                                color="grey"
                                                weight={500}
                                                variant={2}
                                            >
                                                Storage Size
                                            </Text>
                                        </Stack>
                                        <Stack
                                            gap={1}
                                            align="flex-start"
                                            fullWidth={true}
                                            styles={{
                                                width: "calc(100% - 180px)",
                                                maxWidth: "700px",
                                                height: "fit-content",
                                            }}
                                        >
                                            {MEMORY_OPTIONS.map(
                                                ({ value }) => value
                                            ).includes(storage) ? (
                                                <MemorySlider
                                                    setValue={setStorage}
                                                    selected={storage}
                                                    styles={{
                                                        transform:
                                                            "translateY(6px)",
                                                    }}
                                                />
                                            ) : (
                                                <Stack
                                                    direction="horizontal"
                                                    gap={1}
                                                    align="center"
                                                >
                                                    <Input
                                                        styles={{
                                                            paddingY: "auto",
                                                            height: "30px",
                                                            width: "100px",
                                                        }}
                                                        defaultValue={storage}
                                                        disabled={
                                                            disableStorage
                                                        }
                                                        key={storage}
                                                        type="number"
                                                        onBlur={e => {
                                                            setStorage(
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                    />
                                                    <Text
                                                        color="grey"
                                                        weight={600}
                                                    >
                                                        GB
                                                    </Text>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="horizontal"
                                        gap={1}
                                        justify="space-between"
                                        align="flex-start"
                                        wrap="nowrap"
                                    >
                                        <Stack direction="vertical">
                                            <Text
                                                color="grey"
                                                weight={500}
                                                variant={2}
                                            >
                                                High Availability
                                            </Text>
                                            <Text color="grey" variant={2}>
                                                This would run 3 replicas of
                                                each Dgraph instance. Improved{" "}
                                                <Link
                                                    href="https://uptime.is/99.99"
                                                    openInNewTab={true}
                                                >
                                                    99.99%
                                                </Link>{" "}
                                                SLA.
                                            </Text>
                                        </Stack>
                                        <Switch
                                            id="ha"
                                            disabled={
                                                disableHAToggle && ha === true
                                            }
                                            checked={ha}
                                            onChange={() => setHA(!ha)}
                                        />
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </>
                );
            })}
        </Stack>
    );
};

export default Tiers;
