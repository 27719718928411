import React, { useState, useRef } from "react";
import {
    Button,
    Avatar,
    Card,
    Link,
    Stack,
    Divider,
    Text,
    Container,
} from "@dgraph-io/typhoon-ui";
import { styles } from "./AccountDropdown.styles";
import history from "libs/browserHistory";
import { useOnClickOutside } from "@dgraph-io/typhoon-ui";

export const AccountDropdown = ({
    initialIsOpen = false,
    user,
    path,
    logout,
}: {
    initialIsOpen?: boolean;
    path: string;
    logout?: () => void;
    user: { picture?: string; email: string; name?: string };
}) => {
    const [showUserDropdown, setShowUserDropdown] = useState(
        initialIsOpen || false
    );
    const ref = useRef();
    useOnClickOutside(ref, () => setShowUserDropdown(false));

    const dpPic = user.picture || "/dp.svg";

    return (
        <Container styles={styles.Container}>
            <Button
                styles={styles.DropdownButton}
                onClick={() => setShowUserDropdown(!showUserDropdown)}
            >
                <Avatar variant={3} src={dpPic} />
            </Button>

            {showUserDropdown && (
                <Card ref={ref} styles={styles.AccountDropdown}>
                    <Stack
                        direction="horizontal"
                        gap={1}
                        styles={styles.AccountInfo}
                    >
                        <Avatar variant={3} src={dpPic} />
                        <Stack direction="vertical" justify="space-around">
                            {user.name && <Text>{user.name}</Text>}
                            <Text color="grey" variant={1}>
                                {user.email}
                            </Text>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack
                        direction="vertical"
                        inline={true}
                        align="flex-start"
                        fullWidth={true}
                    >
                        <Button
                            variant="ghost"
                            styles={styles.LinkButtonStyles}
                            onClick={() => {
                                setShowUserDropdown(false);
                                history.push(path + "/organizations");
                            }}
                        >
                            Organizations
                        </Button>
                        <Button
                            variant="ghost"
                            styles={styles.LinkButtonStyles}
                            onClick={() => {
                                setShowUserDropdown(false);
                                history.push(path + "/billing");
                            }}
                        >
                            Billing
                        </Button>
                        <Button
                            variant="ghost"
                            styles={styles.LinkButtonStyles}
                            onClick={logout}
                        >
                            Log out
                        </Button>
                    </Stack>
                    <Divider />
                    <Stack
                        gap={1}
                        direction="horizontal"
                        styles={{ paddingX: 3, flexWrap: "nowrap" }}
                        justify="space-between"
                        align="center"
                    >
                        <Link
                            href="https://dgraph.io/slash-terms.html"
                            openInNewTab={true}
                        >
                            <Text styles={styles.TermsStyles}>
                                Terms of use
                            </Text>
                        </Link>
                        <Container styles={styles.Circle} />
                        <Link
                            href="https://dgraph.io/slash-dpa.html"
                            openInNewTab={true}
                        >
                            <Text styles={styles.TermsStyles}>
                                Privacy Policy
                            </Text>
                        </Link>
                    </Stack>
                </Card>
            )}
        </Container>
    );
};
