import dev from "./dev";
import stg from "./stg";
import prod from "./prod";

const configs = { dev, stg, prod };

// @ts-ignore
const exportedConfig = configs[process.env.REACT_APP_CONFIG];

export function useAppRoute() {
    return {
        path: "/" + exportedConfig.routes.appPrefix,
    };
}

export default exportedConfig;
