import { StylesObject } from "@dgraph-io/typhoon-ui";

export const styles: Record<string, StylesObject> = {
    TourWidgetContainer: {
        padding: 2,
        width: "230px",
        boxShadow: 3,
        boxSizing: "border-box",
        userSelect: "none",
    },
    Collapsible: {
        cursor: "pointer",
    },
    ChevronStyles: {
        marginLeft: 1,
        width: 1,
        height: 1,
        borderBottom: "2px solid {{colors.grey}} ",
        borderRight: "2px solid {{colors.grey}} ",
    },

    ExitButtonStyles: { marginLeft: "auto !important", height: 4, paddingX: 1 },
};
