import React, { useState } from "react";
import { Stack, Text, Image, Card, Divider } from "@dgraph-io/typhoon-ui";
import Carousel from "nuka-carousel";
import { reviews, users } from "./userReviews";
import { styles } from "../Auth.styles";

const ReviewCarousel = () => {
    const [currentUserReview, setCurrentUserReview] = useState(2);
    const [slideIndex, setSlideIndex] = useState(2);

    let activeUser = users.filter(user => user.id === currentUserReview)[0];

    return (
        <>
            <Carousel
                autoplay
                wrapAround
                afterSlide={index => {
                    setSlideIndex(index);
                    setCurrentUserReview(index);
                }}
                withoutControls={true}
                slideIndex={slideIndex}
                speed={1000}
            >
                {reviews.map(review => {
                    return (
                        <Stack justify="center" key={review.id}>
                            <Card styles={styles.CardStyles}>
                                <Stack
                                    direction="vertical"
                                    align="start"
                                    gap={2}
                                >
                                    <Text variant={4}>
                                        {review.description}
                                    </Text>

                                    <Divider />

                                    <Stack
                                        styles={{
                                            bottom: 4,
                                            position: "absolute",
                                        }}
                                        direction="vertical"
                                        gap={2}
                                        align="start"
                                    >
                                        <Image
                                            fullWidth={false}
                                            alt={review.author}
                                            src={review.companyLogo}
                                        />
                                        <Stack>
                                            <Text styles={styles.Highlight}>
                                                {review.author}
                                            </Text>{" "}
                                            &nbsp; | &nbsp;
                                            <Text>{review.designation}</Text>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Stack>
                    );
                })}
            </Carousel>

            <Stack
                styles={{
                    marginTop: 1,
                    height: "95px",
                    zIndex: 999,
                }}
                justify="center"
                align="center"
                gap={2}
            >
                {users
                    .filter(user => user.id !== currentUserReview)
                    .map((user, index) => {
                        return (
                            <Stack
                                onClick={() => {
                                    setSlideIndex(user.id);
                                    setCurrentUserReview(user.id);
                                }}
                                key={user.id}
                                styles={{ cursor: "pointer" }}
                                align="center"
                            >
                                {index < 2 && (
                                    <Image
                                        styles={{
                                            ...styles.UserImage,
                                        }}
                                        fullWidth={false}
                                        src={user.image}
                                    />
                                )}
                                {index === 2 && (
                                    <>
                                        <Image
                                            styles={{
                                                ...styles.ActiveImage,
                                            }}
                                            fullWidth={false}
                                            src={activeUser.image}
                                        />
                                        &nbsp; &nbsp;
                                    </>
                                )}
                                &nbsp;
                                {index >= 2 && (
                                    <Image
                                        styles={{
                                            ...styles.UserImage,
                                        }}
                                        fullWidth={false}
                                        src={user.image}
                                    />
                                )}
                            </Stack>
                        );
                    })}
            </Stack>
        </>
    );
};

export default ReviewCarousel;
