import { keyframes } from "@emotion/react";
import { Container, Stack } from "@dgraph-io/typhoon-ui";
import React from "react";
const spin = keyframes`
to { -webkit-transform: rotate(360deg); }
`;

export default ({styles={}}) => (
    <Stack
        fullWidth={true}
        justify="center"
        align="center"
        styles={{ padding: 4 , ...styles}}
    >
        <Container
            styles={{
                size: 4,
                borderRadius: 3,
                border: "{{lineThickness.2}} solid {{colors.pink.light}}",
                display: "inline-block",
                borderTopColor: "pink",
                animation: `${spin} 400ms ease-in-out infinite`,
            }}
        />
    </Stack>
);
