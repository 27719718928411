import { gql } from "@apollo/client";

export const SEND_VERIFICATION_EMAIL = gql`
    query sendVerificationEmail {
        sendVerificationEmail
    }
`;

export const VERIFY_EMAIL = gql`
    mutation verifyEmail($token: String!) {
        verifyEmail(token: $token)
    }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
    query sendPasswordResetEmail($email: String!) {
        sendPasswordResetEmail(email: $email)
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password)
    }
`;
