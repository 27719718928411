import React, { useCallback } from "react";
import { ConfigTable } from "../ConfigTable/ConfigTable";
import {
    JaegerConfigSelectionPropsType,
    JaegerTier,
} from "./JaegerConfigSelection.types";
import { jaegerTiers, getJaegerTierByID } from "./constants";
import { ConfigOption } from "../ConfigTable/ConfigTable.types";

const convertJaegerTierToConfigOption = (tier: JaegerTier) => ({
    label: `${tier.label}`,
    id: `${tier.id}`,
    values: [
        {
            id: "nCPU",
            label: `${tier.nCPU}`,
        },
        {
            id: "RAM",
            label: `${tier.ram} GB`,
        },
    ],
});

export const JaegerConfigSelection = ({
    selected,
    onSelect,
    disabled = false,
}: JaegerConfigSelectionPropsType) => {
    const onSelectTier = useCallback(
        (selectedConfigOption: ConfigOption) => {
            onSelect(getJaegerTierByID(selectedConfigOption.id)!);
        },
        [onSelect]
    );

    return (
        <ConfigTable
            onSelect={onSelectTier}
            selected={convertJaegerTierToConfigOption(selected)}
            config={{
                headers: [
                    { id: "name", label: "Name" },
                    { id: "vcpu", label: "nCPU" },
                    { id: "ram", label: "RAM" },
                ],
                options: jaegerTiers.map(convertJaegerTierToConfigOption),
            }}
            disabled={disabled}
        />
    );
};
