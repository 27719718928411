import { useMutation, useQuery } from "@apollo/client";
import {
    CREATE_NAMESPACE,
    DELETE_NAMESPACE,
    LIST_NAMESPACES,
} from "gql/namespaces.gql";
import {
    CreateNamespace,
    CreateNamespaceVariables,
} from "gql/types/CreateNamespace";
import {
    DeleteNamespace,
    DeleteNamespaceVariables,
} from "gql/types/DeleteNamespace";
import {
    ListNamespacesVariables,
    ListNamespaces,
} from "gql/types/ListNamespaces";

export const useListNamespaces = (deploymentID: string) => {
    const useQueryResult = useQuery<ListNamespaces, ListNamespacesVariables>(
        LIST_NAMESPACES,
        {
            variables: {
                deploymentID,
            },
        }
    );

    return {
        namespaces: useQueryResult.data?.listNamespaces || [],
        ...useQueryResult,
    };
};

export const useCreateNamespace = () => {
    const [createNamespace, mutationData] = useMutation<
        CreateNamespace,
        CreateNamespaceVariables
    >(CREATE_NAMESPACE);

    return {
        createNamespace: (deploymentID: string, description: string) => {
            createNamespace({
                variables: {
                    input: {
                        deploymentID,
                        description,
                    },
                },
                update(cache, { data }) {
                    const existing = cache.readQuery<
                        ListNamespaces,
                        ListNamespacesVariables
                    >({
                        query: LIST_NAMESPACES,
                        variables: {
                            deploymentID,
                        },
                    });
                    if (data) {
                        cache.writeQuery<
                            ListNamespaces,
                            ListNamespacesVariables
                        >({
                            query: LIST_NAMESPACES,
                            variables: {
                                deploymentID,
                            },
                            data: {
                                listNamespaces: [
                                    ...(existing?.listNamespaces || []),
                                    data.createNamespaceForDeployment
                                ],
                            },
                        });
                    }
                },
            });
        },
        ...mutationData,
    };
};

export const useDeleteNamespace = () => {
    const [deleteNamespace, mutationData] = useMutation<
        DeleteNamespace,
        DeleteNamespaceVariables
    >(DELETE_NAMESPACE);

    return {
        deleteNamespace: (deploymentID: string, namespaceID: number) => {
            return deleteNamespace({
                variables: {
                    deploymentID,
                    namespaceID,
                },

                update(cache, { data }) {
                    const existing = cache.readQuery<
                        ListNamespaces,
                        ListNamespacesVariables
                    >({
                        query: LIST_NAMESPACES,
                        variables: {
                            deploymentID,
                        },
                    });
                    if (data) {
                        cache.writeQuery<
                            ListNamespaces,
                            ListNamespacesVariables
                        >({
                            query: LIST_NAMESPACES,
                            variables: {
                                deploymentID,
                            },
                            data: {
                                listNamespaces: [
                                    ...(existing?.listNamespaces || []).filter(
                                        namespace =>
                                            namespace.namespaceId !==
                                            namespaceID
                                    ),
                                ],
                            },
                        });
                    }
                },
            });
        },
        ...mutationData,
    };
};
