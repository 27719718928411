import { OperationVariables, useLazyQuery, useMutation } from "@apollo/client";
import {
    SEND_VERIFICATION_EMAIL,
    VERIFY_EMAIL,
    SEND_PASSWORD_RESET_EMAIL,
    RESET_PASSWORD,
} from "gql/auth.gql";
import { CustomLazyQueryHook, CustomMutationHook } from "../graphql.hook.type";
import {
    UseSendPasswordResetEmailResult,
    UseSendPasswordResetEmailVariables,
    UseSendVerificationEmailResult,
    UseSendVerificationEmailVariables,
    UseVerifyEmailResult,
    UseVerifyEmailVariables,
    UseResetPasswordVariables,
    UseResetPasswordResult,
} from "./auth.hook.types";

export const useSendVerificationEmail: CustomLazyQueryHook<
    UseSendVerificationEmailVariables,
    UseSendVerificationEmailResult,
    OperationVariables
> = ({ onCompleted }) => {
    const [sendVerificationEmail, useQueryResults] = useLazyQuery<
        UseSendVerificationEmailResult
    >(SEND_VERIFICATION_EMAIL, {
        onCompleted,
    });
    return [sendVerificationEmail, useQueryResults];
};

export const useVerifyEmail: CustomMutationHook<
    UseVerifyEmailVariables,
    UseVerifyEmailResult
> = ({ token = "", onCompleted, onError }) => {
    const [verifyEmail, mutationResult] = useMutation<UseVerifyEmailResult>(
        VERIFY_EMAIL,
        {
            variables: { token },
            onCompleted,
            onError,
        }
    );
    return {
        ...mutationResult,
        verifyEmail,
    };
};

export const useSendPasswordResetEmail: CustomLazyQueryHook<
    UseSendPasswordResetEmailVariables,
    UseSendPasswordResetEmailResult,
    OperationVariables
> = ({ onCompleted, onError }) => {
    const [sendPasswordResetEmail, useQueryResults] = useLazyQuery<
        UseSendVerificationEmailResult
    >(SEND_PASSWORD_RESET_EMAIL, {
        onCompleted,
        onError,
    });
    return [sendPasswordResetEmail, useQueryResults];
};

export const useResetPassword: CustomMutationHook<
    UseResetPasswordVariables,
    UseResetPasswordResult
> = ({ token = "", password = "", onCompleted, onError }) => {
    const [resetPassword, mutationResult] = useMutation<UseResetPasswordResult>(
        RESET_PASSWORD,
        {
            variables: { token, password },
            onCompleted,
            onError,
        }
    );
    return {
        ...mutationResult,
        resetPassword,
    };
};
