export const styles: any = {
    OkStatus: {
        background: "green",
        borderRadius: 3,
        size: "12px",
    },
    ErrorStatus: {
        background: "red",
        borderRadius: 3,
        size: "12px",
    },
};
