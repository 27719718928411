import { gql } from "@apollo/client";

export const REGISTER = gql`
    mutation register($email: String!, $password: String!) {
        register(email: $email, password: $password) {
            uid
            email
        }
    }
`;

export const LOGIN = gql`
    query login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                uid
                email
                accountType
                config {
                    maxDeployments
                    isSuperAdmin
                }
                emailVerified
                auth0ID
                stripePaymentSourceLast4
                authId: auth0ID
                createdAt
                picture
            }
            token
        }
    }
`;

export const REAUTHENTICATE = gql`
    query reauthenticate {
        reauthenticate {
            user {
                uid
                email
                accountType
                config {
                    maxDeployments
                    isSuperAdmin
                }
                emailVerified
                auth0ID
                stripePaymentSourceLast4
                authId: auth0ID
                createdAt
                picture
            }
            token
        }
    }
`;

export const OAUTH_LOGIN = gql`
    mutation oauthLogin($provider: String!, $token: String!) {
        oauthLogin(provider: $provider, token: $token) {
            user {
                uid
                email
                accountType
                config {
                    maxDeployments
                    isSuperAdmin
                }
                emailVerified
                auth0ID
                stripePaymentSourceLast4
                authId: auth0ID
                createdAt
                picture
            }
            token
            isFirstLogin
        }
    }
`;

export const UPDATE_USER_CARD = `
    mutation updateUserCard($sourceID: String!) {
        updateUserCard(sourceID: $sourceID)
    }
`;
