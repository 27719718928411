import React from "react";
import ReactDOM from "react-dom";
import App from "components/App.tsx";
import { AuthProvider } from "libs/auth";
import { configure } from "mobx";
import config from "config";
import "./styles.scss";
import { theme } from "@dgraph-io/typhoon-ui";
import { ToastManager } from "@dgraph-io/typhoon-ui";
import { AppTour } from "components/AppTour.tsx";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthorizedApolloProvider } from "libs/apollo";
import { ThemeProvider } from "@dgraph-io/typhoon-ui";
import { ToastContext } from "libs/toast.ts";

let EnvIntegrations;

if (process.env.NODE_ENV === "production") {
    EnvIntegrations = [
        new Integrations.BrowserTracing({
            tracingOrigins: [
                "api.cloud.dgraph.io",
                "api.stage.thegaas.com",
                /^https:\/\/.*\.cloud\.dgraph\.io\.*\//,
            ],
        }),
        new Sentry.Replay(),
    ];
} else {
    EnvIntegrations = [new Integrations.BrowserTracing(), new Sentry.Replay()];
}

Sentry.init({
    ...config.sentry,
    integrations: EnvIntegrations,
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// Don't allow direct mutation of mobx stores
configure({ enforceActions: "observed" });

ReactDOM.render(
    <AuthProvider>
        <AuthorizedApolloProvider>
            <ThemeProvider value={theme}>
                <AppTour>
                    <ToastManager context={ToastContext}>
                        <App />
                    </ToastManager>
                </AppTour>
            </ThemeProvider>
        </AuthorizedApolloProvider>
    </AuthProvider>,
    document.getElementById("root")
);
