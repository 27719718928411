import { ApolloError } from "@apollo/client";
import history from "libs/browserHistory";
import { useEffect, useState } from "react";
import { useToast, RULES, useForm } from "@dgraph-io/typhoon-ui";
import { ToastContext } from "libs/toast";

type ResetPasswordTouched = {
    password: boolean;
    confirmPassword: boolean;
};

export const useResetPasswordForm = () => {
    const toast = useToast(ToastContext);
    const [touched, setTouched] = useState<ResetPasswordTouched>({
        password: false,
        confirmPassword: false,
    });
    const { form: resetPasswordForm, setValue, validate } = useForm({
        password: {
            default: "",
            rules: [RULES.nonEmptyString, RULES.passwordLength],
        },
        confirmPassword: {
            default: "",
            rules: [RULES.nonEmptyString, RULES.passwordLength],
        },
        token: {
            default: "",
        },
    });

    const errors = validate(resetPasswordForm);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");
        if (token) {
            setValue({
                token,
            });
        }
    }, []);

    const onSuccess = () => {
        toast.addToast({
            variant: "success",
            heading: "Great",
            description: "Your Password has been changed",
        });

        history.push("/login");
    };

    const onError = (err: ApolloError) => {
        toast.addToast({
            variant: "error",
            heading: err.message,
        });
    };

    const checkIfPasswordAreSame = (
        password: string,
        confirmPassword: string
    ) => {
        return password !== confirmPassword;
    };

    return {
        resetPasswordForm,
        setValue,
        errors,
        onSuccess,
        onError,
        checkIfPasswordAreSame,
        touched,
        setTouched,
    };
};
