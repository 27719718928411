import React from "react";
import { Image, Button, Text, Stack } from "@dgraph-io/typhoon-ui";
import GoogleLogin from "react-google-login";
import config from "config";
import GoogleSvg from "../icons/google.svg";

import { GoogleResponse, GoogleLoginButtonProps } from "../Auth.types";

export const GoogleLoginButton = ({
    onSuccess,
    onFailure,
    text,
}: GoogleLoginButtonProps) => {
    return (
        <GoogleLogin
            clientId={config.auth.google.clientId}
            render={renderProps => (
                <Button
                    variant="murmur"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                >
                    <Stack
                        gap={3}
                        align="center"
                        direction="horizontal"
                        justify="center"
                    >
                        <Image
                            styles={{ height: 2 }}
                            src={GoogleSvg}
                            alt="google"
                            fullWidth={false}
                        />
                        <Text color="gray.selected">{text}</Text>
                    </Stack>
                </Button>
            )}
            onSuccess={res => onSuccess(res as GoogleResponse)}
            onFailure={res => onFailure(res)}
            cookiePolicy={"single_host_origin"}
        />
    );
};
