import { uuid } from "@dgraph-io/typhoon-ui";
import {
    ArgumentNode,
    buildSchema,
    DirectiveNode,
    FieldDefinitionNode,
    GraphQLEnumType,
    GraphQLNamedType,
    GraphQLObjectType,
    ObjectValueNode,
    StringValueNode,
    TypeNode,
    ValueNode,
} from "graphql";
import { dgraphSpecSchema } from "../../../../../libs/dgraph";
import {
    ParserOptions,
    SchemaParserAuth,
    SchemaParserComments,
    SchemaParserEnum,
    SchemaParserField,
    SchemaParserFieldDirective,
    SchemaParserFieldHasInverseDirective,
    SchemaParserFieldSearchDirective,
    SchemaParserFieldType,
    SchemaParserInterface,
    SchemaParserList,
    SchemaParserType,
} from "./transformers.types";

const HANDLED_FIELD_DIRECTIVES = ["search", "hasInverse", "lambda"];

const compare = (a: any | GraphQLEnumType, b: GraphQLObjectType | any) =>
    (a.astNode?.loc?.start || 0) - (b.astNode?.loc?.start || 0);

export const parse = (
    schema: string,
    options: ParserOptions = {}
): SchemaParserList => {
    const mergedSchema = dgraphSpecSchema + schema;
    const mergedSchemaObject = buildSchema(mergedSchema, {
        assumeValidSDL: true,
    });

    const config = buildSchema(dgraphSpecSchema, {
        assumeValidSDL: true,
    }).toConfig();

    const dgraphSpecTypes = config.types.reduce(
        (names: Array<string>, type: GraphQLNamedType) =>
            type.astNode ? [...names, type.astNode?.name.value] : names,
        [] as Array<string>
    );

    function removeDgraphTypesFilter(obj: GraphQLNamedType): Boolean {
        return obj.astNode
            ? !dgraphSpecTypes.includes(obj.astNode?.name.value)
            : false;
    }

    const parsedComments = parseComments(schema);

    return [
        ...mergedSchemaObject
            .toConfig()
            .types.filter(userDefinedSchemaFilter)
            .filter(removeDgraphTypesFilter)
            .sort(compare)
            .reduce(
                getParseGraphQLObjectReducer(options),
                [] as SchemaParserList
            ),
        ...parsedComments,
    ];
};

const checkFoDgraphComments = (value: string) =>
    value.trim().startsWith("# Dgraph.");

export const parseComments = (inputSchema: string) => {
    const trimmedSchema = inputSchema.replace(/\s+/g, "").trim();
    const comments = [] as SchemaParserComments[];
    let beforeSpaceIdx = 0;
    let inpIdx = -1;
    let trimIdx = -1;

    while (inputSchema.indexOf("#", inpIdx + 1) !== -1) {
        inpIdx = inputSchema.indexOf("#", inpIdx + 1);
        let commentEnd = inputSchema.indexOf("\n", inpIdx);
        beforeSpaceIdx = inpIdx - 1;

        while (
            beforeSpaceIdx >= 0 &&
            (inputSchema[beforeSpaceIdx] === " " ||
                inputSchema[beforeSpaceIdx] === "\n")
        ) {
            beforeSpaceIdx -= 1;
        }

        commentEnd = commentEnd === -1 ? inputSchema.length : commentEnd;
        trimIdx = trimmedSchema.indexOf("#", trimIdx + 1);
        const value = inputSchema
            .substring(beforeSpaceIdx + 1, commentEnd)
            .trim();
        if (checkFoDgraphComments(value)) {
            comments.push({
                uid: uuid(),
                schemaParserType: "comment",
                index: trimIdx,
                value,
                name: "comment",
            });
        }
    }
    return comments;
};

function userDefinedSchemaFilter(obj: GraphQLNamedType) {
    return obj.astNode;
}

function getParseGraphQLObjectReducer(options: ParserOptions) {
    function parseGraphQLObject(
        agg: SchemaParserList,
        obj: GraphQLNamedType
    ): SchemaParserList {
        switch (obj.astNode?.kind) {
            case "ObjectTypeDefinition":
                return [
                    ...agg,
                    parseGraphQLObjectType(obj as GraphQLObjectType, options),
                ];

            case "InterfaceTypeDefinition":
                if (options.parseInterface) {
                    return [
                        ...agg,
                        parseGraphQLObjectInterface(
                            obj as GraphQLObjectType,
                            options
                        ),
                    ];
                } else {
                    if (options.ignoreInvalidObjects) {
                        return agg;
                    } else {
                        throw Error(
                            "Currently, only types and enums are supported by the builder"
                        );
                    }
                }
            // eslint-disable-next-line no-fallthrough
            case "EnumTypeDefinition":
                return [...agg, parseGraphQLEnumType(obj as GraphQLEnumType)];
            default:
                if (options.ignoreInvalidObjects) {
                    return agg;
                } else {
                    throw Error(
                        "Currently, only types and enums are supported by the builder"
                    );
                }
        }
    }
    return parseGraphQLObject;
}

function parseGraphQLObjectType(
    obj: GraphQLObjectType,
    options: ParserOptions
): SchemaParserType {
    return {
        uid: uuid(),
        schemaParserType: "type",
        name: obj.name,
        subscription: isSubscriptionEnabled(obj),
        remote: isRemoteEnabled(obj),
        fields: obj.astNode?.fields ? parseFields(obj.astNode.fields) : [],
        description: {
            text: obj.astNode?.description?.value,
        },
        ...(!options.ignoreAuth && obj.astNode?.directives && isAuthEnabled(obj)
            ? {
                  auth: parseAuthDirective(obj.astNode?.directives),
              }
            : {}),
    };
}

function parseGraphQLObjectInterface(
    obj: GraphQLObjectType,
    options: ParserOptions
): SchemaParserInterface {
    return {
        uid: uuid(),
        schemaParserType: "interface",
        name: obj.name,
        subscription: isSubscriptionEnabled(obj),
        remote: isRemoteEnabled(obj),
        fields: obj.astNode?.fields ? parseFields(obj.astNode.fields) : [],
        description: {
            text: obj.astNode?.description?.value,
        },
        ...(!options.ignoreAuth && obj.astNode?.directives && isAuthEnabled(obj)
            ? {
                  auth: parseAuthDirective(obj.astNode?.directives),
              }
            : {}),
    };
}

function parseAuthDirective(
    directiveNodes: readonly DirectiveNode[]
): SchemaParserAuth {
    try {
        const authDirective = directiveNodes.filter(
            directiveNode => directiveNode.name.value === "auth"
        )[0];
        if (authDirective.arguments) {
            return authDirective.arguments.reduce((auth, argument) => {
                //? Type casting
                const argumentValueNode = argument.value as ObjectValueNode;
                const ruleField = argumentValueNode.fields[0]
                    .value as StringValueNode;
                return {
                    ...auth,
                    [argument.name.value]: ruleField.value.replace(/"/g, '\\"'),
                };
            }, {} as SchemaParserAuth);
        } else {
            return {};
        }
    } catch (error) {
        throw new Error("Unable to parse auth directive");
    }
}

function parseGraphQLEnumType(obj: GraphQLEnumType): SchemaParserEnum {
    return {
        uid: uuid(),
        schemaParserType: "enum",
        name: obj.name,
        values: obj.getValues().map(value => value.name),
    };
}

function parseFields(fieldsObj: readonly FieldDefinitionNode[]) {
    return fieldsObj.map(parseFieldDefinitionNode);
}

function parseFieldDefinitionNode(
    fieldNode: FieldDefinitionNode
): SchemaParserField {
    return {
        name: fieldNode.name.value,
        directives: fieldNode.directives
            ? fieldNode.directives
                  .filter(
                      directive =>
                          !HANDLED_FIELD_DIRECTIVES.includes(
                              directive.name.value
                          )
                  )
                  .map(parseFieldDirective)
            : [],
        type: parseFieldType(
            fieldNode.type,
            false,
            checkIsNonNullable(fieldNode.type)
        ),
        search: fieldNode.directives
            ? parseSearchDirective(fieldNode.directives)
            : { enabled: false },
        hasInverse: fieldNode.directives
            ? parseHasInverseDirective(fieldNode.directives)
            : { enabled: false },
        lambda: fieldNode.directives
            ? parseLambdaDirective(fieldNode.directives)
            : {
                  enabled: false,
              },
        uid: uuid(),
    };
}

function parseHasInverseDirective(
    directiveNodes: readonly DirectiveNode[]
): SchemaParserFieldHasInverseDirective {
    const hasInverseIndex = Object.values(directiveNodes)
        .map(node => node.name.value)
        .indexOf("hasInverse");

    if (hasInverseIndex > -1) {
        const fieldArgumentIndex = (
            directiveNodes[hasInverseIndex].arguments || []
        )
            .map(arg => arg.name.value)
            .indexOf("field");

        if (fieldArgumentIndex > -1) {
            const fieldArgument =
                directiveNodes[hasInverseIndex].arguments?.[fieldArgumentIndex]
                    .value;

            return {
                enabled: true,
                field: parseValueNode(fieldArgument as ValueNode),
            };
        }
    }

    return {
        enabled: false,
    };
}

function parseSearchDirective(
    directiveNodes: readonly DirectiveNode[]
): SchemaParserFieldSearchDirective {
    const searchIndex = Object.values(directiveNodes)
        .map(node => node.name.value)
        .indexOf("search");

    if (searchIndex > -1) {
        const byArgumentIndex = (directiveNodes[searchIndex].arguments || [])
            .map(arg => arg.name.value)
            .indexOf("by");

        if (byArgumentIndex > -1) {
            const byArguments =
                directiveNodes[searchIndex].arguments?.[byArgumentIndex]
                    .value || ([] as ArgumentNode[]);

            return {
                enabled: true,
                by: parseValueNode(byArguments as ValueNode),
            };
        }

        return {
            enabled: true,
        };
    } else {
        return {
            enabled: false,
        };
    }
}

function parseLambdaDirective(
    directiveNodes: readonly DirectiveNode[]
): SchemaParserFieldSearchDirective {
    const searchIndex = Object.values(directiveNodes)
        .map(node => node.name.value)
        .indexOf("lambda");
    if (searchIndex > -1) {
        return {
            enabled: true,
        };
    } else {
        return {
            enabled: false,
        };
    }
}
function parseFieldDirective(
    directiveNode: DirectiveNode
): SchemaParserFieldDirective {
    return {
        name: directiveNode.name.value,
        arguments: directiveNode.arguments
            ? directiveNode.arguments.map(parseArgumentNode)
            : [],
    };
}

function parseArgumentNode(argumentNode: ArgumentNode) {
    return {
        [argumentNode.name.value]: parseValueNode(argumentNode.value),
    };
}

function parseValueNode(valueNode: ValueNode): any {
    switch (valueNode.kind) {
        case "EnumValue":
        case "FloatValue":
        case "BooleanValue":
        case "IntValue":
        case "StringValue":
            return valueNode.value;
        case "ListValue":
            return valueNode.values.map(parseValueNode);
        case "ObjectValue":
            return valueNode.fields.reduce(
                (prev, current) => ({
                    ...prev,
                    [current.name.value]: parseValueNode(current.value),
                }),
                {}
            );
        case "NullValue":
            return null;
    }
}

function checkIsNonNullable(typeNode: TypeNode): boolean {
    switch (typeNode.kind) {
        case "NonNullType":
            return true;
        case "NamedType":
            return false;
        default:
            return checkIsNonNullable(typeNode.type);
    }
}

function parseFieldType(
    fieldType: TypeNode,
    isArray = false,
    isNonNullable = false
): SchemaParserFieldType {
    switch (fieldType.kind) {
        case "NamedType":
            return {
                kind: fieldType.name.value,
                isArray,
                isNonNullable,
            };
        case "ListType":
            return parseFieldType(fieldType.type, true, isNonNullable);

        default:
            return parseFieldType(fieldType.type, isArray, isNonNullable);
    }
}

function isSubscriptionEnabled(obj: GraphQLObjectType) {
    const directives = obj.astNode?.directives;
    if (directives) {
        return (
            directives
                .map(pluckNameValueFromDirective)
                .indexOf("withSubscription") > -1
        );
    }
    return false;
}

function isRemoteEnabled(obj: GraphQLObjectType) {
    const directives = obj.astNode?.directives;
    if (directives) {
        return (
            directives.map(pluckNameValueFromDirective).indexOf("remote") > -1
        );
    }
    return false;
}

function isAuthEnabled(obj: GraphQLObjectType) {
    const directives = obj.astNode?.directives;
    if (directives) {
        return directives.map(pluckNameValueFromDirective).indexOf("auth") > -1;
    }
    return false;
}

function pluckNameValueFromDirective(directive: DirectiveNode) {
    return directive.name.value;
}
