import Axios from "axios";
import config from "config";

const api = Axios.create({
    baseURL: config.serverUrl,
    headers: {},
});

export default {
    get: api.get,
    post: api.post,
    delete: api.delete,
    put: api.put,
    patch: api.patch,
};

export function setAuthToken(token: string | null) {
    api.defaults.headers.common = {
        Authorization: token ? "Bearer " + token : undefined,
    };
}
