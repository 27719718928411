import React from "react";
import { Input, Stack, Text, Switch } from "@dgraph-io/typhoon-ui";
import Tiers from "components/pages/PricingCalculator/TierTable/TierTable";
import {
    getValidDiskSize,
    getValidSamplingRatio,
} from "components/pages/PricingCalculator/utils";
import { Tier } from "components/pages/PricingCalculator/ProviderData";
import { EnterpriseSettingsConfigurationForm } from "components/pages/PricingCalculator/PricingCalculator.types";
import { FormConfig } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";
import { jaegerTiers } from "../JaegerConfigSelection/constants";
import { JaegerTier } from "../JaegerConfigSelection/JaegerConfigSelection.types";
import { JaegerConfigSelection } from "../JaegerConfigSelection/JaegerConfigSelection";
import { PrometheusConfigSelection } from "../PrometheusConfigSelection/PrometheusConfigSelection";

export const EnterpriseSettings = ({
    settingsForm,
    setSettingsForm,
    disableHAToggle = false,
    disableStorage = false,
    minShardCount = 1,
}: {
    settingsForm: FormConfig<EnterpriseSettingsConfigurationForm>;
    setSettingsForm: (
        values: Partial<{
            dgraphHA: boolean;
            dgraphHosted: boolean;
            selectedTier: Tier;
            storage: number;
            alphaNodes: number | null;
            zeroNodes: number | null;
            jaeger: JaegerTier | null;
            jaegerTrace: number | null;
            acls: boolean;
            auditlogs: boolean;
            shardCount: number;
            prometheus: number | null;
        }>
    ) => void;
    disableHAToggle?: boolean;
    disableStorage?: boolean;
    minShardCount?: number;
}) => {
    const {
        selectedTier,
        storage,
        dgraphHA,
        jaeger,
        prometheus,
        acls,
        jaegerTrace,
        auditlogs,
        shardCount,
    } = settingsForm;
    return (
        <Stack direction="vertical" gap={3}>
            <Stack direction="vertical" gap={1}>
                <Text color="grey" variant={4} weight={500}>
                    Cluster Tier
                </Text>
                <Tiers
                    selected={selectedTier.value}
                    onSelect={tier => {
                        setSettingsForm({
                            selectedTier: tier,
                            ...(!disableStorage
                                ? { storage: tier.storage }
                                : {}),
                        });
                    }}
                    storage={storage.value}
                    setStorage={value =>
                        setSettingsForm({
                            storage: getValidDiskSize(value),
                        })
                    }
                    disableHAToggle={disableHAToggle}
                    disableStorage={disableStorage}
                    ha={dgraphHA.value}
                    setHA={value =>
                        setSettingsForm({
                            dgraphHA: value,
                        })
                    }
                    minShardCount={minShardCount}
                    shardCount={shardCount.value || 1}
                    setShardCount={value =>
                        setSettingsForm({
                            shardCount: value,
                        })
                    }
                />
            </Stack>

            <Stack direction="vertical" gap={2}>
                <Text color="grey" variant={4} weight={500}>
                    Additional Settings
                </Text>
                <Stack direction="vertical" gap={2}>
                    <Stack
                        direction="horizontal"
                        gap={1}
                        justify="space-between"
                        align="flex-start"
                        wrap="nowrap"
                    >
                        <Stack direction="vertical" gap={0}>
                            <Text color="grey" weight={500} variant={2}>
                                Access Control Lists
                            </Text>
                            <Text color="grey" variant={2}>
                                Provides access protection to your data stored
                                in Dgraph.
                            </Text>
                        </Stack>
                        <Switch
                            id="acl"
                            checked={acls.value}
                            onChange={() =>
                                setSettingsForm({
                                    acls: !acls.value,
                                })
                            }
                        />
                    </Stack>

                    <Stack
                        direction="horizontal"
                        gap={1}
                        justify="space-between"
                        align="flex-start"
                        wrap="nowrap"
                    >
                        <Stack direction="vertical" gap={0}>
                            <Text color="grey" weight={500} variant={2}>
                                Audit logs
                            </Text>
                            <Text color="grey" variant={2}>
                                Access Audit logs for your backend
                            </Text>
                        </Stack>
                        <Switch
                            id="acl"
                            checked={auditlogs.value}
                            onChange={() =>
                                setSettingsForm({
                                    auditlogs: !auditlogs.value,
                                })
                            }
                        />
                    </Stack>
                    <Stack direction="vertical" gap={1}>
                        <Stack
                            direction="horizontal"
                            gap={1}
                            justify="space-between"
                            align="flex-start"
                            wrap="nowrap"
                        >
                            <Stack direction="vertical" gap={0}>
                                <Text color="grey" weight={500} variant={2}>
                                    Prometheus
                                </Text>
                                <Text color="grey" variant={2}>
                                    Power your metrics and alerting with the
                                    leading open-source monitoring solution.
                                </Text>
                            </Stack>
                            <Switch
                                id="prometheus"
                                checked={prometheus.value !== null}
                                onChange={() => {
                                    prometheus.value;
                                    setSettingsForm({
                                        prometheus:
                                            prometheus.value === null
                                                ? 1
                                                : null,
                                    });
                                }}
                            />
                        </Stack>
                        {prometheus.value !== null && (
                            <PrometheusConfigSelection
                                selected={prometheus.value}
                                onSelect={value => {
                                    setSettingsForm({
                                        prometheus: value,
                                    });
                                }}
                            />
                        )}
                    </Stack>
                    <Stack direction="vertical" gap={1}>
                        <Stack
                            direction="horizontal"
                            gap={1}
                            justify="space-between"
                            align="flex-start"
                            wrap="nowrap"
                        >
                            <Stack direction="vertical" gap={0}>
                                <Text color="grey" weight={500} variant={2}>
                                    Jaeger
                                </Text>
                                <Text color="grey" variant={2}>
                                    Jaeger collects distributed traces and
                                    provides a UI to view and query traces
                                    across different services.
                                </Text>
                            </Stack>
                            <Switch
                                id="jaeger"
                                checked={jaeger.value !== null}
                                onChange={() => {
                                    if (jaeger.value === null) {
                                        setSettingsForm({
                                            jaeger: jaegerTiers[0],
                                            jaegerTrace: 0.01,
                                        });
                                    } else {
                                        setSettingsForm({
                                            jaeger: null,
                                            jaegerTrace: null,
                                        });
                                    }
                                }}
                            />
                        </Stack>
                        {jaeger.value !== null && (
                            <Stack direction="vertical" gap={1}>
                                <JaegerConfigSelection
                                    selected={jaeger.value}
                                    onSelect={jaeger =>
                                        setSettingsForm({
                                            jaeger,
                                        })
                                    }
                                />

                                {jaegerTrace.value !== null && (
                                    <Stack
                                        direction="vertical"
                                        gap={1}
                                        styles={{ flex: 1 }}
                                    >
                                        <Text
                                            color="grey"
                                            variant={2}
                                            weight={500}
                                        >
                                            Sampling Ratio
                                        </Text>
                                        <Stack gap={1} align="center">
                                            <Input
                                                type="number"
                                                min="0"
                                                max="1"
                                                step="0.01"
                                                value={jaegerTrace.value}
                                                onChange={e =>
                                                    setSettingsForm({
                                                        jaegerTrace: getValidSamplingRatio(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        ),
                                                    })
                                                }
                                            />
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
