export default {
    auth0: {
        domain: "login.dgraph.io",
        clientId: "TFR6NsWHGwvrUfSEw8v5lbgT4JLrdNqi",
        audience: "https://cloud.dgraph.io",
        popupAuth: false,
    },
    auth: {
        github: {
            clientId: "a6be18de9a1602c5607d",
            redirect_uri: "https://cloud.dgraph.io/oauth/github",
        },
        google: {
            clientId:
                "1050440161966-rrod2u5auspdpt7k3o40n4l7hgoq2h8p.apps.googleusercontent.com",
        },
    },
    serverUrl: "https://cerebro.cloud.dgraph.io",
    graphqlServerUrl: "https://cerebro.cloud.dgraph.io/graphql",
    analyticsServerUrl: "https://analytics.dgraph.io",
    monitoringUrl:
        "https://github.com/dgraph-io/slashgraphql-monitoring/blob/master/documentation/debugging.md",
    httpProtocol: "https",
    stripe: {
        publicKey: "pk_live_R0KQFXDA23iHuhlm8eXx7JHe00wjr3o9hb",
    },
    routes: {
        appPrefix: "_",
    },
    features: {},
    sentry: {
        dsn:
            "https://24d9a26f8c7f4a7794b104e99a57bc5b@o318308.ingest.sentry.io/5718954",
        environment: "production",
    },
    grafanaPromUrl:
        "https://g.o.cloud.dgraph.io/d/ADsEUzNMz/customer-dashboard?orgId=1&",
    grafanaLogsUrl: "https://g.o.cloud.dgraph.io/explore?orgId=1&",
    hubspot: {
        form:
            "https://api.hsforms.com/submissions/v3/integration/submit/7078637/03c58707-8355-4eea-9bcb-118c04314498",
    },
};
