import * as Sentry from "@sentry/react";
declare global {
    interface Window {
        analytics: any;
    }
}

export const events = {
    clickGuidedMode: "Tour_Action_Click_Guided_Mode",
    clickLaunchBackend: "Tour_Action_Click_Launch_Backend",
    exitTour: "Tour_Action_Exit_Tour",

    loginSuccess: "App_Login_Success",
    loginFail: "App_Login_Fail",

    introQuery: "App_IntrospectionQuery_Query",
    emptyDashboard: "App_Empty_Dashboard",
    gettingRestored: "App_Getting_Restored",
    couponSuccess: "App_Coupon_Success",
    couponFail: "App_Coupon_Fail",
    creditCardPromptShown: "App_Credit_Card_Prompt_Shown",
    creditCardAddSuccess: "App_Credit_Card_Add_Success",
    creditCardAddFail: "App_Credit_Card_Add_Fail",
    unsubscribe: "Action_Unsubscribe",

    loginFrequency: "Count_Login_Frequency",

    createDeployment: "Action_Create_Deployment",
    deleteDeployment: "Action_Delete_Deployment",
    updateSchema: "Action_Update_Schema",
    userQuery: "Action_User_Query",
    contactUs: "Action_Contact_Us",
    createAPIKey: "Action_Create_API_Key",
    deleteAPIKey: "Action_Delete_API_Key",
    guidedModeStarted: "Action_User_clicked_Guided_Mode",
    schemaTextView: "Action_Schema_Text_View",
    schemaGraphicalView: "Action_Schema_Graphical_View",
    oneClickDeploy: "Action_One_Click_Deploy",
    backupsPage: "Action_Backups_Page",
    createOrganization: "Action_Create_Organization",
    updateLambda: "Action_Update_Lambda",
    modifyBackendUpdate: "Action_Modify_Backend_Page_Update",
    clickLaunchDedicatedInstance: "Action_Launch_Dedicated_Instance",
    dataVisualizerFilterApply: "Action_Data_Visualizer_Filter_Apply",
    backendLaunchTimeout: "Action_Backend_Launch_Timeout",
    backendCreationTime: "Stats_Backend_Launch_Time",
    downloadInvoice: "Action_Download_Invoice",
    dqlQuery: "Action_Run_DQL_Query",
};

type User = {
    email?: string;
    name?: string;
};

export function trackUser(user: User) {
    try {
        Sentry.setUser({ email: user.email });
        window.analytics.identify(user.email, {
            name: user.name,
            email: user.email,
        });
    } catch (error) {
        console.error(error);
    }
}

export default function trackEvent(
    eventName: string,
    eventProps?: Record<string, string | number | boolean>
) {
    try {
        window.analytics.track(eventName, eventProps);
    } catch (error) {
        console.error(error);
    }
}

export const trackPage = () => {
    try {
        window.analytics.page();
    } catch (error) {
        console.error(error);
    }
};

export const ignoreErrorMessages = [
    "No user found with this email.",
    "password is wrong",
];
