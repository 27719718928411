export default {
    auth0: {
        domain: "dev-dgraph-saas.auth0.com",
        clientId: "gbpVq7i1gMvgmtI3muAzoZHJbU2bx5jt",
        audience: "http://localhost:8070",
        popupAuth: true,
    },
    auth: {
        github: {
            clientId: "a7c90138a25508d4483f",
            redirect_uri: "http://localhost:3000/oauth/github",
        },
        google: {
            clientId:
                "1050440161966-rrod2u5auspdpt7k3o40n4l7hgoq2h8p.apps.googleusercontent.com",
        },
    },
    serverUrl: "http://localhost:8070",
    graphqlServerUrl: "http://localhost:8070/graphql",
    analyticsServerUrl: "http://localhost:4005",
    monitoringUrl:
        "https://github.com/dgraph-io/slashgraphql-monitoring/blob/master/documentation/debugging.md",
    httpProtocol: "http",
    stripe: {
        publicKey: "pk_test_0yraRyXUMjgiThSQ4E7UJNK000FBRbmFIh",
        freeSharedPlanPriceId: "",
    },
    routes: {
        appPrefix: "_",
    },
    features: {},
    sentry: {
        dsn:
            "https://bc3003490fd0a6ce49cb0bdf93d2e7a5@o318308.ingest.sentry.io/4505741229752320",
        environment: "development",
    },
};
