import React from "react";
import {
    Stack,
    Button,
    Text,
    Input,
    Link,
    Form,
    uuid,
} from "@dgraph-io/typhoon-ui";
import { styles } from "../Auth.styles";
import { useSignupForm } from "../hooks/useSignupForm";
import { useLoginForm } from "../hooks/useLoginForm";
import { GoogleLoginButton } from "../Social/Google";
import { GithubLoginButton } from "../Social/Github";

import { useLogin, useRegister } from "gql/hooks/users/users.hook";

const Signup = () => {
    const {
        signupForm: { email, password },
        handleSignupError,
        errors,
        onSuccessfulLogin,
        touched,
        setTouched,
    } = useSignupForm();

    const [login] = useLogin({
        email: email.value,
        password: password.value,
        onCompleted: onSuccessfulLogin,
        onError: handleSignupError,
    });

    const { onGoogleSuccess, onGoogleFailure } = useLoginForm();

    const { register, loading: signupLoading } = useRegister({
        email: email.value,
        password: password.value,
        onCompleted: () => login(),
        onError: handleSignupError,
    });

    return (
        <Stack gap={2} direction="vertical" styles={styles.SignupStack}>
            <GoogleLoginButton
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                text="Sign up with Google"
            />

            <GithubLoginButton text="Sign up with GitHub" />
            <Text styles={styles.Center}>Or</Text>

            <Form
                onSubmit={event => {
                    event.preventDefault();
                    register();
                }}
            >
                <Stack gap={2} direction="vertical">
                    <Input
                        {...email}
                        placeholder="yours@example.com"
                        onBlur={() => setTouched({ ...touched, email: true })}
                    />
                    {touched.email &&
                        email.errors.map(error => (
                            <Text color="red" variant={2} key={uuid()}>
                                {error.message}
                            </Text>
                        ))}
                    <Input
                        type="password"
                        {...password}
                        placeholder="your password"
                        onBlur={() =>
                            setTouched({ ...touched, password: true })
                        }
                    />
                    {touched.password &&
                        password.errors.map(error => (
                            <Text color="red" variant={2} key={uuid()}>
                                {error.message}
                            </Text>
                        ))}
                    <Text color="gray" styles={styles.Terms} variant={2}>
                        By signing up, you agree to our <br />{" "}
                        <Link href="https://dgraph.io/cloud-terms" openInNewTab>
                            {" "}
                            terms of service
                        </Link>{" "}
                        and{" "}
                        <Link href="https://dgraph.io/cloud-dpa" openInNewTab>
                            privacy policy
                        </Link>
                    </Text>
                    <Button
                        type="submit"
                        disabled={signupLoading || errors.length > 0}
                    >
                        Signup
                    </Button>
                </Stack>
            </Form>
        </Stack>
    );
};

export default Signup;
