export const styles: any = {
    ChevronStyles: {
        marginLeft: 1,
        width: 1,
        height: 1,
        borderBottom: "2px solid {{colors.grey}} ",
        borderRight: "2px solid {{colors.grey}} ",
        transform: "rotate(135deg) translateX(0px)",
    },
};
