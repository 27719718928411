import Spinner from "components/shared/Spinner/Spinner";
import { Container, Heading, Stack, StylesObject, Text } from "@dgraph-io/typhoon-ui";
import React from "react";
import ReactJson from "react-json-view";
import { DqlQueryResponse } from "../../reducer/dqlQueryExecutor.reducer.types";
import { styles } from "./ResponsePanel.styles";

export const ResponsePanel = ({
    isLoading,
    response,
    styles: stylesProp,
}: {
    isLoading: boolean;
    response: DqlQueryResponse | null;
    styles: StylesObject;
}) => {
    return (
        <Stack
            direction="vertical"
            gap={1}
            styles={{ ...stylesProp, padding: 0 }}
        >
            <Stack
                direction-="horizontal"
                justify="space-between"
                align="center"
            >
                <Heading variant={4}>Response</Heading>
                {isLoading && <Spinner />}

                {response && !isLoading && (
                    <Stack direction="horizontal" gap={2} align="center">
                        <Stack direction="horizontal" gap={1} align="center">
                            <Text>{response.statusCode}</Text>
                            <Container
                                styles={
                                    response.statusCode === 200
                                        ? styles.OkStatus
                                        : styles.ErrorStatus
                                }
                            />
                        </Stack>
                        <Text>
                            {" "}
                            {response.size ? `${response.size} Bytes` : ""}
                        </Text>
                        {response.executionTime && (
                            <Text>
                                {`${(response.executionTime / 1000000).toFixed(
                                    2
                                )}ms`}
                            </Text>
                        )}
                    </Stack>
                )}
            </Stack>

            {!isLoading && response === null && (
                <Text variant={1}>
                    Execute the query to see the response here
                </Text>
            )}

            {response && !isLoading && (
                <Container
                    styles={{ height: "calc(100% - 60px)", overflow: "auto" }}
                >
                    <ReactJson
                        src={response.value}
                        name={null}
                        displayDataTypes={false}
                        quotesOnKeys={false}
                    />
                </Container>
            )}
        </Stack>
    );
};
