import { gql } from "@apollo/client";

export const GET_CLUSTERS = gql`
    query Clusters {
        clusters(unavailableClusters: true) {
            uid
            name
            zone
            country
            platform
            url
            isAvailable
            slashAvailable
        }
    }
`;

export const GET_KUBE_CONFIG = gql`
    query GetKubeConfig(
        $deploymentID: String!
        $podType: String!
        $name: String!
    ) {
        getKubeConfig(
            deploymentID: $deploymentID
            podType: $podType
            name: $name
        ) {
            content
        }
    }
`;
export const CREATE_CLUSTER = gql`
    mutation CreateCluster(
        $name: String!
        $country: String!
        $zone: String!
        $platform: String!
        $url: String!
        $managerUrl: String!
        $authKey: String!
        $organizationUID: String!
    ) {
        createCluster(
            input: {
                name: $name
                country: $country
                zone: $zone
                platform: $platform
                url: $url
                managerUrl: $managerUrl
                authKey: $authKey
                organizationUID: $organizationUID
            }
        ) {
            name
            uid
        }
    }
`;
