import Boss from "../icons/company/boss.svg";
import MissionBase from "../icons/company/missionbase.svg";
import Mooncamp from "../icons/company/mooncamp.svg";
import Onmi from "../icons/company/onmi.svg";
import TimeCampus from "../icons/company/tc.svg";

import Anthony from "../icons/users/anthony.svg";
import Long from "../icons/users/long.svg";
import Mart from "../icons/users/mart.svg";
import Seb from "../icons/users/seb.svg";
import Vignesh from "../icons/users/vignesh.svg";

export const reviews = [
    {
        id: 0,
        description:
            "“The best part of Dgraph's GraphQL is that the entire GraphQL API layer is transitioned into the core of the database itself removing the need to write any resolvers or thinking of best practices for CRUD operations.”",
        author: "Anthony Master",
        logo: Anthony,
        designation: "Founder at MissionBase",
        companyLogo: MissionBase,
    },

    {
        id: 1,
        description:
            "“Our team maintains hundreds of billions of relationships using Dgraph. It effectively solves the reading and writing of related data and it has excellent performance.”",
        author: "Long Liu",
        designation: "Senior Software Engineer at Boss",
        logo: Long,
        companyLogo: Boss,
    },

    {
        id: 2,
        description:
            "“Dgraph makes relations between nodes far more accessible than a traditional relational database could and offered us a new mental model on how to think about how we structure our data.”",
        author: "Sebastian Walter",
        designation: "CEO at Mooncamp",
        companyLogo: Mooncamp,
        logo: Seb,
    },
    {
        id: 3,
        description:
            "“Dgraph is the main database we use to store all the data, leverage the tools around GraphQL to enable end-end typed architectures and above all run it at scale using Kubernetes and the cloud native ecosystem.”",
        author: "Vignesh T.V.",
        designation: "Founder & CEO at Timecampus",
        logo: Vignesh,
        companyLogo: TimeCampus,
    },
    {
        id: 4,
        description:
            "“Dgraph has made graph databases accessible for everyone and has convinced us to migrate our mixed-use databases to Dgraph.”",
        author: "Mart Wetzels",
        designation: "CTO at Onmi",
        logo: Mart,
        companyLogo: Onmi,
    },
];

export const vanityMetrics = [
    {
        title: "90 Billion",
        subTitle: "Queries Executed",
    },
    {
        title: "#1",
        subTitle: "Graph Database",
    },
    {
        title: "10 Million",
        subTitle: "Docker Pulls",
    },
    {
        title: "16,000",
        subTitle: "GitHub Stars",
    },
];

export const users = [
    {
        id: 0,
        image: Anthony,
    },
    {
        id: 1,
        image: Long,
    },
    {
        id: 2,
        image: Seb,
    },
    {
        id: 3,
        image: Vignesh,
    },
    {
        id: 4,
        image: Mart,
    },
];
