import React, { useState } from "react";
import { Button, Card, Text, Stack, Container } from "@dgraph-io/typhoon-ui";

import Layout from "../layout/Layout";
import { PageContentContainerStyles } from "../pages/styles";
import { useSendVerificationEmail } from "gql/hooks/auth/auth.hook";

interface VerifyEmailScreenProps {
    email: string;
}

export default function VerifyEmailScreen({ email }: VerifyEmailScreenProps) {
    const [sent, setSent] = useState(false);
    const [sendVerificationEmail] = useSendVerificationEmail({
        onCompleted: () => setSent(true),
    });

    return (
        <Layout>
            <Card styles={PageContentContainerStyles}>
                {!sent ? (
                    <Stack direction="vertical" align="center" gap={3}>
                        <Container>
                            <Text>
                                {" "}
                                Please verify your email before continuing.
                            </Text>
                        </Container>
                        <Button onClick={sendVerificationEmail}>
                            Resend verification email
                        </Button>
                    </Stack>
                ) : (
                    <Text>
                        The verification email has been resent to {email}.
                    </Text>
                )}
            </Card>
        </Layout>
    );
}
