import React, { useEffect } from "react";
import { Stack, Text, Divider, Heading, Link } from "@dgraph-io/typhoon-ui";

import LoadingSpinner from "components/shared/LoadingSpinner/LoadingSpinner";
import { useDeploymentCost } from "gql/hooks/pricing/pricing.hook";
import { EnterpriseSettingsConfigurationForm } from "./PricingCalculator.types";
import { FormConfig } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";

export const CostCalculator = ({
    form,
    setDeploymentCostPerHour,
}: {
    form: FormConfig<EnterpriseSettingsConfigurationForm>;
    setDeploymentCostPerHour: (cost: number) => void;
}) => {
    const {
        cloudProvider,
        zone,
        dgraphHA,
        selectedTier,
        alphaNodes,
        zeroNodes,
        storage,
        jaeger,
        shardCount,
        prometheus,
    } = form;

    const {
        costPerHour: deploymentCostPerHour,
        dataTransferCost,
        storageCost,
    } = useDeploymentCost({
        platform: cloudProvider.value?.key || "",
        zone: zone.value || "",
        storage: storage.value,
        deploymentSize: selectedTier.value.id,
        highAvailability: dgraphHA.value,
        jaegerEnabled: jaeger.value !== null,
        jaegerSize: jaeger.value !== null ? jaeger.value.nCPU : null,
        shardCount: shardCount.value,
        prometheusEnabled: prometheus.value !== null,
        prometheusSize: prometheus.value,
    });

    const totalCost = deploymentCostPerHour * 24 * (365 / 12);

    useEffect(() => {
        setDeploymentCostPerHour(deploymentCostPerHour);
    }, [setDeploymentCostPerHour, deploymentCostPerHour]);

    const isBYOK = cloudProvider.value?.key === "enterprise";

    return (
        <Stack direction="vertical">
            <Stack
                direction="vertical"
                styles={{ padding: 3, paddingBottom: isBYOK ? 3 : 1 }}
                gap={2}
            >
                <Heading>Summary</Heading>
                <Stack justify="space-between">
                    <Text>Region</Text>
                    <Text weight={600}>{zone.value}</Text>
                </Stack>

                <Divider />

                <Stack justify="space-between">
                    <Text>Tier</Text>

                    <Text weight={600}>{selectedTier.value.label}</Text>
                </Stack>

                <Stack justify="space-between">
                    <Stack direction="vertical">
                        <Text>Storage</Text>
                    </Stack>

                    <Text weight={600}>{storage.value} GB</Text>
                </Stack>

                <Stack justify="space-between">
                    <Text>Dgraph Hosted</Text>

                    <Text weight={600}>Yes</Text>
                </Stack>

                <Stack justify="space-between">
                    <Stack direction="vertical" gap={1}>
                        <Text>High Availability</Text>
                        {dgraphHA.value && alphaNodes.value && zeroNodes.value && (
                            <Stack gap={1}>
                                <small> alpha - {alphaNodes.value}</small>
                                <small> zero - {zeroNodes.value}</small>
                            </Stack>
                        )}
                    </Stack>

                    <Text weight={600}>{dgraphHA.value ? "Yes" : "No"}</Text>
                </Stack>

                <Stack justify="space-between">
                    <Stack direction="vertical" gap={1}>
                        <Text>SLA</Text>
                    </Stack>

                    {dgraphHA.value ? (
                        <Link
                            styles={{ fontWeight: 500 }}
                            openInNewTab={true}
                            href={"https://uptime.is/99.99"}
                        >
                            99.99%
                        </Link>
                    ) : (
                        <Link
                            styles={{ fontWeight: 500 }}
                            openInNewTab={true}
                            href={"https://uptime.is/99.9"}
                        >
                            99.9%
                        </Link>
                    )}
                </Stack>

                {jaeger.value !== null && (
                    <Stack justify="space-between">
                        <Text>Jaeger</Text>
                        <Text weight={600}>{`${jaeger.value.nCPU}C`}</Text>
                    </Stack>
                )}

                {prometheus.value !== null && (
                    <Stack justify="space-between">
                        <Text>Prometheus</Text>
                        <Text weight={600}>{`${prometheus.value}C`}</Text>
                    </Stack>
                )}

                {!isBYOK && <Divider />}
            </Stack>

            {!isBYOK && (
                <>
                    <LoadingSpinner loading={!deploymentCostPerHour}>
                        <Stack direction="vertical" gap={1}>
                            <Stack
                                align="center"
                                justify="space-between"
                                styles={{
                                    background: "pink.translucent",
                                    paddingY: 1,
                                    paddingX: 3,
                                }}
                            >
                                <Text weight={600}>Hourly Cost</Text>

                                {deploymentCostPerHour && (
                                    <Text weight={600} variant={5}>
                                        $
                                        {Number(deploymentCostPerHour).toFixed(
                                            2
                                        )}
                                    </Text>
                                )}
                            </Stack>
                            <Stack
                                direction="vertical"
                                styles={{ padding: 3, paddingTop: 0 }}
                                gap={2}
                            >
                                <Stack align="center" justify="space-between">
                                    <Text weight={200} variant={2}>
                                        Estimated Monthly Cost
                                    </Text>

                                    <Text weight={200} variant={2}>
                                        ${totalCost.toFixed(2)}
                                    </Text>
                                </Stack>

                                <Stack align="center" justify="space-between">
                                    <Text weight={200} variant={2}>
                                        Monthly Storage Cost
                                    </Text>

                                    <Text weight={200} variant={2}>
                                        ${storageCost.toFixed(2)}
                                    </Text>
                                </Stack>

                                <Stack align="center" justify="space-between">
                                    <Text weight={200} variant={2}>
                                        Data Transfer Rate
                                    </Text>

                                    <Text weight={200} variant={2}>
                                        ${dataTransferCost.toFixed(2)}/GB
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                    </LoadingSpinner>

                    <Stack
                        direction="vertical"
                        styles={{ paddingX: 3, paddingBottom: 3 }}
                        gap={2}
                    >
                        <Text color="grey" variant={1}>
                            *The estimation may vary slightly from the official
                            quote/invoice.
                        </Text>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
