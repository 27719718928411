import axios from "axios";
import { uuid } from "@dgraph-io/typhoon-ui";
import { useCallback, useEffect, useReducer } from "react";
import { ActionTypes } from "../reducer/dqlQueryExecutor.actions";
import { dqlQueryExecutorReducer } from "../reducer/dqlQueryExecutor.reducer";
import {
    DqlOperationType,
    DqlQueryState,
} from "../reducer/dqlQueryExecutor.reducer.types";
import { Deployment, Namespace } from "store/deployments/deployments.types";
import { headers as getHeaders } from "deployments/lib";
import config from "config";

export const useDQLQueryExecutor = ({
    namespace,
    deployment,
    onQueryChange,
    onMutationChange,
    onVariablesChange,
    onOperationTypeChange,
}: {
    namespace: Namespace | null;
    deployment: Deployment;
    onQueryChange: (query: string) => void;
    onMutationChange: (mutation: string) => void;
    onOperationTypeChange: (operationType: DqlOperationType) => void;
    onVariablesChange: (variables: Record<string, string>) => void;
}) => {
    const url = config.httpProtocol + "://" + (deployment.url || "");
    const storageKey = "dql-page-v0.1-" + url;
    const initialState: DqlQueryState = {
        uid: uuid(),
        query: "schema {}",
        response: null,
        isLoading: false,
        error: null,
        headers: {},
        variables: {},
        repeatCount: 1,
        at: new Date().toJSON(),
        operationType: "query",
    };
    const initialReducerState = getStateFromLocalStorage(storageKey, {
        dqlQueryState: initialState,
        history: [initialState],
        viewMode: "JSON",
    });

    const [state, dispatch] = useReducer(
        dqlQueryExecutorReducer,
        initialReducerState
    );

    useEffect(() => {
        if (state.dqlQueryState.operationType === "query") {
            onQueryChange(state.dqlQueryState.query);
        } else {
            onMutationChange(state.dqlQueryState.query);
        }
        onVariablesChange(state.dqlQueryState.variables);
        onOperationTypeChange(state.dqlQueryState.operationType);
    }, []);

    const setState = useCallback(
        (newState: DqlQueryState) => {
            if (newState.operationType === "query") {
                onQueryChange(newState.query);
            } else {
                onMutationChange(newState.query);
            }
            onVariablesChange(newState.variables);
            onOperationTypeChange(newState.operationType);
        },
        [
            onQueryChange,
            onMutationChange,
            onVariablesChange,
            onOperationTypeChange,
        ]
    );

    useEffect(() => {
        dispatch({
            type: ActionTypes.RESET,
            payload: {
                reducerState: initialReducerState,
            },
        });

        if (initialReducerState.dqlQueryState.operationType === "query") {
            onQueryChange(initialReducerState.dqlQueryState.query);
        } else {
            onMutationChange(initialReducerState.dqlQueryState.query);
        }
        onVariablesChange(initialReducerState.dqlQueryState.variables);
        onOperationTypeChange(initialReducerState.dqlQueryState.operationType);
    }, [url]);

    const clearHistory = useCallback(() => {
        dispatch({
            type: ActionTypes.CLEAR_HISTORY,
        });
    }, []);

    const executeQuery = useCallback(
        async ({
            query,
            variables,
            operationType,
        }: {
            query: string;
            variables: Record<string, string>;
            operationType: DqlOperationType;
        }) => {
            const isLastQueryIdentical =
                query + JSON.stringify(variables) + operationType ===
                state.dqlQueryState.query +
                    JSON.stringify(state.dqlQueryState.variables) +
                    state.dqlQueryState.operationType;
            const newID = uuid();
            dispatch({
                type: ActionTypes.EXECUTE_QUERY_ACTION,
                payload: {
                    query,
                    variables,
                    uid: isLastQueryIdentical ? state.dqlQueryState.uid : newID,
                    operationType,
                },
            });

            try {
                const res: any =
                    operationType === "query"
                        ? await axios.post(
                              url + "/query",
                              {
                                  query,
                                  variables: Object.entries(variables).reduce(
                                      (agg, [key, value]) => {
                                          agg[`$${key.trim()}`] = value;
                                          return agg;
                                      },
                                      {} as Record<string, string>
                                  ),
                              },
                              {
                                  headers: {
                                      ...getHeaders(deployment, namespace),
                                      "Content-Type": "application/json",
                                  },
                              }
                          )
                        : await axios.post(
                              url + "/mutate?commitNow=true",
                              query,
                              {
                                  headers: {
                                      ...getHeaders(deployment, namespace),
                                      "Content-Type": "application/json",
                                  },
                              }
                          );

                dispatch({
                    type: ActionTypes.EXECUTE_QUERY_COMPLETE_ACTION,
                    payload: {
                        uid: isLastQueryIdentical
                            ? state.dqlQueryState.uid
                            : newID,
                        response: {
                            statusCode: res?.status,
                            value: res?.data || {},
                            size: res?.headers?.["content-length"] || null,
                            executionTime:
                                res?.data?.extensions?.server_latency
                                    ?.total_ns || null,
                        },
                    },
                });
            } catch (error) {
                console.log(error);
                dispatch({
                    type: ActionTypes.EXECUTE_QUERY_FAIL_ACTION,
                    payload: {
                        uid: state.dqlQueryState.uid,
                        response: {
                            statusCode: error?.response?.status,
                            value: {},
                            size: null,
                            executionTime: null,
                        },
                    },
                });
            }
        },
        [state, url, deployment.url, deployment.accessToken, namespace]
    );

    useEffect(() => {
        window.localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state, storageKey]);

    return {
        ...state,
        setState,
        executeQuery,
        clearHistory,
    };
};

const getStateFromLocalStorage = (key: string, defaultValue: any) => {
    try {
        const fromLocalStorage = window.localStorage.getItem(key);
        if (fromLocalStorage === null) {
            return defaultValue;
        } else {
            const value = JSON.parse(fromLocalStorage);
            return value || defaultValue;
        }
    } catch (err) {
        return defaultValue;
    }
};
