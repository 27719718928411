import React from "react";
import { styles } from "./Layout.styles";
import { Text } from "@dgraph-io/typhoon-ui";
import { ReactComponent as Overview } from "./svg-icons/overview.svg";
import { ReactComponent as Schema } from "./svg-icons/schema.svg";
import { ReactComponent as Settings } from "./svg-icons/settings.svg";
import { ReactComponent as Explorer } from "./svg-icons/explorer.svg";
import { ReactComponent as Monitoring } from "./svg-icons/monitoring.svg";
import { ReactComponent as Docs } from "./svg-icons/docs.svg";
import { ReactComponent as Lambdas } from "./svg-icons/lambdas.svg";
import { ReactComponent as Support } from "./svg-icons/support.svg";
import { ReactComponent as DataVisualizer } from "./svg-icons/dataexplorer.svg";
import { ReactComponent as Ratel } from "./svg-icons/ratel.svg";
import { ReactComponent as ACL } from "./svg-icons/acls.svg";
import { ReactComponent as Logs } from "./svg-icons/logs.svg";
import { ReactComponent as DQL } from "./svg-icons/dql.svg";
import { ReactComponent as Namespace } from "./svg-icons/namespace.svg";
import { TourElement } from "components/shared/Tour/Tour";
import { tourStates } from "tour";

const Link = ({ text }: { text: string }) => {
    return (
        <Text
            styles={{
                paddingX: 3,
                ...styles.Text,
            }}
            variant={2}
            weight={500}
        >
            {text}
        </Text>
    );
};

export const getNavlinks = ({
    disabled,
    path,
    isSuperAdmin,
    dispatchTourEvent,
    openRatel,
}: {
    disabled: boolean;
    path: string;
    isSuperAdmin: boolean;
    dispatchTourEvent: (e: string) => void;
    openRatel: () => void;
}) => {
    const overviewLinks = [
        {
            url: `${path}/dashboard`,
            disabled,
            NavLink: (props: any) => (
                <TourElement id="dashboard-link">
                    <Link text="Overview" {...props} />
                </TourElement>
            ),
            NavIcon: ({ ...props }) => <Overview {...props} />,
            onClick: dispatchTourEvent.bind(null, tourStates.CLICK_DASHBOARD),
        },
    ];

    let developLinks = [
        {
            url: `${path}/schema`,
            disabled,
            NavLink: (props: any) => (
                <TourElement id="schema-link">
                    <Link text="Schema" {...props} />
                </TourElement>
            ),
            NavIcon: (props: any) => <Schema {...props} />,
            onClick: dispatchTourEvent.bind(
                null,
                tourStates.CLICK_SCHEMA_EDITOR
            ),
        },
        {
            url: `${path}/explorer`,
            disabled,
            NavLink: (props: any) => (
                <TourElement id="api-explorer-link">
                    <Link text="GraphQL" {...props} />
                </TourElement>
            ),
            NavIcon: (props: any) => <Explorer {...props} />,
            onClick: dispatchTourEvent.bind(
                null,
                tourStates.CLICK_API_EXPLORER
            ),
        },
        {
            url: `${path}/dql`,
            disabled,
            NavLink: (props: any) => <Link text="DQL" {...props} />,
            NavIcon: (props: any) => <DQL {...props} />,
        },
        {
            url: `${path}/data`,
            disabled,
            NavLink: (props: any) => <Link text="Data Studio" {...props} />,
            NavIcon: (props: any) => <DataVisualizer {...props} />,
        },
        {
            url: `${path}/lambdas`,
            disabled,
            NavLink: (props: any) => <Link text="Lambdas" {...props} />,
            NavIcon: (props: any) => <Lambdas {...props} />,
        },
        {
            url: "",
            disabled,
            NavLink: (props: any) => <Link text="Ratel" {...props} />,
            NavIcon: (props: any) => <Ratel {...props} />,
            onClick: openRatel,
        },
        ...(isSuperAdmin
            ? [
                  {
                      url: `${path}/super-admin`,
                      NavLink: (props: any) => (
                          <Link text="Super Admin" {...props} />
                      ),
                      NavIcon: (props: any) => <Overview {...props} />,
                  },
              ]
            : []),
    ];

    const adminLinks = [
        {
            url: `${path}/monitoring`,
            disabled,
            NavLink: (props: any) => <Link text="Monitoring" {...props} />,
            NavIcon: (props: any) => <Monitoring {...props} />,
        },
        {
            url: `${path}/acl`,
            disabled,
            NavLink: (props: any) => <Link text="ACLs" {...props} />,
            NavIcon: (props: any) => <ACL {...props} />,
        },
        {
            url: `${path}/namespaces`,
            disabled,
            NavLink: (props: any) => <Link text="Namespaces" {...props} />,
            NavIcon: (props: any) => <Namespace {...props} />,
        },
        {
            url: `${path}/audit-logs`,
            disabled,
            NavLink: (props: any) => <Link text="Audit Logs" {...props} />,
            NavIcon: (props: any) => <Logs {...props} />,
        },
        {
            url: `${path}/settings`,
            disabled,
            NavLink: (props: any) => <Link text="Settings" {...props} />,
            NavIcon: (props: any) => <Settings {...props} />,
        },
    ];

    const resourceLinks = [
        {
            url: "",
            href: "https://dgraph.io/docs/cloud/",
            NavLink: (props: any) => <Link text="Documentation" {...props} />,
            NavIcon: (props: any) => <Docs {...props} />,
        },
        {
            url: `${path}/support`,
            NavLink: (props: any) => <Link text="Support" {...props} />,
            NavIcon: (props: any) => <Support width="24px" />,
        },
    ];

    return {
        overviewLinks,
        developLinks,
        adminLinks,
        resourceLinks,
    };
};
