import React from "react";
import { observer } from "mobx-react-lite";
import deploymentStore, {
    DeploymentStore,
} from "store/deployments/deployments";
import { Select } from "@dgraph-io/typhoon-ui";
import { useListNamespaces } from "gql/hooks/namespaces/namespaces.hook";
import { Namespace } from "store/deployments/deployments.types";

export const NamespaceSelectView = observer(
    ({ deploymentStore }: { deploymentStore: DeploymentStore }) => {
        const { namespaces } = useListNamespaces(
            deploymentStore.currentDeployment!.uid
        );

        return namespaces.length > 1 ? (
            <Select
                styles={{ width: "200px" }}
                onSelect={({ value }: any) => {
                    deploymentStore.setNamespace(value.value);
                }}
                selected={getSelectOptionForNamesapce(
                    deploymentStore.selectedNamespace || namespaces[0]
                )}
                values={namespaces.map(namespace =>
                    getSelectOptionForNamesapce(namespace)
                )}
            />
        ) : (
            <></>
        );
    }
);

export const NamespaceSelect = () => (
    <NamespaceSelectView deploymentStore={deploymentStore} />
);

const getSelectOptionForNamesapce = (namespace: Namespace) => ({
    value: namespace,
    id: namespace.uid,
    label: `${namespace.description}`,
});
