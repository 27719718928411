/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import { styles } from "./TourWidget.styles";
import {
    Button,
    Card,
    Container,
    Divider,
    Heading,
    Modal,
    Progressbar,
    Stack,
    Text,
} from "@dgraph-io/typhoon-ui";

import { ReactComponent as CheckedIcon } from "./svgs/check.svg";
import { ReactComponent as UncheckedIcon } from "./svgs/uncheck.svg";

import { TourWidgetPropsType, StepPropsType } from "./TourWidget.types";

const Step = ({ title, completed }: StepPropsType) => {
    return (
        <Stack direction="horizontal" align="center" gap={1}>
            {completed ? (
                <CheckedIcon height="20px" width="20px" />
            ) : (
                <UncheckedIcon height="20px" width="20px" />
            )}
            <Text variant={2} weight={500}>
                {title}
            </Text>
        </Stack>
    );
};

const TourWidget = ({
    steps,
    checkpoints,
    currentStep,
    styles: stylesProp = {},
    onExit,
}: TourWidgetPropsType): JSX.Element => {
    const tourSteps = checkpoints.map(({ title, id }) => ({
        title,
        completed:
            steps.map(step => step.id).indexOf(currentStep.id) >=
            steps.map(step => step.id).indexOf(id),
    }));

    const completedSteps = tourSteps.filter(step => step.completed);

    const [isOpen, setIsOpen] = useState(true);
    const [isExitModalOpen, setIsExitModalOpen] = useState(false);
    const progress = Math.round(
        (completedSteps.length / tourSteps.length) * 100
    );
    return (
        <Card styles={{ ...styles.TourWidgetContainer, ...stylesProp }}>
            <Stack direction="vertical" gap={1}>
                <Stack
                    direction="horizontal"
                    align="center"
                    justify="space-between"
                    onClick={setIsOpen.bind(null, !isOpen)}
                    styles={styles.Collapsible}
                >
                    <Heading variant={4}>App Tour</Heading>
                    <Container
                        styles={{
                            ...styles.ChevronStyles,
                            transform: !isOpen
                                ? "rotate(-135deg) translateX(-3px)"
                                : "rotate(45deg) translateY(-3px)",
                        }}
                    />
                </Stack>
                <Stack
                    direction="horizontal"
                    align="center"
                    justify="space-between"
                >
                    <Text variant={2}>{`${progress}%`} </Text>
                    <Progressbar progress={progress} width="80%" />
                </Stack>

                {isOpen && [
                    <Divider key={"divider"} />,
                    <Stack key={"tasks"} direction="vertical" gap={2}>
                        {tourSteps.map(step => (
                            <Step {...step} key={step.title} />
                        ))}
                    </Stack>,
                    <Button
                        key={"exit-bttn"}
                        variant="ghost"
                        styles={styles.ExitButtonStyles}
                        onClick={setIsExitModalOpen.bind(null, true)}
                    >
                        Exit Tour
                    </Button>,
                ]}
            </Stack>
            <Modal
                heading="You are almost there!"
                isOpen={isExitModalOpen}
                onClose={() => {
                    setIsExitModalOpen(false);
                }}
            >
                <Text>
                    95% of users find the tour helpful to them in building apps
                    quickly.
                </Text>
                <Text>Are you sure to exit?</Text>
                <Stack direction="horizontal" gap={1} justify="flex-end">
                    <Button
                        variant="ghost"
                        onClick={() => {
                            setIsExitModalOpen(false);
                            if (onExit) {
                                onExit();
                            }
                        }}
                    >
                        Yes, I can figure it out myself
                    </Button>
                    <Button
                        onClick={() => {
                            setIsExitModalOpen(false);
                        }}
                    >
                        Resume
                    </Button>
                </Stack>
            </Modal>
        </Card>
    );
};

export default TourWidget;
