import React from "react";
import { Stack, Text } from "@dgraph-io/typhoon-ui";
import { CloudProvider } from "components/pages/PricingCalculator/PricingCalculator.types";
import { ZoneSelect } from "../ZoneSelect/ZoneSelect";

export const EnterpriseClusterSelect = ({
    cloudProviders,
    selectedCloudProvider,
    selectedZone,
    onClusterChange,
    disabled = false,
}: {
    cloudProviders: Array<CloudProvider>;
    selectedZone: string | null;
    selectedCloudProvider: CloudProvider | null;
    onClusterChange: ({
        selectedZone,
        selectedCloudProvider,
    }: {
        selectedZone: string | null;
        selectedCloudProvider: CloudProvider | null;
    }) => void;
    disabled?: boolean;
}) => {
    return (
        <Stack direction="vertical" gap={2}>
            <Text color="grey" variant={4} weight={500}>
                Cloud Region
            </Text>

            <Stack fullWidth={true} direction="horizontal" gap={2}>
                <Stack
                    gap={1}
                    direction="vertical"
                    wrap="nowrap"
                    styles={{ flex: 1 }}
                >
                    {selectedZone && (
                        <>
                            <Text color="grey" variant={2}>
                                Select a region
                            </Text>
                            <ZoneSelect
                                selected={selectedZone}
                                zones={
                                    selectedCloudProvider
                                        ? selectedCloudProvider.zoneProvider
                                        : []
                                }
                                onChange={updatedZone =>
                                    onClusterChange({
                                        selectedCloudProvider,
                                        selectedZone: updatedZone.zoneID,
                                    })
                                }
                                disabled={disabled}
                            />
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
