import React from "react";
import {
    Stack,
    Heading,
    Input,
    Button,
    Card,
    Navbar,
    NavbarLogo,
    Container,
    Text,
    uuid,
} from "@dgraph-io/typhoon-ui";
import { useResetPassword } from "gql/hooks/auth/auth.hook";
import { PageContentContainerStyles } from "components/pages/styles";
import { useResetPasswordForm } from "../hooks/useResetPassword";
import { styles } from "./ResetPassword.styles";

export default function ResetPassword() {
    const {
        resetPasswordForm: { password, confirmPassword, token },
        errors,
        onSuccess,
        onError,
        checkIfPasswordAreSame,
        touched,
        setTouched,
    } = useResetPasswordForm();

    const { resetPassword, loading } = useResetPassword({
        onCompleted: onSuccess,
        onError,
    });

    return (
        <>
            <Navbar styles={{ top: 0, zIndex: "navbar", position: "relative" }}>
                <Container styles={{ position: "absolute" }}>
                    <NavbarLogo src="/logo.svg" alt="dgraph-cloud" />
                </Container>
            </Navbar>
            <Card
                styles={{
                    ...PageContentContainerStyles,
                    ...styles.ResetPassword,
                }}
            >
                <Stack justify="center" direction="vertical" gap={3}>
                    <Heading styles={{ textAlign: "center" }} variant={2}>
                        Reset your Password
                    </Heading>
                    <Stack direction="vertical" gap={2}>
                        <Heading variant={4}>New Password</Heading>
                        <Input
                            type="password"
                            {...password}
                            onBlur={() =>
                                setTouched({ ...touched, password: true })
                            }
                        />
                        {touched.password &&
                            password.errors.map(error => (
                                <Text color="red" variant={2} key={uuid()}>
                                    {error.message}
                                </Text>
                            ))}
                    </Stack>

                    <Stack direction="vertical" gap={2}>
                        <Heading variant={4}>Confirm New Password</Heading>
                        <Input
                            type="password"
                            {...confirmPassword}
                            onBlur={() =>
                                setTouched({
                                    ...touched,
                                    confirmPassword: true,
                                })
                            }
                        />
                        {touched.confirmPassword &&
                            confirmPassword.errors.map(error => (
                                <Text color="red" variant={2} key={uuid()}>
                                    {error.message}
                                </Text>
                            ))}
                    </Stack>

                    <Button
                        disabled={
                            loading ||
                            errors.length > 0 ||
                            checkIfPasswordAreSame(
                                password.value,
                                confirmPassword.value
                            )
                        }
                        onClick={() =>
                            resetPassword({
                                variables: {
                                    password: password.value,
                                    token: token.value,
                                },
                            })
                        }
                    >
                        Reset Password
                    </Button>
                </Stack>
            </Card>
        </>
    );
}
