import React from "react";
import { Card, Heading, Stack, StylesObject } from "@dgraph-io/typhoon-ui";
import { AuthHeader } from "components/shared/AuthHeader/AuthHeader";
import { Header } from "components/shared/AuthHeader/AuthHeader.types";

export const QueryOptionsPanel = ({
    variables,
    setVariables,
    styles: stylesProp,
}: {
    variables: Array<Header>;
    setVariables: (headers: Array<Header>) => void;
    styles?: StylesObject;
}) => {
    return (
        <Card
            styles={{
                paddingY: 2,
                paddingX: 2,
                height: "240px",
                overflow: "auto",
                ...stylesProp,
            }}
        >
            <Stack direction="vertical" gap={1}>
                <Heading variant={4}>Variables</Heading>
                <AuthHeader
                    keyPrefix="$"
                    headers={variables}
                    onAddHeader={newVariable =>
                        setVariables([...variables, newVariable])
                    }
                    onRemoveHeader={id =>
                        setVariables(
                            variables.filter(variable => variable.id !== id)
                        )
                    }
                    onKeyChange={({ key, id }) => {
                        setVariables(
                            variables.map(variable =>
                                variable.id === id
                                    ? { ...variable, key }
                                    : variable
                            )
                        );
                    }}
                    onValueChange={({ value, id }) => {
                        setVariables(
                            variables.map(variable =>
                                variable.id === id
                                    ? { ...variable, value }
                                    : variable
                            )
                        );
                    }}
                />
            </Stack>
        </Card>
    );
};
