import React, { useEffect, useState } from "react";
import { Tour } from "components/shared/Tour/Tour";
import { produtTourSteps } from "tour";
import { useAuth } from "libs/auth";

const TOUR_VERSION = "0.4";
const TOUR_STATE_KEY_PREFIX = `slashgraphql:tour:${TOUR_VERSION}`;

const getKey = (uid: string) => `${TOUR_STATE_KEY_PREFIX}:${uid}`;

export const AppTour = ({ children }: { children: React.ReactNode }) => {
    const { loading, user } = useAuth();
    const [tourId, setTourId] = useState("");
    useEffect(() => {
        if (!loading && user) {
            setTourId(getKey(user.authId));
        }
    }, [loading, user]);

    return (
        <Tour tourId={tourId} steps={produtTourSteps}>
            {children}
        </Tour>
    );
};
