import { JaegerTier } from "./JaegerConfigSelection.types";

//! nCPU is jaegerSize
export const jaegerTiers: Array<JaegerTier> = [
    {
        label: "0.5C",
        nCPU: 0.5,
        ram: 2,
        id: "0.5C",
    },
    {
        label: "1C",
        nCPU: 1,
        ram: 4,
        id: "1C",
    },
    {
        label: "2C",
        nCPU: 2,
        ram: 8,
        id: "2C",
    },
    {
        label: "4C",
        nCPU: 4,
        ram: 16,
        id: "4C",
    },
];

export const getJaegerTierByID = (id: string | undefined | null) => {
    return jaegerTiers.find(tier => tier.id === id) || null;
};
