import { EnterpriseSettingsConfigurationForm } from "components/pages/PricingCalculator/PricingCalculator.types";
import { useForm, RULES } from "@dgraph-io/typhoon-ui";
import { FormConfig } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";
import { useToast } from "@dgraph-io/typhoon-ui";
import { useAuth } from "libs/auth";
import { useState } from "react";
import { submitHubspotForm } from "./utils";
import { ToastContext } from "libs/toast";

export const useContactFormForDgraphCloud = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToast(ToastContext);
    const { user } = useAuth();
    const {
        form: contactForm,
        setValue: setContactFormValues,
        validate,
    } = useForm({
        email: {
            default: user?.email || "",
            rules: [RULES.email],
        },
        name: {
            default: "",
            rules: [RULES.nonEmptyString],
        },
        companyName: {
            default: "",
            rules: [RULES.nonEmptyString],
        },
        jobTitle: {
            default: "",
            rules: [RULES.nonEmptyString],
        },
        phone: {
            default: "",
        },
    });

    const errors = validate(contactForm);

    const [deploymentCostPerHour, setDeploymentCostPerHour] = useState(0);

    const submitForm = async (
        settingsForm: FormConfig<EnterpriseSettingsConfigurationForm>,
        customPlanRequired = false
    ) => {
        try {
            setIsModalOpen(false);
            const res = await submitHubspotForm({
                settingsForm,
                deploymentCostPerHour,
                contactForm,
                customPlanRequired,
            });

            if (res.status === 200) {
                setIsModalOpen(false);
                toast.addToast({
                    heading: "Request sent",
                    description: "We’ll be in touch within 24 hours.",
                    variant: "success",
                    permanent: true,
                });
            }
        } catch (err) {
            toast.addToast({
                heading: "Uh-oh, Something went wrong",
                description: "Please Try again",
                variant: "error",
            });
            console.error(err);
        }
    };

    return {
        isModalOpen,
        setIsModalOpen,
        submitForm,
        setDeploymentCostPerHour,
        contactForm,
        setContactFormValues,
        contactFormErrors: errors,
    };
};
