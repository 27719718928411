import React from "react";
import { Heading, Stack, Text, Button, Input } from "@dgraph-io/typhoon-ui";
import { FormConfig } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";
import { uuid } from "@dgraph-io/typhoon-ui";
import { ContactFormConfiguration } from "./ContactForm.types";
import { FormErrorType } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";

export const ContactForm = ({
    form,
    formErrors,
    onSubmit,
}: {
    form: FormConfig<ContactFormConfiguration>;
    formErrors: FormErrorType[];
    onSubmit: () => void;
}) => {
    const { name, companyName, email, jobTitle, phone } = form;
    return (
        <>
            <Heading>Contact Details</Heading>
            <Stack direction="vertical" fullWidth gap={2}>
                <Stack direction="vertical" gap={2}>
                    <Heading variant={5}>Name *</Heading>
                    <Input {...name} fullWidth placeholder="Your Name" />
                    {name.errors.map((error: FormErrorType) => (
                        <Text color="red" variant={2} key={uuid()}>
                            {error.message}
                        </Text>
                    ))}
                </Stack>

                <Stack direction="vertical" gap={2}>
                    <Heading variant={5}>Email *</Heading>

                    <Input {...email} fullWidth placeholder="Email" />
                    {email.errors.map((error: FormErrorType) => (
                        <Text color="red" variant={2} key={uuid()}>
                            {error.message}
                        </Text>
                    ))}
                </Stack>

                <Stack direction="vertical" gap={2}>
                    <Heading variant={5}>Company Name *</Heading>

                    <Input
                        {...companyName}
                        fullWidth
                        placeholder="Company Name"
                    />
                    {companyName.errors.map((error: FormErrorType) => (
                        <Text color="red" variant={2} key={uuid()}>
                            {error.message}
                        </Text>
                    ))}
                </Stack>

                <Stack direction="vertical" gap={2}>
                    <Heading variant={5}>Job Title *</Heading>
                    <Input {...jobTitle} fullWidth placeholder="Job Title" />
                    {jobTitle.errors.map((error: FormErrorType) => (
                        <Text color="red" variant={2} key={uuid()}>
                            {error.message}
                        </Text>
                    ))}
                </Stack>

                <Stack direction="vertical" gap={2}>
                    <Heading variant={5}>Phone</Heading>
                    <Input
                        type="phone"
                        {...phone}
                        fullWidth
                        placeholder="Phone"
                    />
                </Stack>

                <Button onClick={onSubmit} disabled={formErrors.length > 0}>
                    Submit
                </Button>
            </Stack>
        </>
    );
};
