import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import deploymentStore from "store/deployments/deployments";
import LoadingSpinner from "components/shared/LoadingSpinner/LoadingSpinner";
import { useAuth } from "libs/auth";
// @ts-ignore
import AppSidebar from "./AppSidebar";
// @ts-ignore
import MobileSidebar from "./MobileSidebar";
import { useAppRoute } from "config";
import history from "libs/browserHistory";
import { styles } from "./Layout.styles";
import {
    Navbar,
    NavbarLogo,
    Stack,
    Select,
    Button,
    Container,
} from "@dgraph-io/typhoon-ui";
import { useToast } from "@dgraph-io/typhoon-ui";
import { DeploymentStore } from "store/deployments/deployments";
import { AccountDropdown } from "./AccountDropdown/AccountDropdown";
import { TourElement } from "components/shared/Tour/Tour";
import { ToastContext } from "libs/toast";
import { getLabelForBackend } from "libs/deployment";
import { NamespaceSelect } from "components/shared/NamespaceSelect/NamespaceSelect";

const Layout = observer(({ children, deploymentStore }: LayoutPropsType) => {
    const { user, logout, isAuthenticated, loading: authLoading } = useAuth();

    const { path } = useAppRoute();
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const currentDeployment = deploymentStore.currentDeployment;
    const toast = useToast(ToastContext);

    // If there is deployments, use the first one
    if (
        !currentDeployment &&
        deploymentStore.deployments &&
        deploymentStore.deployments.length > 0
    ) {
        deploymentStore.setCurrent(deploymentStore.deployments[0]);
    }

    const loadDeployments = async () => {
        return await deploymentStore.load(false, toast);
    };

    async function fetchAll() {
        await loadDeployments();
        setLoading(false);
    }

    useEffect(() => {
        if (!authLoading && isAuthenticated) {
            fetchAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, authLoading]);

    const areDeploymentsPresent =
        deploymentStore.deployments && deploymentStore.deployments.length > 0;

    return (
        <main>
            <Navbar styles={{ top: 0, zIndex: "navbar" }}>
                <Container styles={styles.MenuIcon}>
                    <i
                        className="fas fa-bars fa-2x"
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                    />
                </Container>
                <NavbarLogo alt="Dgraph Cloud" src="/logo.svg" height="32" width="106.2"/>
                {areDeploymentsPresent && (
                    <Stack direction="horizontal" gap={2}>
                        <Select
                            fullWidth
                            styles={styles.SelectBackendStyles}
                            label={"Select your backend"}
                            selected={
                                currentDeployment
                                    ? {
                                          label: getLabelForBackend(
                                              currentDeployment
                                          ),
                                          id: currentDeployment.uid,
                                      }
                                    : null
                            }
                            onSelect={({ value }: any) => {
                                deploymentStore.setCurrent(value.deployment);
                                const { deployment } = value;
                                const isOwner =
                                    user?.auth0ID === deployment?.owner;

                                if (!isOwner) {
                                    history.push(path + "/dashboard");
                                }
                                const query = new URLSearchParams(
                                    location.search
                                );
                                const tab = query.get("tab");
                                if (
                                    tab === "exports" &&
                                    deployment.deploymentType === "dedicated"
                                ) {
                                    history.push(path + "/settings");
                                }
                            }}
                            values={deploymentStore.deployments.map(
                                deployment => ({
                                    ...deployment,
                                    label: getLabelForBackend(deployment),
                                    key: `${deployment.name}`,
                                    id: `${deployment.uid}`,
                                    deployment,
                                })
                            )}
                        />
                        <NamespaceSelect />
                    </Stack>
                )}

                <TourElement id="launch-new-backend-bttn">
                    <Button
                        styles={styles.CreateBackend}
                        variant="yell"
                        onClick={() => {
                            history.push(path + "/create-deployment");
                        }}
                    >
                        Launch new backend
                    </Button>
                </TourElement>
                <Stack styles={styles.UserProfile}>
                    {!authLoading && isAuthenticated && (
                        <AccountDropdown
                            user={{
                                picture: user?.picture || undefined,
                                email: user!.email,
                            }}
                            path={path}
                            logout={logout}
                        />
                    )}
                </Stack>
            </Navbar>
            <Container fullWidth={true} styles={styles.AppConsoleContainer}>
                <AppSidebar
                    disabled={
                        !currentDeployment || currentDeployment.isDeploying
                    }
                />

                {isSidebarOpen && (
                    <MobileSidebar
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                        disabled={
                            currentDeployment === null ||
                            currentDeployment.isDeploying === true
                        }
                    />
                )}

                <Container fullWidth={true} styles={styles.PageContainer}>
                    <LoadingSpinner loading={loading}>
                        {children}
                    </LoadingSpinner>
                </Container>
            </Container>
        </main>
    );
});

export default (props: any) => (
    <Layout {...props} deploymentStore={deploymentStore} />
);

type LayoutPropsType = {
    deploymentStore: DeploymentStore;
    children?: React.ReactNode;
};
