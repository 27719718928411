import { gql } from "@apollo/client";

export const GET_DEPLOYMENTS_QUERY_STRING = `
    query GetDeployments {
        deployments {
            uid
            url
            name
            zone
            subdomain
            charts{
                compact
                full
            }
            jwtToken
            organization {
                uid
                name
                createdBy {
                    accountType
                }
            }
            owner
            deletedAt
            frontendUrl
            frontendRepo
            deploymentMode
            accessToken
            deploymentType
            aclEnabled
            dgraphHA
            jaegerEnabled
            jaegerSize
            jaegerTrace
            size
            alphaStorage
            anonAccess
            isAuditEnabled
            dgraphVersion
            provider
            shardCount
            isMultiTenancyEnabled
            prometheusEnabled
            prometheusSize
        }
    }
`;

export const GET_LAMBDA = gql`
    query GetLambda($input: GetLambdaInput!) {
        GetLambda(input: $input) {
            lambdaScript
            tenantID
        }
    }
`;

export const UPDATE_LAMBDA = gql`
    mutation UpdateLambda($input: UpdateLambdaInput!) {
        updateLambda(input: $input)
    }
`;

export const GET_LAMBDA_LOGS_QUERY = gql`
    query GetLambdaLogs($input: LambdaLogsInput!) {
        getLambdaLogs(input: $input) {
            logs
        }
    }
`;

export const CREATE_DEPLOYMENT = `
    mutation CreateDeployment($input: NewDeployment!) {
        createDeployment(input: $input) {
            uid
            url
            name
            zone
            subdomain
            deploymentType
            charts{
                compact
                full
            }
            jwtToken
            organization {
                uid
                name
                createdBy {
                    accountType
                }
            }
            owner
            deletedAt
            frontendUrl
            frontendRepo
            deploymentMode
            anonAccess
            aclEnabled
            accessToken 
            dgraphHA
            jaegerEnabled
            jaegerSize
            jaegerTrace
            isAuditEnabled
            size
            alphaStorage
            dgraphVersion
            provider
            shardCount
            isMultiTenancyEnabled
            prometheusEnabled
            prometheusSize
        }
    }
`;

export const DELETE_DEPLOYMENT = `
    mutation DeleteDeployment($deploymentID: String!) {
        deleteDeployment(deploymentID: $deploymentID)
    }
`;

export const PATCH_DEPLOYMENT = `
mutation UpdateDeployment($input: UpdateDeploymentInput!) {
    updateDeployment(input: $input)
}
`;

export const CHANGE_DEPLOYMENT_PROTECTION = `
mutation UpdateDeploymentProtection($input: UpdateDeploymentProtectionInput!) {
    updateDeploymentProtection(input: $input) {
        uid
        isProtected
    }
}
`;

export const GET_PATCH_ATTRIBUTES = `
query GetAllowedDeploymentUpdateAttributes($uid: ID!) {
    getAllowedDeploymentUpdateAttributes(uid: $uid) {
        label
        name
        changeAllowedBy
        type
        needsRestart
        description
        hidden
        link
        options {
            label
            value
        }
        valueType
    }
}
`;

export const SEARCH_DEPLOYMENTS = `
query SearchDeployments($inputType: SearchType!, $searchText: String!) {
    searchDeployments(inputType: $inputType, searchText: $searchText ) {
            uid
            url
            name
            zone
            subdomain
            charts{
                compact
                full
            }
            jwtToken
            organization {
                uid
                name
            }
            owner
            ownerDetails {
                accountType
                email
            }
            deletedAt
            frontendUrl
            frontendRepo
            deploymentMode
            deploymentType
            aclEnabled
            dgraphHA
            jaegerEnabled
            jaegerSize
            jaegerTrace
            size
            alphaStorage
            anonAccess
            accessToken
            backupBucketFormat
            doNotFreeze
            backupInterval
            isAuditEnabled
            isProtected
            provider
            shardCount
            isMultiTenancyEnabled
            prometheusEnabled
            prometheusSize
    }
}
`;
