import { ApolloError } from "@apollo/client";
import { useToast, RULES, useForm } from "@dgraph-io/typhoon-ui";
import { ToastContext } from "libs/toast";

export const useForgotPasswordForm = () => {
    const toast = useToast(ToastContext);
    const { form: forgotPasswordForm, setValue, validate } = useForm({
        email: {
            default: "",
            rules: [RULES.email, RULES.nonEmptyString],
        },
    });

    const errors = validate(forgotPasswordForm);

    const onSuccess = () => {
        toast.addToast({
            variant: "success",
            heading: "Great",
            description: "Please check your email to reset your password",
        });
    };

    const onFailure = (error: ApolloError) => {
        toast.addToast({
            variant: "error",
            heading: error.message,
        });
    };

    return {
        forgotPasswordForm,
        setValue,
        errors,
        onSuccess,
        onFailure,
    };
};
