import React, { useState } from "react";
import {
    Stack,
    Text,
    Heading,
    Divider,
    Image,
    ControlledTabs,
    Tab,
} from "@dgraph-io/typhoon-ui";
import WavesPng from "../icons/waves.png";
import { styles } from "../Auth.styles";
import { vanityMetrics } from "./userReviews";

import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import ReviewCarousel from "./ReviewCarousel";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import BackButton from "components/shared/BackButton/BackButton";

const AuthHomePage = () => {
    const [defaultTab] = useState("login");
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    return (
        <Stack fullWidth={true} styles={{ height: "100vh" }}>
            <Stack
                align="center"
                justify="center"
                direction="vertical"
                styles={styles.LeftPage}
                gap={4}
            >
                <Image src={WavesPng} fullWidth={false} styles={styles.Waves} />
                <Text variant={5}>The fastest way to build apps</Text>

                <Heading weight={500} styles={styles.Heading}>
                    Hosted Dgraph. Managed by us. <br /> Deployed in your cloud.
                </Heading>

                <ReviewCarousel />

                <Divider />

                <Stack
                    fullWidth={true}
                    styles={{ marginTop: 8 }}
                    justify="space-around"
                >
                    {vanityMetrics.map(metrics => {
                        return (
                            <Stack
                                key={metrics.title}
                                align="center"
                                gap={1}
                                direction="vertical"
                            >
                                <Text styles={styles.Vanity}>
                                    {metrics.title}
                                </Text>
                                <Text variant={4}>{metrics.subTitle}</Text>
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>

            <Stack
                align="center"
                justify="center"
                direction="vertical"
                styles={styles.RightPage}
                gap={4}
            >
                <Image
                    fullWidth={false}
                    styles={{ height: "40px" }}
                    src="/dgraph-logo.png"
                />

                {isForgotPassword && (
                    <BackButton
                        text="Back to Login"
                        onClick={() => setIsForgotPassword(false)}
                    />
                )}

                <ControlledTabs
                    defaultSelected={defaultTab || "login"}
                    tabWidth="150px"
                >
                    <Tab id="login" label="Login">
                        <>
                            {!isForgotPassword && (
                                <Login isForgotPassword={setIsForgotPassword} />
                            )}

                            {isForgotPassword && <ForgotPassword />}
                        </>
                    </Tab>
                    <Tab id="signup" label="Signup">
                        <Signup />
                    </Tab>
                </ControlledTabs>
            </Stack>
        </Stack>
    );
};

export default AuthHomePage;
