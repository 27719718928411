import React, { useState, useEffect } from "react";
import {
    Stack,
    NavbarLogo,
    Text,
    Link,
    Card,
    Button,
} from "@dgraph-io/typhoon-ui";
import { PageContentContainerStyles } from "../styles";
import { styles } from "./PricingCalculator.styles";
import { getNewTierByID, tiers } from "./ProviderData";
import { useForm } from "@dgraph-io/typhoon-ui";
import { useDrift } from "hooks/useDrift";
import { Helmet } from "react-helmet";
import SummaryCard from "./SummaryCard";
import { EnterpriseClusterSelect } from "components/shared/EnterpriseClusterSelect/EnterpriseClusterSelect";
import { useCloudProviders } from "hooks/useCloudProviders/useCloudProviders";
import WavyBackground from "./illustrations/wavy.svg";
import { EnterpriseSettingsConfigurationForm } from "./PricingCalculator.types";
import { DeploymentTypeSelect } from "components/shared/DeploymentTypeSelect/DeploymentTypeSelect";
import { DeploymentType } from "store/deployments/deployments.types";
import { SlashPricingCard } from "components/shared/SlashPricingCard/SlashPricingCard";
import { ZoneSelect } from "components/shared/ZoneSelect/ZoneSelect";
import { useLocation } from "react-router";
import history from "libs/browserHistory";
import { EnterpriseSettings } from "components/shared/EnterpriseSettings/EnterpriseSettings";
import { getJaegerTierByID } from "../../shared/JaegerConfigSelection/constants";

function PricingCalculator() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [deploymentType, setDeploymentType] = useState<DeploymentType>(
        (query.get("type") as DeploymentType) || "dedicated"
    );

    const { form, setValue, formState } = useForm<
        EnterpriseSettingsConfigurationForm
    >({
        cloudProvider: {
            default: null,
        },
        zone: {
            default: null,
        },
        dgraphHA: {
            default: query.get("ha") === "true" || false,
        },
        dgraphHosted: {
            default: true,
        },
        selectedTier: {
            default: query.get("tier")
                ? getNewTierByID(query.get("tier")) || tiers[0]
                : tiers[2],
        },
        storage: {
            default: query.get("size")
                ? Number(query.get("size"))
                : tiers[2].storage,
        },
        auditlogs: {
            default: query.get("audit") === "true" || false,
        },
        alphaNodes: {
            default: null,
        },
        zeroNodes: {
            default: null,
        },
        jaeger: {
            default: query.get("jaeger")
                ? getJaegerTierByID(query.get("jaeger"))
                : null,
        },
        jaegerTrace: {
            default: null,
        },
        acls: {
            default: query.get("acls") === "true" || false,
        },
        shardCount: {
            default: query.get("shard") ? Number(query.get("shard")) : 1,
        },
        prometheus: {
            default: query.get("prom") ? Number(query.get("prom")) : null,
        },
    });

    useDrift();

    const { cloudProvider, zone } = form;

    const {
        zones,
        cloudProviders,
        loading: cloudProvidersLoading,
        slashZones,
    } = useCloudProviders();

    const [slashZone, setSlashZone] = useState<string | null>(null);

    useEffect(() => {
        if (
            !cloudProvidersLoading &&
            cloudProviders.length > 0 &&
            cloudProvider.value !== null
        ) {
            history.push({
                search:
                    "?" +
                    new URLSearchParams({
                        type: deploymentType,
                        ...(slashZone && deploymentType !== "dedicated"
                            ? { zo: slashZone }
                            : {}),

                        ...(deploymentType === "dedicated"
                            ? {
                                  ...(form.selectedTier.value
                                      ? { tier: form.selectedTier.value.id }
                                      : {}),
                                  ...(form.cloudProvider.value?.key
                                      ? { pro: form.cloudProvider.value?.key }
                                      : {}),
                                  ...(form.zone.value
                                      ? { zo: form.zone.value }
                                      : {}),
                                  ...(form.storage.value
                                      ? { size: String(form.storage.value) }
                                      : {}),
                                  ...(form.acls.value === true
                                      ? { acls: "true" }
                                      : {}),
                                  ...(form.auditlogs.value === true
                                      ? { audit: "true" }
                                      : {}),
                                  ...(form.dgraphHA.value === true
                                      ? { ha: "true" }
                                      : {}),
                                  ...(form.shardCount.value &&
                                  form.shardCount.value > 1
                                      ? { shard: String(form.shardCount.value) }
                                      : {}),
                                  ...(form.prometheus.value !== null
                                      ? {
                                            prom: String(form.prometheus.value),
                                        }
                                      : {}),
                                  ...(form.jaeger.value !== null
                                      ? { jaeger: form.jaeger.value.id }
                                      : {}),
                              }
                            : {}),
                    }).toString(),
            });
        }
    }, [slashZone, deploymentType, formState]);

    useEffect(() => {
        if (
            !cloudProvidersLoading &&
            cloudProviders.length > 0 &&
            cloudProvider.value === null
        ) {
            const selectedProvider =
                cloudProviders.find(
                    provider => provider.key === query.get("pro")
                ) || cloudProviders[0];

            setValue({
                cloudProvider: selectedProvider,
                zone:
                    selectedProvider.zoneProvider.find(
                        zone => zone.zoneID === query.get("zo")
                    )?.zoneID ||
                    (cloudProviders[0]?.zoneProvider?.length > 0
                        ? cloudProviders[0].zoneProvider[0].zoneID
                        : null),
            });

            if (deploymentType !== "dedicated") {
                setSlashZone(
                    slashZones.find(szone => szone.zoneID === query.get("zo"))
                        ?.zoneID || slashZones[0].zoneID
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudProviders, cloudProvidersLoading, slashZones]);

    return (
        <>
            <Helmet>
                <title>Pricing Calculator - Dgraph Cloud</title>
            </Helmet>
            <Stack
                styles={{
                    backgroundImage: `url(${WavyBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top right",
                }}
                direction="vertical"
            >
                <Stack
                    styles={{
                        ...PageContentContainerStyles,
                        ...styles.Navbar,
                    }}
                    justify="space-between"
                    align="center"
                >
                    <NavbarLogo
                        link="https://dgraph.io"
                        alt="dgraph-logo"
                        styles={{ height: "40px" }}
                        src="/dgraph-logo.png"
                    />

                    <Link
                        styles={styles.CancelText}
                        href="https://dgraph.io/pricing"
                    >
                        <Text weight={600} color="pink">
                            Cancel and return to website
                        </Text>
                    </Link>
                </Stack>

                <Stack direction="vertical" styles={PageContentContainerStyles}>
                    <Stack
                        fullWidth={true}
                        justify="space-between"
                        direction="horizontal"
                    >
                        <Card styles={styles.CardContainer}>
                            <Stack direction="vertical" gap={2}>
                                <Stack direction="vertical" gap={2}>
                                    <Text color="grey">
                                        Select a product type
                                    </Text>
                                    <DeploymentTypeSelect
                                        deploymentType={deploymentType}
                                        onChange={setDeploymentType}
                                    />
                                </Stack>
                                {deploymentType === "dedicated" && (
                                    <Stack direction="vertical" gap={2}>
                                        <EnterpriseClusterSelect
                                            selectedZone={zone.value}
                                            selectedCloudProvider={
                                                cloudProvider.value
                                            }
                                            cloudProviders={cloudProviders}
                                            onClusterChange={({
                                                selectedZone,
                                                selectedCloudProvider,
                                            }) => {
                                                setValue({
                                                    zone: selectedZone,
                                                    cloudProvider: selectedCloudProvider,
                                                });
                                            }}
                                        />
                                        <EnterpriseSettings
                                            settingsForm={form}
                                            setSettingsForm={setValue}
                                        />
                                    </Stack>
                                )}
                                {deploymentType !== "dedicated" && zone && (
                                    <Stack
                                        gap={1}
                                        direction="vertical"
                                        wrap="nowrap"
                                        styles={{ flex: 1 }}
                                    >
                                        <Text color="grey">
                                            Select a region
                                        </Text>
                                        <ZoneSelect
                                            zones={slashZones}
                                            selected={slashZone}
                                            onChange={zone => {
                                                setSlashZone(zone.zoneID);
                                            }}
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        </Card>
                        {deploymentType === "dedicated" && (
                            <SummaryCard form={form} zones={zones} />
                        )}
                        {deploymentType !== "dedicated" && (
                            <Card
                                styles={{
                                    ...styles.SummaryCardContainer,
                                    padding: 0,
                                }}
                            >
                                <Stack direction="vertical" gap={2}>
                                    <SlashPricingCard
                                        isPaid={deploymentType === "shared"}
                                        region={slashZone}
                                    />
                                    <Stack
                                        styles={{
                                            paddingX: 2,
                                            paddingBottom: 2,
                                        }}
                                    >
                                        <Button
                                            fullWidth={true}
                                            onClick={() => {
                                                const query = new URLSearchParams(
                                                    {
                                                        deploymentType,
                                                        zone: slashZone as string,
                                                    }
                                                ).toString();
                                                history.push(
                                                    "/_/create-deployment?" +
                                                        query
                                                );
                                            }}
                                        >
                                            Launch
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Card>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}

export default PricingCalculator;
