import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { useAuth } from "libs/auth";

function PrivateRoute({ component: Component, history, path, ...rest }) {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }

        const fn = async () => {
            loginWithRedirect(history);
        };
        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isAuthenticated, path]);

    const render = props =>
        isAuthenticated === true ? <Component {...props} /> : null;

    return <Route path={path} render={render} {...rest} />;
}

export default withRouter(PrivateRoute);

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    path: PropTypes.string,
};
