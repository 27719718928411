import PropTypes from "prop-types";
import React from "react";
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import { setContext } from "apollo-link-context";
import { useAuth } from "libs/auth";
import config from "config";

export const AuthorizedApolloProvider = ({ children }) => {
    const { token } = useAuth();

    const httpLink = createHttpLink({
        uri: config.graphqlServerUrl,
    });

    const authLink = setContext((_, { headers }) => {
        if (token) {
            return {
                headers: {
                    ...headers,
                    authorization: "Bearer " + token,
                },
            };
        } else {
            return headers;
        }
    });

    const apolloClient = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        connectToDevtools: true,
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
    children: PropTypes.any,
};
