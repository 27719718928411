import React from "react";
import Spinner from "../Spinner/Spinner";
import { LoadingSpinnerProps } from "./LoadingSpinner.types";

export default function LoadingSpinner({
    loading,
    fullHeight,
    children,
    styles: stylesProp = {},
}: LoadingSpinnerProps) {
    if (loading) {
        return (
            <Spinner
                styles={
                    fullHeight ? { height: "100%", ...stylesProp } : stylesProp
                }
            />
        );
    }

    return <>{children}</>;
}
