import React, { useState } from "react";
import { Card, Stack, Button, Modal, Text } from "@dgraph-io/typhoon-ui";
import { styles } from "./PricingCalculator.styles";

import { CostCalculator } from "./PriceBreakup";
import { useContactFormForDgraphCloud } from "hooks/useContactFormForDgraphCloud/useContactFormForDgraphCloud";
import { ContactForm } from "components/shared/ContactForm/ContactForm";
import { EnterpriseSettingsConfigurationForm } from "./PricingCalculator.types";
import { FormConfig } from "@dgraph-io/typhoon-ui/hooks/form/useForm.types";
import LoadingSpinner from "components/shared/LoadingSpinner/LoadingSpinner";
import { Zone } from "components/shared/ZoneSelect/ZoneSelect.types";
import history from "libs/browserHistory";

const isSupportedNodeConfiguration = (
    zeroNodesCount: number,
    alphaNodesCount: number
) =>
    (zeroNodesCount === 3 && alphaNodesCount === 3) ||
    (zeroNodesCount === 1 && alphaNodesCount === 1);

interface SummaryCard {
    form: FormConfig<EnterpriseSettingsConfigurationForm>;
    zones: Array<Zone>;
}

function SummaryCard({ form, zones }: SummaryCard) {
    const {
        contactForm,
        isModalOpen,
        setIsModalOpen,
        setDeploymentCostPerHour,
        submitForm,
        contactFormErrors,
    } = useContactFormForDgraphCloud();

    const { zone: selectedZone, alphaNodes, zeroNodes } = form;
    const [customPlanRequired, setCustomPlanRequired] = useState(false);

    const isLaunchAvailable =
        zones.find(zone => selectedZone.value == zone.zoneID)?.isAvailable &&
        (zeroNodes.value && alphaNodes.value
            ? isSupportedNodeConfiguration(zeroNodes.value, alphaNodes.value)
            : true);

    return (
        <Stack
            styles={styles.SummaryCardContainer}
            direction="vertical"
            gap={2}
        >
            <Card styles={styles.SummaryCard}>
                <Stack direction="vertical" gap={2}>
                    <LoadingSpinner
                        loading={!form.cloudProvider.value}
                        styles={{ height: "300px" }}
                    >
                        <CostCalculator
                            form={form}
                            setDeploymentCostPerHour={setDeploymentCostPerHour}
                        />
                    </LoadingSpinner>
                    <Stack styles={{ paddingX: 2, paddingBottom: 2 }}>
                        {isLaunchAvailable ? (
                            <Button
                                fullWidth={true}
                                onClick={() => {
                                    const query = new URLSearchParams({
                                        deploymentType: "dedicated",
                                        selectedTier: form.selectedTier.value
                                            .id as string,
                                        storage: String(form.storage.value),
                                        zone: form.zone.value as string,
                                        cloudProvider: form.cloudProvider.value
                                            ?.key as string,
                                        dgraphHA: String(form.dgraphHA.value),
                                        ...(form.jaeger.value
                                            ? { jaeger: form.jaeger.value.id }
                                            : {}),
                                        ...(form.jaegerTrace.value
                                            ? {
                                                  jaegerTrace: String(
                                                      form.jaegerTrace.value
                                                  ),
                                              }
                                            : {}),
                                    }).toString();

                                    history.push(
                                        "/_/create-deployment?" + query
                                    );
                                }}
                            >
                                Launch
                            </Button>
                        ) : (
                            <Button
                                onClick={() => setIsModalOpen(true)}
                                fullWidth={true}
                            >
                                Contact Us
                            </Button>
                        )}
                    </Stack>
                </Stack>

                <Modal
                    styles={{ position: "fixed" }}
                    onClose={() => {
                        setIsModalOpen(false);
                        setCustomPlanRequired(false);
                    }}
                    isOpen={isModalOpen}
                >
                    <ContactForm
                        form={contactForm}
                        formErrors={contactFormErrors}
                        onSubmit={() => {
                            submitForm(form, customPlanRequired);
                            setCustomPlanRequired(false);
                        }}
                    />
                </Modal>
            </Card>
            <Stack
                direction="horizontal"
                gap={1}
                align="center"
                justify="center"
            >
                <Text variant={1} color="grey" weight={500}>
                    Need a larger solution?
                </Text>
                <Button
                    onClick={() => {
                        setIsModalOpen(true);
                        setCustomPlanRequired(true);
                    }}
                    styles={styles.ContactUs}
                >
                    Contact Sales
                </Button>
            </Stack>
        </Stack>
    );
}

export default SummaryCard;
