import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Stack, Text } from "@dgraph-io/typhoon-ui";
import { ReactComponent as CrossIcon } from "./cross-icon.svg";
import { AuthHeaderPropsType } from "./AuthHeader.types";
import { uuid } from "@dgraph-io/typhoon-ui";
import { styles } from "./AuthHeader.styles";

export const AuthHeader = ({
    onKeyChange,
    onValueChange,
    onRemoveHeader,
    onAddHeader,
    keyPrefix,
    headers = [],
}: AuthHeaderPropsType) => {
    const [isKeyAdded, setIsKeyAdded] = useState(false);
    const [isValueAdded, setIsValueAdded] = useState(false);
    const lastHeaderKeyInputRef = useRef<HTMLInputElement>(null);
    const lastHeaderValueInputRef = useRef<HTMLInputElement>(null);

    //! For key
    useEffect(() => {
        if (lastHeaderValueInputRef.current) {
            lastHeaderValueInputRef.current.focus();
            setIsValueAdded(false);
        }
    }, [isValueAdded]);

    //! For value
    useEffect(() => {
        if (lastHeaderKeyInputRef.current) {
            lastHeaderKeyInputRef.current.focus();
            setIsKeyAdded(false);
        }
    }, [isKeyAdded]);

    return (
        <Stack
            direction="vertical"
            styles={{ paddingBottom: 1 }}
            gap={1}
            fullWidth={true}
        >
            {headers?.map((header, index) => {
                const isLastInput = headers.length - 1 === index;
                return (
                    <Stack
                        key={header.id}
                        align="center"
                        gap={1}
                        wrap="nowrap"
                        fullWidth={true}
                    >
                        {keyPrefix && <Text>{keyPrefix}</Text>}
                        <Input
                            placeholder="Key"
                            value={header.key}
                            styles={styles.Input}
                            onChange={e =>
                                onKeyChange({
                                    key: e.target.value,
                                    id: header.id,
                                })
                            }
                            {...(isLastInput
                                ? { ref: lastHeaderKeyInputRef }
                                : {})}
                        />
                        <Input
                            placeholder="Value"
                            value={header.value}
                            styles={styles.Input}
                            onChange={e =>
                                onValueChange({
                                    value: e.target.value,
                                    id: header.id,
                                })
                            }
                            {...(isLastInput
                                ? { ref: lastHeaderValueInputRef }
                                : {})}
                        />

                        <Button
                            onClick={() => onRemoveHeader(header.id)}
                            styles={styles.CrossButton}
                        >
                            <CrossIcon
                                height="14"
                                width="14"
                                style={{
                                    cursor: "pointer",
                                    margin: "6px",
                                }}
                            />
                        </Button>
                    </Stack>
                );
            })}

            <Stack gap={1} wrap="nowrap" align="center" fullWidth={true}>
                {keyPrefix && <Text>{keyPrefix}</Text>}
                <Input
                    placeholder="Key"
                    value={""}
                    styles={styles.Input}
                    onChange={e => {
                        onAddHeader({
                            id: uuid(),
                            key: e.target.value,
                            value: "",
                        });

                        setIsKeyAdded(true); // this state is managed to keep the focus
                    }}
                />

                <Input
                    placeholder="Value"
                    value={""}
                    styles={styles.Input}
                    onChange={e => {
                        onAddHeader({
                            id: uuid(),
                            value: e.target.value,
                            key: "",
                        });

                        setIsValueAdded(true);
                    }}
                />
                <Button
                    disabled={true}
                    styles={{ ...styles.CrossButton, opacity: 0 }}
                >
                    <CrossIcon
                        height="14"
                        width="14"
                        style={{
                            cursor: "pointer",
                            margin: "6px",
                        }}
                    />
                </Button>
            </Stack>
        </Stack>
    );
};
