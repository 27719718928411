import React from "react";
import { Stack } from "@dgraph-io/typhoon-ui";
import { TourContext } from "components/shared/Tour/Tour";
import { Sidebar } from "components/shared/Sidebar/Sidebar";
import { SidebarGroup } from "components/shared/Sidebar/SidebarGroup";
import { useAuth } from "libs/auth";
import { styles } from "./Layout.styles";
import { useAppRoute } from "config";
import { getNavlinks } from "./navigationlinks";
import { useContext } from "react";
import { tourStates, tourCheckpoints, isTourOpen } from "tour";
import TourWidget from "components/shared/TourWidget/TourWidget";
import { useLocation } from "react-router-dom";
import trackEvent, { events } from "libs/trackevents";
import { launchRatel } from "./utils";

export default function SlashSidebar({ disabled = false }) {
    const { user, loading } = useAuth();

    const { path } = useAppRoute();
    const location = useLocation();
    const isSuperAdmin = !loading && user?.config?.isSuperAdmin === true;
    const { tourState, setTourStep, dispatchTourEvent, tourSteps } = useContext(
        TourContext
    );
    const {
        overviewLinks,
        developLinks,
        adminLinks,
        resourceLinks,
    } = getNavlinks({
        path,
        disabled,
        isSuperAdmin,
        dispatchTourEvent,
        openRatel: launchRatel,
    });

    return (
        <Stack styles={styles.AppSidebarContainerStyles}>
            <Sidebar styles={styles.SidebarStyles}>
                <SidebarGroup
                    links={overviewLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Develop"
                    links={developLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Admin"
                    links={adminLinks}
                    pathname={location.pathname}
                />
                <SidebarGroup
                    title="Resources"
                    links={resourceLinks}
                    pathname={location.pathname}
                />
            </Sidebar>

            <Stack gap={2} direction="vertical" styles={styles.BottomNavStyles}>
                {isTourOpen(tourState) && (
                    <TourWidget
                        styles={{ margin: "auto" }}
                        steps={tourSteps}
                        checkpoints={tourCheckpoints}
                        currentStep={tourState}
                        onExit={() => {
                            setTourStep(tourStates.DISMISSED);
                            trackEvent(events.exitTour);
                        }}
                    />
                )}
            </Stack>
        </Stack>
    );
}
