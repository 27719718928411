export const styles: any = {
    Input: {
        flexGrow: 1,
        width: "40%",
    },
    RequestHeader: {
        marginY: 1,
    },
    CrossButton: {
        background: "none",
        padding: 0,
    },
};
