import { GoogleResponse, GoogleFailureResponse } from "./../Auth.types";
import { useEffect, useState } from "react";
import { useForm, RULES, useToast } from "@dgraph-io/typhoon-ui";
import { ApolloError } from "@apollo/client";
import { useAuth } from "libs/auth";
import history from "libs/browserHistory";
import { ToastContext } from "libs/toast";

type SigninTouched = {
    email: boolean;
    password: boolean;
};

export const useLoginForm = () => {
    const toast = useToast(ToastContext);
    const { isAuthenticated } = useAuth();
    const [touched, setTouched] = useState<SigninTouched>({
        email: false,
        password: false,
    });

    const { form: loginForm, setValue, validate } = useForm({
        email: {
            default: "",
            rules: [RULES.email, RULES.nonEmptyString],
        },
        password: {
            default: "",
            rules: [RULES.nonEmptyString],
        },
    });

    const handleLoginError = (err: ApolloError) => {
        toast.addToast({
            variant: "error",
            heading: err.message,
        });
    };

    const handleRedirect = () => {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        history.push(redirect ?? "/dashboard");
    };
    const errors = validate(loginForm);

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirect();
        }
    }, [isAuthenticated]);

    const onGoogleSuccess = (res: GoogleResponse) => {
        if (res.accessToken) {
            history.push("/oauth/google?token=" + res.accessToken);
        }
    };

    const onGoogleFailure = (res: GoogleFailureResponse) => {
        console.error(res.error);
    };

    return {
        loginForm,
        setValue,
        errors,
        handleLoginError,
        handleRedirect,
        onGoogleSuccess,
        onGoogleFailure,
        touched,
        setTouched,
    };
};
