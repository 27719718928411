/** @jsx jsx */
import { jsx } from "@emotion/react";
import { stylegun } from "@dgraph-io/typhoon-ui";
import { styles } from "./Sidebar.styles";
import { theme } from "@dgraph-io/typhoon-ui";
import { Stack } from "@dgraph-io/typhoon-ui";
import { SidebarPropsType } from "./Sidebar.types";

export const Sidebar = ({
    width = "300px",
    children,
    styles: stylesProp = {},
}: SidebarPropsType) => {
    return (
        <Stack
            direction="vertical"
            gap={3}
            styles={stylegun({
                css: { width, ...styles.Sidebar, ...stylesProp },
                theme,
                component: "Sidebar",
            })}
        >
            {children}
        </Stack>
    );
};
