import { useEffect } from "react";
import drift from "../components/pages/PricingCalculator/Drift";

export const useDrift = () => {
    useEffect(() => {
        const script: any = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.innerHTML = drift;
        document.head.appendChild(script);
        return () => script.parentNode.removeChild(script);
    }, []);
};
