import { useCallback, useState } from "react";
import Axios from "axios";
import { useToast } from "@dgraph-io/typhoon-ui";
import {
    DQLSchemaEditorProps,
    DQLSchemaParsed,
} from "../DQLSchemaEditor.types";
import { SchemaParserList } from "components/shared/SchemaBuilder/utils/transformers/transformers.types";
import { parse } from "components/shared/SchemaBuilder/utils/transformers/parsers";
import { headers } from "deployments/lib";
import { ToastContext } from "libs/toast";

export const useFetchDQLSchema = ({
    deployment,
    url,
    namespace
}: DQLSchemaEditorProps) => {
    const [graphqlSchema, setGraphQLSchema] = useState<SchemaParserList>([]);
    const [dqlSchema, setDqlSchema] = useState<DQLSchemaParsed>({
        types: [],
        schema: [],
    });
    const [isRateLimited, setIsRateLimited] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useToast(ToastContext);

    const fetchSchema = useCallback(async () => {
        setLoading(true);
        let res;
        try {
            res = await Axios.post(url + "/query", `schema {}`, {
                headers: {
                    "Content-Type": "application/graphql+-", // very important
                    ...headers(deployment, namespace),
                },
            });

            const graphqlSchemaResponse = await Axios.post(
                url + "/admin",
                {
                    query: `{
                        getGQLSchema {
                            schema
                            generatedSchema
                        }
                    }`,
                },
                {
                    headers: headers(deployment, namespace),
                }
            );
            const { getGQLSchema } = graphqlSchemaResponse?.data?.data;

            setGraphQLSchema(
                getGQLSchema?.schema && getGQLSchema?.schema !== ""
                    ? parse(getGQLSchema.schema, {
                          ignoreAuth: true,
                          ignoreInvalidObjects: true,
                          parseInterface: true,
                      })
                    : []
            );
        } catch (e) {
            console.error(e);
            if (e?.response?.status === 429) {
                setIsRateLimited(true);
            }
            toast.addToast({
                variant: "error",
                description: "Not able to fetch schema",
            });
            return;
        }

        try {
            if (res.data && res.data.data && res.data.data.schema) {
                setDqlSchema(res.data.data);
                setLoading(false);
            }
        } catch (e) {
            console.error(e);

            toast.addToast({
                variant: "error",
                description: "Something went wrong",
            });

            return;
        }
    }, [url, deployment.jwtToken, toast, namespace]);

    return {
        graphqlSchema,
        fetchSchema,
        dqlSchema,
        loading,
        isRateLimited,
    };
};
