import { Button, Container, Stack, StylesObject, Text } from "@dgraph-io/typhoon-ui";
import React from "react";

export const MEMORY_OPTIONS = [
    { value: 32, label: "32GB" },
    { value: 64, label: "64GB" },
    { value: 128, label: "128GB" },
    { value: 256, label: "256GB" },
    { value: 512, label: "512GB" },
    { value: 768, label: "768GB" },
    { value: 1024, label: "1 TB" },
];

export const MemorySlider = ({
    selected,
    setValue,
    styles = {},
}: {
    setValue: (value: number) => void;
    selected: number;
    styles?: StylesObject;
}) => {
    return (
        <Stack
            direction="horizontal"
            wrap="nowrap"
            fullWidth={true}
            styles={styles}
        >
            {MEMORY_OPTIONS.map(({ value, label }, index) => (
                <Stack
                    direction="vertical"
                    key={value}
                    align="center"
                    gap={1}
                    styles={{ flex: 1, position: "relative" }}
                >
                    <Stack
                        direction="vertical"
                        key={value}
                        align="center"
                        styles={{ position: "relative" }}
                        fullWidth={true}
                    >
                        <Button
                            onClick={() => setValue(value)}
                            styles={{
                                padding: 0,
                                height: "unset",
                                position: "absolute",
                                transform: "translateY(-50%)",
                                left: 0,
                                top: "50%",
                                size: "12px",
                                background:
                                    selected >= value ? "pink" : "grey.border",
                                borderRadius: 3,
                            }}
                        />
                        {index < MEMORY_OPTIONS.length - 1 && (
                            <Container
                                styles={{
                                    height: "3px",
                                    width: "100%",
                                    background:
                                        selected > value
                                            ? "pink"
                                            : "grey.border",
                                }}
                            />
                        )}
                    </Stack>
                    <Text
                        color="grey"
                        variant={1}
                        weight={500}
                        styles={{
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            left: "6px",
                            top: "calc(100% + 4px)",
                        }}
                    >
                        {label}
                    </Text>
                </Stack>
            ))}
        </Stack>
    );
};
