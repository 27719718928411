import React from "react";
import { Router } from "react-router-dom";

import Routes from "./routes";
import history from "libs/browserHistory";
import { useGetCurrentCloudUser } from "gql/hooks/users/users.hook";

function App() {
    useGetCurrentCloudUser();
    return (
        <Router history={history}>
            <Routes />
        </Router>
    );
}

export default App;
