import React from "react";
import { Button, Heading, Stack, Text } from "@dgraph-io/typhoon-ui";
import { styles } from "./DeploymentTypeSelect.styles";
import { DeploymentType } from "store/deployments/deployments.types";
export const DeploymentTypeSelect = ({
    deploymentType,
    onChange,
    disabled = {
        free: false,
        dedicated: false,
        shared: false,
    },
}: {
    deploymentType: DeploymentType;
    onChange: (deploymentType: DeploymentType) => void;
    disabled?: Record<DeploymentType, boolean>;
}) => {
    return (
        <Stack direction="horizontal" gap={2}>
            <DeploymentTypeOption
                isSelected={deploymentType === "free"}
                onClick={() => onChange("free")}
                name="Free"
                description="Shared cluster"
                subdescription="For individuals learning Dgraph or developing a small application. Get 1MB data per day."
                disabled={disabled.free}
            />
            <DeploymentTypeOption
                isSelected={deploymentType === "shared"}
                onClick={() => onChange("shared")}
                name="Shared"
                description="Shared cluster"
                subdescription="For individuals and teams building applications that needs a production-ready environment."
                disabled={disabled.shared}
            />
            <DeploymentTypeOption
                isSelected={deploymentType === "dedicated"}
                onClick={() => onChange("dedicated")}
                name="Dedicated"
                description="Dedicated cluster"
                subdescription="For teams developing enterprise-grade applications that require high resiliency and ultra-low latency."
                disabled={disabled.dedicated}
            />
        </Stack>
    );
};

const DeploymentTypeOption = ({
    name,
    description,
    subdescription,
    isSelected,
    onClick,
    disabled = false,
}: {
    name: string;
    description: string;
    subdescription: string;
    isSelected: boolean;
    onClick: () => void;
    disabled: boolean;
}) => (
    <Button
        disabled={disabled}
        styles={{
            position: "relative",
            ...styles.OptionButton,
            ...(isSelected ? styles.Selected : {}),
        }}
        onClick={onClick}
    >
        {disabled && name == "Free" && (
            <Text
                variant={1}
                styles={{
                    fontSize: "11px",
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    color: "grey",
                    background: "grey.border",
                    borderRadius: 4,
                    paddingY: "3px",
                    paddingX: "8px",
                }}
            >
                1/1 Launched
            </Text>
        )}
        <Stack
            direction="vertical"
            gap={2}
            align="center"
            justify="space-between"
            styles={{ height: "100%" }}
        >
            <Stack direction="vertical" align="center">
                <Heading
                    variant={4}
                    color="pink"
                    styles={{ textAlign: "center" }}
                >
                    {name}
                </Heading>

                <Text
                    variant={1}
                    color="grey"
                    weight={500}
                    styles={{ textAlign: "center" }}
                >
                    {description}
                </Text>
            </Stack>
            <Text
                variant={2}
                color="grey"
                weight={400}
                styles={{ textAlign: "center", flex: 1 }}
            >
                {subdescription}
            </Text>
        </Stack>
    </Button>
);
