import React from "react";
import { Button, Image, Text, Stack } from "@dgraph-io/typhoon-ui";
import GithubSvg from "../icons/github.png";
import config from "config";

export const GithubLoginButton = ({ text }: { text: string }) => {
    return (
        <Button
            onClick={() => {
                location.href = `https://github.com/login/oauth/authorize?redirect_uri=${config.auth.github.redirect_uri}&client_id=${config.auth.github.clientId}&scope=user%3Aemail`;
            }}
            variant="murmur"
            styles={{ background: "black" }}
        >
            <Stack align="center" justify="center" gap={3}>
                <Image
                    styles={{ height: 2 }}
                    src={GithubSvg}
                    alt="github"
                    fullWidth={false}
                />
                <Text styles={{ color: "white" }}>{text}</Text>
            </Stack>
        </Button>
    );
};
