import { maxWidth, minWidth } from "components/mediaqueries";
const SIDEBAR_WIDTH = "250px";

export const styles: any = {
    AppConsoleContainer: {
        display: "flex",
        paddingTop: 64,
    },
    SelectBackendStyles: {
        width: SIDEBAR_WIDTH,
        marginLeft: 4,
        [maxWidth[1]]: {
            display: "none",
        },
    },
    SidebarStyles: {
        width: "100%",
        background: "white",
        zIndex: "sidebar",
        borderRight: "{{lineThickness.1}} solid {{colors.grey.border}}",
    },
    AppSidebarContainerStyles: {
        width: SIDEBAR_WIDTH,
        backgroundColor: "white",
        [maxWidth[1]]: {
            display: "none",
        },
    },
    AppSidebarMobileContainerStyles: {
        height: "100%",
        backgroundColor: "white",
    },
    MenuIcon: {
        [minWidth[1]]: {
            display: "none",
        },
    },
    Text: {
        color: "grey.default",
    },
    BottomNavStyles: {
        bottom: 0,
        position: "fixed",
        width: SIDEBAR_WIDTH,
        padding: 0,
        marginBottom: 2,
        zIndex: "calc({{zIndices.sidebar}} + 1)",
    },
    PageContainer: {
        backgroundColor: "grey.surface",
        overflow: "auto",
        width: `calc(100% - ${SIDEBAR_WIDTH})`,
        [maxWidth[1]]: {
            width: "100%",
            left: 0,
        },
    },
    CreateBackend: {
        borderRadius: 2,
        right: "80px",
        position: "fixed",
        [maxWidth[1]]: {
            display: "none",
        },
    },
    UserProfile: {
        right: 2,
        position: "fixed",
        cursor: "pointer",
    },

    CrediCardReminderModal: { padding: 0, minWidth: "380px", width: "380px" },
};
