import { maxWidth } from "components/mediaqueries";

export const styles: any = {
    OptionButton: {
        flex: 1,
        minWidth: "unset",
        textAlign: "left",
        height: "unset",
        padding: 2,
        background: "white",
        border: "2px solid {{colors.grey.border}}",
        [maxWidth[2]]: {
            width: "unset",
            flex: 1,
        },
    },
    Selected: {
        boxShadow: 2,
        border: "2px solid {{colors.pink}}",
    },
};
