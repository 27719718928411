import { maxWidth } from "components/mediaqueries";

export const PageContentContainerStyles = {
    marginX: "auto",
    marginY: 6,
    width: "calc(100% - {{space.11}})",
    maxWidth: "1300px",
    [maxWidth[2]]: {
        marginX: 10,
    },
    [maxWidth[1]]: {
        marginX: 2,
        width: "calc(100% - {{space.4}})",
    },
};
