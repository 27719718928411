import React from "react";
import { Heading, Stack, Text, Divider } from "@dgraph-io/typhoon-ui";

export const SlashPricingCard = ({
    region,
    isPaid,
}: {
    region: string | null;
    isPaid: boolean;
}) => {
    return (
        <Stack direction="vertical">
            <Stack
                gap={2}
                direction="vertical"
                styles={{ padding: 3, paddingBottom: 1 }}
            >
                <Heading variant={3}>Summary</Heading>
                <Stack direction="vertical" gap={2}>
                    <Stack justify="space-between">
                        <Text>Region</Text>
                        <Text weight={600}>{region ? region : "-"}</Text>
                    </Stack>
                    <Divider />
                    <Stack justify="space-between">
                        <Text>Storage</Text>
                        <Text weight={600}>Limited to 25 GB</Text>
                    </Stack>
                    <Stack justify="space-between">
                        <Text>Data Transfer</Text>
                        <Text weight={600}>
                            {isPaid
                                ? "5 GB data transfer"
                                : "Limited to 1MB/day"}
                        </Text>
                    </Stack>
                    {isPaid && (
                        <Text
                            weight={600}
                            variant={2}
                            color="grey"
                            styles={{ marginLeft: "auto" }}
                        >
                            $2/additional GB
                        </Text>
                    )}
                    <Divider></Divider>
                </Stack>
            </Stack>
            <Stack direction="vertical" gap={1}>
                <Stack
                    align="center"
                    justify="space-between"
                    styles={{
                        background: "pink.translucent",
                        paddingY: 1,
                        paddingX: 3,
                    }}
                >
                    <Text weight={600}>Total Cost</Text>
                    <Text weight={600} variant={5}>
                        {" "}
                        {isPaid ? "$39.99" : "$0.00"}
                    </Text>
                </Stack>
                <Stack
                    gap={2}
                    direction="vertical"
                    styles={{ paddingX: 3, paddingBottom: 3 }}
                >
                    <Stack align="center" justify="space-between">
                        <Text weight={200} variant={2}>
                            Estimated Monthly Cost
                        </Text>

                        <Text weight={200} variant={2}>
                            {isPaid ? "$39.99" : "$0.00"}
                        </Text>
                    </Stack>
                    <Stack direction="vertical" gap={2}>
                        <Text color="grey" variant={1}>
                            *The estimation may vary slightly from the official
                            quote/invoice.
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
