export const styles: any = {
    Cell: {
        flex: 1,
        textAlign: "center",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    FirstCell: {
        width: "180px",
        textAlign: "center",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
    },
    TierCell: {
        background: "pink.translucent",
    },
    SettingsHeaderCell: {
        color: "grey",
        background: "grey.surface",
        height: "unset",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Row: {
        height: "fit-content",
        minHeight: "40px",
    },
    TierRow: {
        cursor: "pointer",
        borderBottom: "{{lineThickness.1}} solid {{colors.grey.border}}",
    },
    SettingsCell: {
        height: "auto",
        width: "66%",
        padding: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "initial",
    },
    SelectedRow: {
        border: "2px solid {{colors.pink}}",
        borderRadius: 1,
        boxShadow: 2,
        transform: "scale(1.002)",
    },
    Class: {
        fontSize: 1,
        whiteSpace: "nowrap",
        background: "white",
        color: "grey",
        flex: 1,
        maxWidth: "34%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: "unset",
        height: "unset",
        padding: 1,
        border: "{{lineThickness.1}} solid {{colors.grey.border}}",
    },
    SelectedClass: {
        border: "{{lineThickness.1}} solid {{colors.pink}}",
        backgroundColor: "pink.translucent",
    },
};
