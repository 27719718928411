import React from "react";
import {
    Stack,
    TableHead,
    TableHeading,
    TableRow,
    TableBody,
    TableDescription,
    Heading,
    Table,
    Text,
    Button,
} from "@dgraph-io/typhoon-ui";

import { styles } from "./ConfigTable.styles";
import { ConfigTablePropsType } from "./ConfigTable.types";

export const ConfigTable = ({
    config,
    selected,
    onSelect,
    disabled = false,
}: ConfigTablePropsType) => {
    return (
        <Stack
            direction="vertical"
            styles={{
                width: "calc(100% - {{lineThickness.2}})",
                marginLeft: "{{lineThickness.1}}",
            }}
        >
            <Table
                styles={{
                    textAlign: "center",
                }}
            >
                <TableHead>
                    <TableRow
                        styles={{
                            textAlign: "center",
                        }}
                    >
                        {config.headers.map(header => (
                            <TableHeading key={header.id}>
                                {header.label}
                            </TableHeading>
                        ))}
                    </TableRow>
                </TableHead>

                {config.options.map(option => {
                    return (
                        <TableBody
                            key={option.id}
                            styles={{
                                ...(selected?.id === option.id
                                    ? styles.SelectedTier
                                    : styles.UnselectedTier),
                            }}
                        >
                            <TableRow
                                styles={{
                                    cursor: "pointer",
                                    padding: 1,
                                }}
                            >
                                <TableDescription
                                    styles={styles.TableDescription}
                                >
                                    <Stack
                                        justify="center"
                                        align="center"
                                        direction="vertical"
                                    >
                                        <Button
                                            disabled={disabled}
                                            variant="ghost"
                                            styles={styles.TableButton}
                                            fullWidth={true}
                                            onClick={() => onSelect(option)}
                                        >
                                            <Heading variant={4} weight={500}>
                                                {option.label}
                                            </Heading>
                                            {option.description && (
                                                <Text variant={1} color="pink">
                                                    ({option.description})
                                                </Text>
                                            )}
                                        </Button>
                                    </Stack>
                                </TableDescription>

                                {option.values.map(value => {
                                    return (
                                        <TableDescription key={value.id}>
                                            <Button
                                                disabled={disabled}
                                                variant="ghost"
                                                fullWidth={true}
                                                styles={styles.TableButton}
                                                onClick={() => onSelect(option)}
                                            >
                                                <Heading
                                                    variant={4}
                                                    weight={500}
                                                >
                                                    {value.label}
                                                </Heading>
                                            </Button>
                                        </TableDescription>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    );
                })}
            </Table>
        </Stack>
    );
};
