export type Tier = {
    label: string;
    ram: number;
    vCPU: number;
    storage: number;
    id: TierID;
    description?: string;
};

export type OldTier = {
    label: string;
    ram: number;
    vCPU: number;
    storage: number;
    id: OldTierIDs;
    description?: string;
};

export type OldTierIDs = "small" | "medium" | "large" | "xlarge" | "xxlarge";

export type TierID =
    | "1M"
    | "1R"
    | "2M"
    | "2R"
    | "4M"
    | "4R"
    | "8M"
    | "8R"
    | "16C"
    | "16M"
    | "16R"
    | "32C"
    | "32M"
    | "32R"
    | "48M"
    | "48C"
    | "48R"
    | "64M"
    | "64C"
    | "64R";

export type TierGroup = {
    label: string;
    cores: number;
    default: "rType" | "mType" | "cType";
    rType: Tier | null;
    cType: Tier | null;
    mType: Tier | null;
    startingPrice: string;
};

export const disabledTiersGCP: Array<TierID> = [
    "8R",
    "16C",
    "16M",
    "16R",
    "32C",
    "32M",
    "32R",
    "64C",
    "64M",
    "64R",
];

export const oldTiers: Array<OldTier> = [
    {
        label: "2C",
        ram: 8,
        vCPU: 2,
        storage: 40,
        id: "medium",
    },
    {
        label: "4C",
        ram: 16,
        vCPU: 4,
        storage: 80,
        id: "large",
    },
    {
        label: "8C",
        description: "Recommended",
        ram: 32,
        vCPU: 8,
        storage: 160,
        id: "xlarge",
    },
    {
        label: "16C",
        ram: 64,
        vCPU: 16,
        storage: 320,
        id: "xxlarge",
    },
];

export const prodTierGroups: Array<TierGroup> = [
    {
        label: "8C",
        cores: 8,
        default: "mType",
        mType: {
            ram: 32,
            vCPU: 8,
            storage: 256,
            id: "8M",
            label: "8M",
        },
        rType: {
            ram: 64,
            vCPU: 8,
            storage: 256,
            id: "8R",
            label: "8R",
        },
        cType: null,
        startingPrice: "0.88",
    },
    {
        label: "16C",
        cores: 16,
        default: "mType",
        mType: {
            ram: 64,
            vCPU: 16,
            storage: 512,
            id: "16M",
            label: "16M",
        },
        rType: {
            ram: 128,
            vCPU: 16,
            storage: 512,
            id: "16R",
            label: "16R",
        },
        cType: {
            ram: 32,
            vCPU: 16,
            storage: 512,
            id: "16C",
            label: "16C",
        },
        startingPrice: "1.66",
    },
    {
        label: "32C",
        cores: 32,
        default: "mType",
        mType: {
            ram: 128,
            vCPU: 32,
            storage: 512,
            id: "32M",
            label: "32M",
        },
        rType: {
            ram: 256,
            vCPU: 32,
            storage: 512,
            id: "32R",
            label: "32R",
        },
        cType: {
            ram: 64,
            vCPU: 32,
            storage: 512,
            id: "32C",
            label: "32C",
        },
        startingPrice: "3.21",
    },
    {
        label: "48C",
        cores: 48,
        default: "mType",
        mType: {
            ram: 192,
            vCPU: 48,
            storage: 512,
            id: "48M",
            label: "48M",
        },
        rType: {
            ram: 384,
            vCPU: 48,
            storage: 512,
            id: "48R",
            label: "48R",
        },
        cType: {
            ram: 96,
            vCPU: 48,
            storage: 512,
            id: "48C",
            label: "48C",
        },
        startingPrice: "4.17",
    },
    {
        label: "64C",
        cores: 64,
        default: "mType",
        mType: {
            ram: 256,
            vCPU: 64,
            storage: 512,
            id: "64M",
            label: "64M",
        },
        rType: {
            ram: 512,
            vCPU: 64,
            storage: 512,
            id: "64R",
            label: "64R",
        },
        cType: {
            ram: 128,
            vCPU: 64,
            storage: 512,
            id: "64C",
            label: "64C",
        },
        startingPrice: "6.33",
    },
];

export const devTierGroups: Array<TierGroup> = [
    {
        label: "1M",
        cores: 1,
        default: "mType",
        rType: {
            label: "1R",
            ram: 8,
            vCPU: 1,
            storage: 32,
            id: "1R",
        },
        mType: {
            label: "1M",
            ram: 4,
            vCPU: 1,
            storage: 32,
            id: "1M",
        },
        cType: null,
        startingPrice: "0.12",
    },
    {
        label: "2M",
        cores: 2,
        default: "mType",
        rType: {
            label: "2R",
            ram: 16,
            vCPU: 2,
            storage: 32,
            id: "2R",
        },
        mType: {
            label: "2M",
            ram: 8,
            vCPU: 2,
            storage: 32,
            id: "2M",
        },
        cType: null,
        startingPrice: "0.24",
    },
    {
        label: "4M",
        cores: 4,
        default: "mType",
        rType: {
            ram: 32,
            vCPU: 4,
            storage: 64,
            id: "4R",
            label: "4R",
        },
        cType: null,
        mType: {
            label: "4M",
            ram: 16,
            vCPU: 4,
            storage: 64,
            id: "4M",
        },
        startingPrice: "0.49",
    },
];

export const tierGroups: Array<TierGroup> = [
    ...devTierGroups,
    ...prodTierGroups,
];

export const tiers = tierGroups.reduce((agg, group) => {
    if (group.cType) {
        agg.push(group.cType);
    }
    if (group.mType) {
        agg.push(group.mType);
    }
    if (group.rType) {
        agg.push(group.rType);
    }

    return agg;
}, [] as Array<Tier>);

export const getTierByID = (id: string | undefined | null) => {
    return [...tiers, ...oldTiers].find(tier => tier.id === id) || null;
};
export const getNewTierByID = (id: string | undefined | null) => {
    return tiers.find(tier => tier.id === id) || null;
};
