import { maxWidth } from "components/mediaqueries";

export const styles: any = {
    CardContainer: {
        height: "fit-content",
        width: "min-content",
        flex: 1,
    },
    Navbar: {
        minHeight: 4,
        paddingTop: 3,
        marginTop: 0,
    },

    SummaryCardContainer: {
        width: "400px",
        marginLeft: 2,
        [maxWidth[1]]: {
            marginLeft: 0,
            marginTop: 1,
            width: "100%",
        },
    },

    SummaryCard: {
        width: "100%",
        height: "fit-content",
        boxShadow: 3,
        padding: 0,
    },
    ImageStyles: {
        objectFit: "contain",
        height: "60px",
        width: "120px",
    },
    SelectedTier: {
        outline: "{{lineThickness.1}} solid {{colors.pink}}",
    },
    UnselectedTier: {
        outline: "{{lineThickness.1}} solid transparent",
    },
    TableDescription: {
        background: "pink.translucent",
    },
    TableDescriptionDetails: {
        background: "grey.surface",
    },
    Input: {
        textAlign: "right",
        width: "100px",
    },
    TableButton: {
        fontSize: 2,
        fontWeight: 500,
        height: 4,
        "&:hover": {
            transition: "none",
            background: "inherit",
        },
    },
    CancelText: {
        [maxWidth[1]]: {
            display: "none",
        },
    },
    RowGap: {
        rowGap: 5,
    },

    ContactUs: {
        fontSize: 1,
        background: "transparent",
        color: "pink",
        padding: 0,
        height: "unset",
        fontWeight: 500,
    },
};
