import { Button, Card, Divider, Heading, Stack, StylesObject, Text } from "@dgraph-io/typhoon-ui";
import { getDaysDifference, getShortDate, getShortTime } from "@dgraph-io/typhoon-ui";
import { uuid } from "@dgraph-io/typhoon-ui";
import React from "react";
import { DqlQueryState } from "../../reducer/dqlQueryExecutor.reducer.types";
import { styles } from "./HistoryPanel.styles";

export const HistoryPanel = ({
    setState,
    styles: stylesProp,
    dqlHistory,
    clearHistory,
}: {
    styles?: StylesObject;
    dqlHistory: DqlQueryState[];
    setState: (state: DqlQueryState) => void;
    clearHistory: () => void;
}) => {
    return (
        <Card
            styles={{
                ...stylesProp,
                padding: 1,
                paddingX: 2,
            }}
        >
            <Stack
                direction="horizontal"
                justify="space-between"
                align="center"
                styles={{ padding: 1 }}
            >
                <Heading variant={4}>History</Heading>
                <Button
                    onClick={clearHistory}
                    variant="ghost"
                    styles={{
                        fontWeight: 500,
                        marginLeft: "auto",
                        height: 3,
                        fontSize: 1,
                        paddingX: 1,
                    }}
                >
                    Clear
                </Button>
            </Stack>
            <Stack
                direction="vertical"
                wrap="nowrap"
                styles={{
                    height: "calc(100% - 70px)",
                    overflowY: "auto",
                    width: "100%",
                }}
            >
                {dqlHistory.length === 0 && (
                    <Text
                        variant={1}
                        color="grey"
                        styles={{
                            paddingX: 2,
                            paddingY: 1,
                            width: "fit-content",
                            margin: "auto",
                            borderRadius: 4,
                            background: "grey.surface",
                        }}
                        weight={500}
                    >
                        History is clear ✨
                    </Text>
                )}
                {dqlHistory.map(dqlState => (
                    <Button
                        onClick={() => setState(dqlState)}
                        key={uuid()}
                        styles={{
                            background: "unset",
                            padding: 0,
                            width: "100%",
                            fontWeight: "unset",
                            fontSize: "unset",
                            height: "unset",
                            textAlign: "unset",
                            ":hover": {
                                background: "grey.surface",
                            },
                        }}
                    >
                        <Stack
                            direction="vertical"
                            styles={{ paddingTop: 1, width: "100%" }}
                        >
                            <Text
                                variant={1}
                                styles={{
                                    fontFamily: "monospace",
                                    overflow: "hidden",
                                    width: "220px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {stripNewLines(dqlState.query)}
                            </Text>
                            <Stack
                                direction="horizontal"
                                gap={1}
                                justify="flex-end"
                                align="center"
                                styles={{ height: "24px" }}
                            >
                                {dqlState.repeatCount > 1 && (
                                    <Text
                                        variant={1}
                                        weight={500}
                                        styles={styles.Badge}
                                    >{`${dqlState.repeatCount} times`}</Text>
                                )}
                                <Text
                                    variant={1}
                                    weight={500}
                                    color="grey.light"
                                >
                                    {getDaysDifference(
                                        new Date(dqlState.at),
                                        new Date()
                                    ) === 0
                                        ? getShortTime(new Date(dqlState.at))
                                        : getShortDate(new Date(dqlState.at))}
                                </Text>
                            </Stack>

                            <Divider styles={{ marginTop: 1 }} />
                        </Stack>
                    </Button>
                ))}
            </Stack>
        </Card>
    );
};

const stripNewLines = (text: string) => text.replace(/(\r\n|\n|\r)/gm, "");
