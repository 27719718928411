import { CloudProvider } from "components/pages/PricingCalculator/PricingCalculator.types";
import AzurePng from "./icons/azure.png";
import GCPng from "./icons/gcp.png";
import AWSPng from "./icons/aws.png";
import BYOKPng from "./icons/byok.png";
import { Zone } from "components/shared/ZoneSelect/ZoneSelect.types";

export const createCloudProviderFromZones = (zones: Array<Zone>) => {
    zones.sort(compareZones);
    zones.forEach(filterProviderFromName);
    const cloudProviders = zones.reduce(
        zonesToCloudProvidersReducer,
        [] as Array<CloudProvider>
    );
    cloudProviders.sort(compareCloudProviders);
    return cloudProviders;
};

const zonesToCloudProvidersReducer = (
    agg: Array<CloudProvider>,
    zone: Zone
) => {
    const isCloudProviderPresentForTheZone = agg
        .map(cloud => cloud.key)
        .includes(zone.platform);

    if (isCloudProviderPresentForTheZone) {
        return agg.map(cloud =>
            cloud.key === zone.platform
                ? {
                      ...cloud,
                      zoneProvider: [...cloud.zoneProvider, zone],
                  }
                : cloud
        );
    } else {
        return [
            ...agg,
            {
                ...cloudMap[zone.platform],
                zoneProvider: [zone],
            },
        ];
    }
};

const providerOrder = ["BYOK", "AWS", "GCP", "Azure"];

const cloudMap: Record<string, CloudProvider> = {
    aws: {
        key: "aws",
        icon: AWSPng,
        name: "AWS",
        zoneProvider: [],
    },
    gcp: {
        key: "gcp",
        icon: GCPng,
        name: "GCP",
        zoneProvider: [],
    },
    azure: {
        key: "azure",
        icon: AzurePng,
        name: "Azure",
        zoneProvider: [],
    },
    enterprise: {
        key: "enterprise",
        icon: BYOKPng,
        name: "BYOK",
        zoneProvider: [],
    },
};

function filterProviderFromName(zone: Zone) {
    zone.name = zone.name.replace(/(AWS|GCP|Azure)\s/, "");
}

// Returns index if found, lowest priority if not
function getCountryPriority(country: string) {
    const index = countryPriority.indexOf(country);
    return index > -1 ? index : countryPriority.length;
}

// Sorts by: Platform -> Country -> Name
function compareZones(a: Zone, b: Zone): number {
    const platOrder = a.platform
        .toUpperCase()
        .localeCompare(b.platform.toUpperCase());
    const countryOrder =
        getCountryPriority(a.country) - getCountryPriority(b.country);
    const zoneOrder = a.zoneID.localeCompare(b.zoneID);

    return platOrder ? platOrder : countryOrder ? countryOrder : zoneOrder;
}

function compareCloudProviders(a: CloudProvider, b: CloudProvider): number {
    return providerOrder.indexOf(a.name) - providerOrder.indexOf(b.name);
}

const countryPriority: Array<string> = [
    "US",
    "CA",
    "EU",
    "GB",
    "UK",
    "DE",
    "SG",
    "IN",
    "KP",
];
