import { gql } from "@apollo/client";

export const LIST_NAMESPACES = gql`
    query ListNamespaces($deploymentID: ID!) {
        listNamespaces(deploymentID: $deploymentID) {
            uid
            description
            namespaceId
            accessToken
        }
    }
`;

export const CREATE_NAMESPACE = gql`
    mutation CreateNamespace($input: CreateNamespaceInput!) {
        createNamespaceForDeployment(input: $input) {
            uid
            description
            namespaceId
            accessToken
        }
    }
`;

export const DELETE_NAMESPACE = gql`
    mutation DeleteNamespace($deploymentID: ID!, $namespaceID: Int!) {
        deleteNamespaceForDeployment(deploymentID: $deploymentID, NamespaceId: $namespaceID)
    }
`;
