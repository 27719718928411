import { gql } from "@apollo/client";

export const GET_DEPLOYMENT_COST = gql`
    query GetDeploymentCost($input: DeploymentCostInput!) {
        getDeploymentCost(input: $input) {
            totalCost
            dataTransferCost
            machineCost
            storageCost
        }
    }
`;
