import React, { lazy, Suspense, useEffect } from "react";
import { observer } from "mobx-react-lite";
import deploymentStore, {
    DeploymentStore,
} from "store/deployments/deployments";
//@ts-ignore
import RouteBuilder from "./RouteBuilder";
import Layout from "components/layout/Layout";
import { trackUser } from "libs/trackevents";
import VerifyEmailScreen from "./VerifyEmailScreen";
//@ts-ignore
import RedirectToDashboard from "components/pages/RedirectToDashboard";
import Spinner from "components/shared/Spinner/Spinner";
import { useAuth } from "libs/auth";
import DQLPage from "components/pages/DQLPage/DQLPage";

const Dashboard = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "Dashboard" */ /* webpackPrefetch: 100 */ "components/pages/Dashboard"
    )
);
const CreateDeploymentPage = lazy(() =>
    import(
        /* webpackChunkName: "CreateDeploymentPage" */ /* webpackPrefetch: true   */ "components/pages/CreateDeployment/CreateDeployment"
    )
);
const PaymentsPage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "PaymentsPage" */ /* webpackPrefetch: true */ "components/pages/Payments/PaymentsPage/PaymentsPage"
    )
);
const SuperAdminPage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "SuperAdminPage" */ /* webpackPrefetch: true */ "components/pages/SuperAdmin/SuperAdminPage"
    )
);
const GettingStartedPage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "GettingStartedPage" */ /* webpackPrefetch: true */ "components/pages/GettingStarted/GettingStarted"
    )
);
const ApiExplorer = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "ApiExplorer" */ /* webpackPrefetch: 100 */ "components/pages/ApiExplorer"
    )
);

const SchemaPage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "SchemaPage" */ /* webpackPrefetch: 100 */ "components/pages/SchemaPage/SchemaPage"
    )
);
const SettingsPage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "SettingsPage" */ /* webpackPrefetch: 100 */ "components/pages/Settings/Settings"
    )
);

const AppStorePage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "AppStorePage" */ /* webpackPrefetch: true */ "components/pages/AppStore"
    )
);

const DeploymentDetails = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "DeploymentDetails" */ /* webpackPrefetch: true */ "components/pages/SuperAdmin/DeploymentDetails"
    )
);

const OrganizationsPage = lazy(() =>
    import(
        /* webpackChunkName: "OrganizationsPage" */ /* webpackPrefetch: true */ "components/pages/Organizations/OrganizationsPage"
    )
);

const OrganizationPage = lazy(() =>
    import(
        /* webpackChunkName: "OrganizationPage" */ /* webpackPrefetch: true */ "components/pages/Organization/OrganizationPage"
    )
);

const LambdasPage = lazy(() =>
    import(
        /* webpackChunkName: "LambdasPage" */ /* webpackPrefetch: true */ "components/pages/Lambdas/LambdasPage"
    )
);

const DataExplorerPage = lazy(() =>
    import(
        /* webpackChunkName: "DataExplorerPage" */ /* webpackPrefetch: true */ "components/pages/DataExplorerPage/DataExplorer"
    )
);

const MonitoringPage = lazy(() =>
    import(
        /* webpackChunkName: "MonitoringPage" */ /* webpackPrefetch: true */ "components/pages/Monitoring/MonitoringPage"
    )
);

const ACLPage = lazy(() =>
    import(
        /* webpackChunkName: "ACLPage" */ /* webpackPrefetch: true */ "components/pages/ACL/ACLPage"
    )
);

const ModifyBackendPage = lazy(() =>
    import(
        /* webpackChunkName: "ModifyBackendPage" */ /* webpackPrefetch: true */ "components/pages/ModifyBackendPage/ModifyBackendPage"
    )
);

const VerifyEmailPage = lazy(() =>
    import(
        /* webpackChunkName: "VerifyEmailPage" */ /* webpackPrefetch: true */ "components/pages/Auth/VerifyEmail/VerifyEmail"
    )
);

const AuditLogsPage = lazy(() =>
    import(
        /* webpackChunkName: "AuditLogsPage" */ /* webpackPrefetch: true */ "components/pages/AuditLogsPage/AuditLogsPage"
    )
);

const Support = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "PaymentsPage" */ /* webpackPrefetch: true */ "components/pages/Support/Support"
    )
);

const NamespacePage = lazy(() =>
    import(
        //@ts-ignore
        /* webpackChunkName: "PaymentsPage" */ /* webpackPrefetch: true */ "components/pages/NamespacePage/NamespacePage"
    )
);

const AppRoutes = observer(({ deploymentStore }: AppRoutesPropsType) => {
    const { loading, isAuthenticated, user } = useAuth();

    const routesRequiringDeployment = [
        {
            path: "/getting-started",
            component: GettingStartedPage,
            private: true,
        },
        {
            path: "/explorer",
            component: ApiExplorer,
            private: true,
        },
        {
            path: "/data",
            component: DataExplorerPage,
            private: true,
        },
        {
            path: "/dql",
            component: DQLPage,
            private: true,
        },
        {
            path: "/monitoring",
            component: MonitoringPage,
            private: true,
        },
        {
            path: "/acl",
            component: ACLPage,
            private: true,
        },
        {
            path: "/schema",
            component: SchemaPage,
            private: true,
        },
        {
            path: "/settings/modify",
            component: ModifyBackendPage,
            private: true,
        },
        {
            path: "/settings",
            component: SettingsPage,
            private: true,
        },
        {
            path: "/audit-logs",
            component: AuditLogsPage,
            private: true,
        },
    ];

    const routes = [
        {
            path: "/create-deployment",
            component: CreateDeploymentPage,
            private: true,
        },
        {
            path: "/billing",
            component: PaymentsPage,
            private: true,
        },
        {
            path: "/dashboard",
            component: Dashboard,
            private: true,
        },
        {
            path: "/one-click",
            component: AppStorePage,
            private: true,
        },
        {
            path: "/super-admin",
            component: SuperAdminPage,
            private: true,
            exact: true,
        },
        {
            path: "/super-admin/backend/:id",
            component: DeploymentDetails,
            private: true,
        },
        {
            path: "/organization/:organizationId",
            component: OrganizationPage,
            private: true,
        },
        {
            path: "/organizations",
            component: OrganizationsPage,
            private: true,
        },
        {
            path: "/lambdas",
            component: LambdasPage,
            private: true,
        },
        {
            path: "/verify-email",
            component: VerifyEmailPage,
            private: true,
        },
        {
            path: "",
            component: RedirectToDashboard,
            exact: true,
        },
        {
            path: "/support",
            component: Support,
            private: true,
        },
        {
            path: "/namespaces",
            component: NamespacePage,
            private: true,
        },
    ];

    const email = user ? user.email : null;
    useEffect(() => {
        if (user) {
            trackUser(user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    if (!loading && isAuthenticated) {
        if (
            !user?.emailVerified &&
            !window.location.pathname.startsWith("/_/verify-email")
        ) {
            return <VerifyEmailScreen email={user!.email} />;
        }
    }

    return (
        <Layout>
            <Suspense fallback={<Spinner />}>
                <RouteBuilder
                    routes={
                        deploymentStore.currentDeployment
                            ? [...routesRequiringDeployment, ...routes]
                            : [...routes]
                    }
                />
            </Suspense>
        </Layout>
    );
});

export default () => <AppRoutes deploymentStore={deploymentStore} />;

type AppRoutesPropsType = {
    deploymentStore: DeploymentStore;
};
