import { useClusters } from "gql/hooks/clusters/clusters.hook";
import { createCloudProviderFromZones } from "./utils";

export const useCloudProviders = () => {
    const { clusters: zones, loading } = useClusters();
    const availableZones = zones.filter(
        z =>
            z.platform !== "gcp" &&
            z.zoneID !== "us-east-2" &&
            z.zoneID !== "us-west-1"
    );
    const cloudProviders = createCloudProviderFromZones(availableZones);
    return {
        cloudProviders,
        zones: availableZones,
        slashZones: availableZones.filter(zone => zone.slashAvailable),
        loading,
    };
};
