import React, { useContext, useState } from "react";
import { History, LocationState } from "history";
import { setAuthToken } from "./api";
import deploymentStore from "store/deployments/deployments";
import {
    reauthenticate_reauthenticate,
    reauthenticate_reauthenticate_user,
} from "gql/types/reauthenticate";

export type LoginPayload = reauthenticate_reauthenticate;
export interface LoginCredentials {
    email: string;
    password: string;
}

export interface AuthContextType {
    isAuthenticated: boolean;
    user: reauthenticate_reauthenticate_user | undefined;
    token: string | null;
    loading: boolean;
    loginWithRedirect(
        history: History<LocationState>,
        redirectTo?: string
    ): void;
    logout(): void;
    onLoginSuccess(payload: LoginPayload): void;
    getToken(): string | null | undefined;
    onReauthenticationSuccess(payload: LoginPayload): void;
    onReauthenticationError(): void;
    isFirstLogin: boolean;
    setIsFirstLogin(b: boolean): void;
    onVerifyEmailSuccess: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(
    {} as AuthContextType
);
export const useAuth = () => useContext(AuthContext);

// Redirects to current page, or a specified page
export function loginWithRedirect(
    history: History<LocationState>,
    redirectTo: string
) {
    const relativeUrl = window.location.pathname + window.location.search;
    history.push("/login?redirect=" + relativeUrl);
}

const TOKEN_KEY = "token";

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(
        window.localStorage.getItem(TOKEN_KEY)
    );
    const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

    const updateToken = (token: string | null) => {
        setToken(token);
        setAuthToken(token);
        token
            ? window.localStorage.setItem(TOKEN_KEY, token)
            : window.localStorage.removeItem(TOKEN_KEY);

        setLoading(false);
    };

    const onLoginSuccess = ({ user, token }: LoginPayload) => {
        updateToken(token);
        setUser(user);

        setIsAuthenticated(true);
    };

    const onVerifyEmailSuccess = () => {
        setUser({ ...user, emailVerified: true });
    };

    const onReauthenticationSuccess = (payload: LoginPayload) => {
        onLoginSuccess(payload);
        setLoading(false);
    };

    const onReauthenticationError = () => {
        setToken(null);
        setLoading(false);
    };

    const logout = () => {
        updateToken(null);
        setUser(null);
        setIsAuthenticated(false);
        deploymentStore.purgeStore();
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                token,
                loading,
                loginWithRedirect,
                logout,
                onLoginSuccess,
                getToken: () => token,
                onReauthenticationSuccess,
                onReauthenticationError,
                isFirstLogin,
                setIsFirstLogin,
                onVerifyEmailSuccess,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
