import React from "react";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router";

import AppRoutes from "./AppRoutes";
//@ts-ignore
import RouteBuilder from "./RouteBuilder";
import config from "config";

//@ts-ignore
import RedirectToDashboard from "components/pages/RedirectToDashboard";
import PasswordResetPage from "components/pages/Auth/ResetPassword/ResetPassword";
import PricingCalculatorPage from "components/pages/PricingCalculator/PricingCalculator";
import AuthHomePage from "components/pages/Auth/Home/AuthHomePage";
import OAuthPage from "components/pages/OAuthPage";


function GlobalRoutes() {
    const routes = [
        {
            path: "/" + config.routes.appPrefix,
            component: AppRoutes,
            private: true,
        },
        // public routes go here
        {
            path: "/reset-password",
            component: PasswordResetPage,
        },
        // TODO: Get rid of in 72 hours
        {
            path: "/pricing-calculator",
            component: () => <Redirect to="/pricing" />,
        },
        {
            path: "/pricing",
            component: PricingCalculatorPage,
        },
        {
            path: "/login",
            component: AuthHomePage,
        },

        {
            path: "/oauth/:provider",
            component: OAuthPage,
        },
        // must be last route (it's a catchall)
        {
            path: "/",
            component: RedirectToDashboard,
            //exact: true, // do we want this to only reroute /? if so, we need to add 404 page
            private: true,
        },
    ];

    return <RouteBuilder routes={routes} />;
}

export default observer(GlobalRoutes);
