export const styles: any = {
    SelectedTier: {
        outline: "{{lineThickness.1}} solid {{colors.pink}}",
    },
    UnselectedTier: {
        outline: "{{lineThickness.1}} solid transparent",
    },
    TableDescription: {
        background: "pink.translucent",
    },
    TableDescriptionDetails: {
        background: "grey.surface",
    },
    TableButton: {
        fontSize: 2,
        fontWeight: 500,
        height: 4,
        "&:hover": {
            transition: "none",
            background: "inherit",
        },
    },
};
