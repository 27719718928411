import {
    DqlOperationType,
    DqlQueryExecutorState,
    DqlQueryResponse,
    DqlQueryState,
} from "./dqlQueryExecutor.reducer.types";

export enum ActionTypes {
    SET_STATE = "SET_STATE",
    EXECUTE_QUERY_ACTION = "EXECUTE_QUERY_ACTION",
    EXECUTE_QUERY_COMPLETE_ACTION = "EXECUTE_QUERY_COMPLETE_ACTION",
    EXECUTE_QUERY_FAIL_ACTION = "EXECUTE_QUERY_FAIL_ACTION",
    CLEAR_HISTORY = "CLEAR_HISTORY",
    RESET = "RESET",
}

export type ExecuteQueryAction = {
    type: ActionTypes.EXECUTE_QUERY_ACTION;
    payload: {
        query: string;
        variables: Record<string, string>;
        uid: string;
        operationType: DqlOperationType;
    };
};

export type ExecuteQueryCompleteAction = {
    type: ActionTypes.EXECUTE_QUERY_COMPLETE_ACTION;
    payload: {
        uid: string;
        response: DqlQueryResponse;
    };
};

export type ExecuteQueryFailAction = {
    type: ActionTypes.EXECUTE_QUERY_FAIL_ACTION;
    payload: {
        uid: string;
        response: DqlQueryResponse;
    };
};

export type SetStateAction = {
    type: ActionTypes.SET_STATE;
    payload: {
        state: DqlQueryState;
    };
};

export type ClearHistoryAction = {
    type: ActionTypes.CLEAR_HISTORY;
};

export type ResetAction = {
    type: ActionTypes.RESET;
    payload: {
        reducerState: DqlQueryExecutorState;
    };
};

export type Action =
    | ExecuteQueryAction
    | ExecuteQueryCompleteAction
    | ExecuteQueryFailAction
    | ClearHistoryAction
    | ResetAction;
