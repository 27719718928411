export const tourStates = {
    INACTIVE: "INACTIVE",
    DISMISSED: "DISMISSED",
    TUTORIAL_COMPLETE: "TUTORIAL_COMPLETE",

    /* Step 1 - Create a backend */
    CLICK_LAUNCH_NEW_BACKEND: "CLICK_LAUNCH_NEW_BACKEND",
    BACKEND_LAUNCH_PROGRESS: "BACKEND_LAUNCH_PROGRESS",
    BACKEND_LAUNCH_SUCCESS: "BACKEND_LAUNCH_SUCCESS",
    GOTO_SCHEMA_EDITOR: "GOTO_SCHEMA_EDITOR",

    /* Step 2 - Deploy a schema */
    CLICK_SCHEMA_EDITOR: "CLICK_SCHEMA_EDITOR",
    TYPE_SCHEMA: "TYPE_SCHEMA",
    TYPE_SCHEMA_PROGRESS: "TYPE_SCHEMA_PROGRESS",
    TYPE_SCHEMA_COMPLETE: "TYPE_SCHEMA_COMPLETE",
    CLICK_SCHEMA_DEPLOY_BUTTON: "CLICK_SCHEMA_DEPLOY_BUTTON",
    SCHEMA_UPDATED: "SCHEMA_UPDATED",
    GOTO_API_EXPLORER: "GOTO_API_EXPLORER",

    /* Step 4 - GraphQL queries */
    CLICK_API_EXPLORER: "CLICK_API_EXPLORER",
    TYPE_MUTATION: "TYPE_MUTATION",
    TYPE_MUTATION_PROGRESS: "TYPE_MUTATION_PROGRESS",
    TYPE_MUTATION_COMPLETE: "TYPE_MUTATION_COMPLETE",
    MUTATION_EXECUTION_WAITING: "MUTATION_EXECUTION_WAITING",
    MUTATION_EXECUTION_COMPLETE: "MUTATION_EXECUTION_COMPLETE",
    TYPE_QUERY: "TYPE_QUERY",
    TYPE_QUERY_PROGRESS: "TYPE_QUERY_PROGRESS",
    TYPE_QUERY_COMPLETE: "TYPE_QUERY_COMPLETE",
    QUERY_EXECUTION_WAITING: "QUERY_EXECUTION_WAITING",
    QUERY_EXECUTION_COMPLETE: "QUERY_EXECUTION_COMPLETE",
    GOTO_DASHBOARD: "GOTO_DASHBOARD",

    /* Step 4 - Tour Dashboard */
    CLICK_DASHBOARD: "CLICK_DASHBOARD",
    COPY_BACKEND_URL: "COPY_BACKEND_URL",
    COPY_BACKEND_URL_COMPLETEL: "COPY_BACKEND_URL_COMPLETE",
    TUTORIAL_FINAL: "TUTORIAL_FINAL",

    API_EXPLORER_BRIEF: "API_EXPLORER_BRIEF",
};

export const produtTourSteps = [
    { id: tourStates.INACTIVE, type: "event" },
    {
        id: tourStates.CLICK_LAUNCH_NEW_BACKEND,
        type: "floater",
        elementId: "launch-bttn",
        data: {
            content: "Click here to launch with the above settings",
            position: "left",
            trigger: tourStates.BACKEND_LAUNCH_PROGRESS,
        },
    },
    {
        id: tourStates.BACKEND_LAUNCH_PROGRESS,
        type: "event",
    },
    {
        id: tourStates.BACKEND_LAUNCH_SUCCESS,
        type: "modal",
        data: {
            heading: "🚀 Your backend is now live !",
            content:
                "However, it is completely empty. We need to define a GraphQL Schema before we put data in",
            trigger: tourStates.GOTO_SCHEMA_EDITOR,
        },
    },
    {
        id: tourStates.GOTO_SCHEMA_EDITOR,
        type: "floater",
        elementId: "schema-link",
        data: {
            content: "Update your backend’s schema here",
            position: "right",
        },
    },
    {
        id: tourStates.CLICK_SCHEMA_EDITOR,
        type: "modal",
        data: {
            heading: "GraphQL schema",
            content:
                "You can try using a sample Todo app schema for now to explore or go adventurous with your own schema.",
            trigger: tourStates.TYPE_SCHEMA,
            continueLabel: "Use Todo app schema",
            closeLabel: "No thanks",
            detour: {
                steps: [
                    {
                        id: tourStates.GOTO_API_EXPLORER,
                        type: "floater",
                        elementId: "api-explorer-link",
                        data: {
                            content: "Click here to checkout the API Explorer",
                            position: "right",
                        },
                    },
                    {
                        id: tourStates.CLICK_API_EXPLORER,
                        type: "modal",
                        data: {
                            heading: "API Explorer",
                            content:
                                "You can perform GraphQL mutations & queries from here.",
                            trigger: tourStates.GOTO_DASHBOARD,
                        },
                    },
                ],
                exit: tourStates.GOTO_DASHBOARD,
            },
        },
    },
    {
        id: tourStates.TYPE_SCHEMA,
        type: "event",
    },
    {
        id: tourStates.TYPE_SCHEMA_PROGRESS,
        type: "event",
    },
    {
        id: tourStates.TYPE_SCHEMA_COMPLETE,
        type: "floater",
        elementId: "schema-deploy-bttn",
        data: {
            content:
                "Click here - This deploys your backend with the new schema",
            position: "left",
            trigger: tourStates.CLICK_SCHEMA_DEPLOY_BUTTON,
        },
    },
    {
        id: tourStates.CLICK_SCHEMA_DEPLOY_BUTTON,
        type: "event",
    },
    {
        id: tourStates.SCHEMA_UPDATED,
        type: "modal",
        data: {
            heading: "Now - the exciting part! 🤓",
            content:
                "Your schema has been deployed! Lets perform some GraphQL queries.",
            trigger: tourStates.GOTO_API_EXPLORER,
        },
    },
    {
        id: tourStates.GOTO_API_EXPLORER,
        type: "floater",
        elementId: "api-explorer-link",
        data: {
            content: "Click here to checkout API Explorer",
            position: "right",
        },
    },
    {
        id: tourStates.CLICK_API_EXPLORER,
        type: "modal",
        data: {
            heading: "Don't worry! We will help you write a mutation ✍️",
            content: "Click on continue to type a GraphQL mutation",
            trigger: tourStates.TYPE_MUTATION,
        },
    },
    {
        id: tourStates.TYPE_MUTATION,
        type: "event",
    },
    {
        id: tourStates.TYPE_MUTATION_PROGRESS,
        type: "event",
    },
    {
        id: tourStates.TYPE_MUTATION_COMPLETE,
        type: "modal",
        data: {
            heading: "Your turn - Hit the play button ▶️",
            content:
                "Click on the pink play button at the top to execute your GraphQL mutation",
            trigger: tourStates.MUTATION_EXECUTION_WAITING,
        },
    },
    {
        id: tourStates.MUTATION_EXECUTION_WAITING,
        type: "event",
    },
    {
        id: tourStates.MUTATION_EXECUTION_COMPLETE,
        type: "modal",
        data: {
            heading: "Awesome 🥳  ! Now lets query the data! ",
            content: "Write a few queries",
            trigger: tourStates.TYPE_QUERY,
        },
    },
    {
        id: tourStates.TYPE_QUERY,
        type: "event",
    },
    {
        id: tourStates.TYPE_QUERY_PROGRESS,
        type: "event",
    },
    {
        id: tourStates.TYPE_QUERY_COMPLETE,
        type: "modal",
        data: {
            heading: "Your turn - Hit the play button again ▶️",
            content:
                "Click on the pink play button at the top to execute your GraphQL query",
            trigger: tourStates.QUERY_EXECUTION_WAITING,
        },
    },
    {
        id: tourStates.QUERY_EXECUTION_WAITING,
        type: "event",
    },
    {
        id: tourStates.QUERY_EXECUTION_COMPLETE,
        type: "modal",
        data: {
            heading: "Awesome! 😎",
            content:
                "Your backend is production ready! Now lets get your backend's endpoint",
            trigger: tourStates.GOTO_DASHBOARD,
        },
    },
    {
        id: tourStates.GOTO_DASHBOARD,
        type: "floater",
        elementId: "dashboard-link",
        data: {
            content: "Click here to checkout your backend's dashboard",
            position: "right",
        },
    },
    {
        id: tourStates.CLICK_DASHBOARD,
        type: "modal",
        data: {
            heading: "Your backend's dashboard",
            content:
                "All essentials like usage, deployment info, resources & guides can be found here",
            continueLabel: "Okay",
            trigger: tourStates.COPY_BACKEND_URL,
        },
    },
    {
        id: tourStates.COPY_BACKEND_URL,
        type: "floater",
        elementId: "backend-url-copy-bttn",
        data: {
            content: "Copy your backend's url from here",
            position: "right",
            trigger: tourStates.TUTORIAL_FINAL,
        },
    },
    {
        id: tourStates.TUTORIAL_FINAL,
        type: "modal",
        elementId: "dashboard-link",
        data: {
            heading: "You did it! 😎",
            content:
                "You have just created your first GraphQL backend on Dgraph Cloud! We are looking forward to seeing what you build",
            continueLabel: "Okay",
            trigger: tourStates.TUTORIAL_COMPLETE,
        },
    },
    { id: tourStates.TUTORIAL_COMPLETE, type: "event" },
];

export const tourCheckpoints = [
    {
        title: "Launch your backend",
        id: tourStates.BACKEND_LAUNCH_PROGRESS,
    },
    {
        title: "Deploy your schema",
        id: tourStates.GOTO_API_EXPLORER,
    },
    {
        title: "GraphQL queries",
        id: tourStates.GOTO_DASHBOARD,
    },
    {
        title: "Overview",
        id: tourStates.COPY_BACKEND_URL,
    },
];

interface TourState {
    id: string;
    index?: number;
    type?: string;
    data?: Object;
    elementId?: string;
}

export const isTourOpen = (state: TourState) =>
    ![
        tourStates.DISMISSED,
        tourStates.INACTIVE,
        tourStates.TUTORIAL_COMPLETE,
    ].includes(state.id);
