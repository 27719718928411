import config from "config";

export const grafanaUrl = (
    { uid, url }: { uid: string; url: string },
    { grpc = false } = {}
) => {
    return `${config.grafanaPromUrl}${new URLSearchParams({
        "var-id": `cluster-${uid}`,
        "var-hostname": grpc ? url.replace(".", ".grpc.") : url,
    }).toString()}`;
};
export const jaegerUrl = ({
    url,
    jwtToken,
}: {
    url: string;
    jwtToken: string;
}) => {
    return `https://${url}/jaeger?${new URLSearchParams({
        "auth-token": jwtToken,
    }).toString()}`;
};

export const ratelUrl = ({
    url,
    jwtToken,
}: {
    url: string;
    jwtToken: string;
}) => {
    return `https://play.dgraph.io/?latest#addr=https://${url}&slashApiKey=${encodeURIComponent(
        jwtToken
    )}&query=${encodeURIComponent("schema {}")}`;
};

export const logsUrl = (expr: string) => {
    return `${config.grafanaLogsUrl}${new URLSearchParams({
        left: JSON.stringify([
            "now-1h",
            "now",
            "Loki",
            { expr },
            { ui: [true, true, true, "none"] },
        ]),
    }).toString()}`;
};

export const gRPCEndpoint = ({ url }: { url: string }) => {
    return `${url.replace(".", ".grpc.")}:443`;
};
