import React from "react";
import { Button, Container, Stack } from "@dgraph-io/typhoon-ui";
import { styles } from "./BackButton.styles";
import { BackButtonPropsType } from "./BackButton.types";

export default function BackButton({
    text = "Back",
    onClick,
}: BackButtonPropsType) {
    return (
        <Button variant="ghost" onClick={onClick}>
            <Stack align="center" justify="space-between">
                <Container styles={styles.ChevronStyles}></Container>{" "}
                <Container styles={{ marginX: 1 }}>{text}</Container>
            </Stack>
        </Button>
    );
}
