import React from "react";
import { Stack, Text, Input, Button } from "@dgraph-io/typhoon-ui";
import { useSendPasswordResetEmail } from "gql/hooks/auth/auth.hook";
import { useForgotPasswordForm } from "../hooks/useForgotPassword";
import { uuid } from "@dgraph-io/typhoon-ui";

const ForgotPassword = () => {
    const {
        forgotPasswordForm: { email },
        errors,
        onSuccess,
        onFailure,
    } = useForgotPasswordForm();

    const [
        sendPasswordResetEmail,
        { loading, error },
    ] = useSendPasswordResetEmail({
        onCompleted: onSuccess,
        onError: () => onFailure(error),
    });

    return (
        <Stack direction="vertical" gap={2}>
            <Text color="gray" styles={{ textAlign: "center" }} variant={2}>
                please enter your email address. We will send you <br /> an
                email to rest your password
            </Text>

            <Input {...email} placeholder="yours@example.com" />

            {email.errors.map(error => (
                <Text color="red" variant={2} key={uuid()}>
                    {error.message}
                </Text>
            ))}

            <Button
                onClick={() =>
                    sendPasswordResetEmail({
                        variables: { email: email.value },
                    })
                }
                disabled={loading || errors.length > 0}
            >
                Send Email
            </Button>
        </Stack>
    );
};

export default ForgotPassword;
