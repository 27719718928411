import React, { useContext } from "react";

// @ts-ignore
import ReactCountryFlag from "react-country-flag";

import { styles } from "./ZoneSelect.styles";
import { Stack, Text, Select } from "@dgraph-io/typhoon-ui";
import { Zone, ZoneCardProps } from "./ZoneSelect.types";
import { stylegun } from "@dgraph-io/typhoon-ui";
import { ThemeContext } from "@dgraph-io/typhoon-ui";

export const ZoneSelect = ({
    zones = [],
    onChange,
    selected,
    disabled,
}: ZoneCardProps) => {
    const theme = useContext(ThemeContext);
    const selectedZone = zones.find(zone => zone.zoneID === selected);
    return (
        <Stack direction="vertical" gap={2}>
            <Select
                label="Select Region"
                disabled={disabled}
                styles={{ width: "100%" }}
                selected={
                    selectedZone
                        ? {
                              id: selectedZone.zoneID,
                              value: selected,
                              label: (
                                  <ZoneLabel
                                      zone={selectedZone}
                                      theme={theme}
                                  />
                              ),
                          }
                        : null
                }
                onSelect={({ value }: any) => {
                    onChange(value.value);
                }}
                values={zones.map((zone: Zone) => ({
                    id: zone.zoneID,
                    value: zone,
                    label: <ZoneLabel zone={zone} theme={theme} />,
                }))}
            />
        </Stack>
    );
};

const ZoneLabel = ({ zone, theme }: { zone: Zone; theme: Object }) => {
    return (
        <Stack direction="horizontal" align="center" gap={1} wrap="nowrap">
            <ReactCountryFlag
                svg
                countryCode={zone.country.toUpperCase()}
                style={stylegun({
                    css: styles.FlagInlineStyles,
                    theme,
                })}
            />
            <Text variant={2} weight={500}>
                {zone.name}
            </Text>
            <Text color="grey" weight={500} variant={1}>
                ({zone.zoneID})
            </Text>
        </Stack>
    );
};
