export const debounce = (fn: Function, ms = 500) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

export const getValidDiskSize = (size: number) =>
    Math.min(Math.max(size, 10), 1024);

export const getValidSamplingRatio = (size: number) =>
    Math.min(Math.max(size, 0.001), 1);

export const getValidNodeSize = (size: number) =>
    Math.min(Math.max(size, 0), 1000);
