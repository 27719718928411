import { useOAuthLogin } from "gql/hooks/users/users.hook";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import history from "libs/browserHistory";
import { oauthLogin_oauthLogin } from "gql/types/oauthLogin";
import { useAuth } from "libs/auth";
import Spinner from "components/shared/Spinner/Spinner";

interface OAuthPageParams {
    provider: string;
}

// Query name for each provider
const query: { [key: string]: string } = {
    github: "code",
    google: "token",
};

const getTokenFromQuery = (provider: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(query[provider]);
};

function OAuthPage() {
    const { provider } = useParams<OAuthPageParams>();
    const { setIsFirstLogin } = useAuth();
    const [token] = useState(getTokenFromQuery(provider));

    const onLoginSuccess = ({ isFirstLogin }: oauthLogin_oauthLogin) => {
        if (isFirstLogin && setIsFirstLogin) {
            setIsFirstLogin(isFirstLogin);
        }

        history.push("/");
    };

    const { oauthLogin } = useOAuthLogin({
        provider,
        token: token ?? "",
        onCompleted: onLoginSuccess,
    });

    useEffect(() => {
        if (token) {
            oauthLogin();
        }
    }, [token]);

    return <Spinner />;
}

export default OAuthPage;
